import React from 'react';
import Container from '../../elements/Container';
import BaseLayout from '../../layouts/Base';
import CardWithLogo from '../../elements/CardWithLogo';
import SignInForm from '../../modules/SignInForm';
import AuthWrap from '../../modules/AuthWrap';
import { useDispatch, useSelector } from '../../../libs/redux';
import { signIn } from '../../../store/actions/authentication';
import { selectAuthentication } from '../../../store/reducers/authentication';

const SignInTemplate: React.FC = () => {
  const { loading, errors } = useSelector(selectAuthentication);
  const dispatch = useDispatch();
  return (
    <BaseLayout>
      <Container size="sm">
        <CardWithLogo>
          <AuthWrap loading={loading}>
            <SignInForm errors={errors} onSubmit={(d) => dispatch(signIn(d))} />
          </AuthWrap>
        </CardWithLogo>
      </Container>
    </BaseLayout>
  );
};

export default SignInTemplate;
