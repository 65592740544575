import React from 'react';
import { IEducation } from '../../../types/user';
import { Card } from '../../elements/Card';
import Loader from '../../elements/Loader';
import EducationForm from '../../modules/EducationForm';
import classes from './StoreEducation.module.scss';

interface IProps {
  education?: Omit<IEducation, 'id'>;
  onStore: (education: Omit<IEducation, 'id'>) => void;
  onCancel: () => void;
  errors: Partial<Record<keyof IEducation, string>>;
  loading: boolean;
}

const StoreEducationTemplate: React.FC<IProps> = (props) => {
  const { education, onStore, onCancel, errors, loading } = props;

  return (
    <section>
      <Card>
        <div className={classes.cardBody}>
          <Loader open={loading} position="absolute" />
          <h2>{education ? 'Edit' : 'Add new'} Education</h2>
          <EducationForm
            errors={errors}
            values={education}
            onSubmit={onStore}
            onCancel={onCancel}
          />
        </div>
      </Card>
    </section>
  );
};

export default StoreEducationTemplate;
