import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../../modules/Footer';
import Header from '../../modules/Header';
import RootLayout, { RootLayoutProps } from '../Root';
import classes from './Landing.module.scss';

interface IProps extends RootLayoutProps {}

const LandingLayout: React.FC<IProps> = (props) => {
  const { children, pageTitle } = props;

  return (
    <RootLayout pageTitle={pageTitle}>
      <Header />
      <main className={classes.wrap}>
        <Outlet />
        {children}
      </main>
      <Footer />
    </RootLayout>
  );
};

export default LandingLayout;
