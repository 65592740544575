import { createReducer } from '@reduxjs/toolkit';
import { ISettings } from '../../types/general';
import { getSettings } from '../actions/settings';

interface State {
  appSettings: ISettings | null;
  loading: boolean;
  loaded: boolean;
}

const initialState: State = {
  appSettings: null,
  loading: false,
  loaded: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(getSettings.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getSettings.fulfilled, (state, action) => {
    state.appSettings = action.payload;
    state.loading = false;
    state.loaded = true;
  });
  builder.addCase(getSettings.rejected, (state) => {
    state.loading = false;
    state.loaded = true;
  });
});

interface Store {
  settings: State;
}

export const selectSettings = (state: Store) => state.settings;

export default reducer;
