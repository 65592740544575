import axios, { Response } from '../libs/axios';
import { IEducation } from '../types/user';

export const requestEducation = () =>
  axios<Response<IEducation[]>>({
    url: '/user-educations',
    method: 'GET',
    params: {
      all: 1,
    },
  });

export const addEducation = (data: Omit<IEducation, 'id'>) =>
  axios<Response<IEducation>>({
    url: '/user-educations',
    method: 'POST',
    data,
  });

export const updateEducation = (id: number, data: Omit<IEducation, 'id'>) =>
  axios<Response<IEducation>>({
    url: `/user-educations/${id}`,
    method: 'PUT',
    data,
  });

export const removeEducation = (id: number) =>
  axios<Response<IEducation>>({
    url: `/user-educations/${id}`,
    method: 'DELETE',
  });

export const requestEducationByUserId = (id: number) =>
  axios<Response<IEducation[]>>({
    url: `/user/${id}/user-educations`,
    method: 'GET',
    params: {
      all: 1,
    },
  });
