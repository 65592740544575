import classNames from 'classnames';
import React from 'react';
import classes from './Tabs.module.scss';

interface IProps<T> {
  value: T;
  items: {
    name: string;
    value: T;
  }[];
  onChange: (value: T) => void;
  fullWidth?: boolean;
}

const Tabs = <T,>(props: IProps<T>) => {
  const { value, onChange, items, fullWidth = false } = props;
  return (
    <div
      className={classNames(classes.tabs, { [classes.fullWidth]: fullWidth })}
    >
      <div className={classes.wrap}>
        {items.map((tab) => (
          <button
            key={JSON.stringify(tab.value)}
            type="button"
            onClick={() => onChange(tab.value)}
            className={classNames(classes.tab, {
              [classes.active]: value === tab.value,
            })}
          >
            {tab.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
