import { createReducer } from '@reduxjs/toolkit';
import { IHomePage, IMenu, IPage } from '../../types/dynamic-pages';
import { getHomePage, getMenu, getPage } from '../actions/dynamic-pages';

interface State {
  menu: {
    list: IMenu[];
    loading: boolean;
    loaded: boolean;
  };
  pages: {
    list: Record<string, IPage | undefined>;
    error: string;
    loading: boolean;
  };
  home: {
    content: IHomePage | null;
    error: string;
    loading: boolean;
  };
}

const initialState: State = {
  menu: {
    list: [],
    loading: false,
    loaded: false,
  },
  pages: {
    list: {},
    error: '',
    loading: false,
  },
  home: {
    content: null,
    error: '',
    loading: false,
  },
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(getMenu.pending, (state) => {
    state.menu.loading = true;
  });
  builder.addCase(getMenu.fulfilled, (state, action) => {
    state.menu.list = action.payload;
    state.menu.loading = false;
    state.menu.loaded = true;
  });
  builder.addCase(getMenu.rejected, (state) => {
    state.menu.list = [];
    state.menu.loading = false;
    state.menu.loaded = true;
  });
  builder.addCase(getPage.pending, (state) => {
    state.pages.loading = true;
    state.pages.error = '';
  });
  builder.addCase(getPage.fulfilled, (state, action) => {
    state.pages.loading = false;
    state.pages.list = {
      ...state.pages.list,
      [action.payload.slug]: action.payload,
    };
  });
  builder.addCase(getPage.rejected, (state) => {
    state.pages.loading = false;
    state.pages.error = 'Page not found';
  });

  builder.addCase(getHomePage.pending, (state) => {
    state.home.loading = true;
    state.home.error = '';
  });
  builder.addCase(getHomePage.fulfilled, (state, action) => {
    state.home.loading = false;
    state.home.content = action.payload;
  });
  builder.addCase(getHomePage.rejected, (state) => {
    state.home.loading = false;
    state.home.error = 'Page not found';
  });
});

interface Store {
  dynamicPages: State;
}

export const selectDynamicPages = (state: Store) => state.dynamicPages;

export default reducer;
