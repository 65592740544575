import { createReducer } from '@reduxjs/toolkit';
import { IPagination } from '../../types/general';
import { ISearchFields, IUserSearchable } from '../../types/search';
import { clearAuthData } from '../actions/authentication';
import { search, setCurrentPage, setSearchFields } from '../actions/search';

interface State {
  fields: ISearchFields;
  result: IUserSearchable[];
  pagination: IPagination;
  loading: boolean;
}

const defaultPagination: IPagination = {
  current_page: 1,
  total: 0,
  from: 1,
  to: 1,
  last_page: 1,
  per_page: 15,
};

const initialState: State = {
  fields: {},
  pagination: defaultPagination,
  result: [],
  loading: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(search.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(search.fulfilled, (state, action) => {
    const pagination = action.payload.meta || defaultPagination;
    state.loading = false;

    if (!state.result.length) {
      state.result = Array(pagination.total);
    }

    state.result.splice(
      (action.meta.arg.page || 1) * pagination.per_page - pagination.per_page,
      pagination.per_page,
      ...action.payload.data
    );
    if (action.payload.meta) {
      state.pagination = action.payload.meta;
    }
  });
  builder.addCase(setSearchFields, (state, action) => {
    state.fields = action.payload;
    state.pagination.current_page = 1;
    state.result = [];
  });
  builder.addCase(setCurrentPage, (state, action) => {
    state.pagination.current_page = action.payload;
  });
  builder.addCase(clearAuthData, () => initialState);
});

interface Store {
  search: State;
}

export const selectSearch = (state: Store) => state.search;

export default reducer;
