import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { handleError422 } from '../../helpers';
import {
  requestSparrings,
  createSparring,
  updateSparring,
} from '../../repositories/sparring';
import { IError422 } from '../../types/errors';
import { IPaginationParams } from '../../types/general';
import {
  ISparring,
  ISparringRequest,
  SparringStatus,
} from '../../types/sparring';
import { addFastNotification } from './notifications';

export const getSparrings = createAsyncThunk(
  'getSparrings',
  async (payload: IPaginationParams) => {
    const response = await requestSparrings(payload);
    return response;
  }
);

export const sendSparringRequest = createAsyncThunk<
  ISparring,
  ISparringRequest,
  { rejectValue: Partial<Record<keyof ISparringRequest, string>> }
>('sendSparringRequest', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await createSparring(payload);
    dispatch(
      addFastNotification({
        content: 'Request sent successfully!',
        color: 'success',
      })
    );
    return response.data;
  } catch (error) {
    const err = error as IError422;
    if (err.errors) {
      return rejectWithValue(handleError422(err));
    }
    return rejectWithValue({});
  }
});

export const handleSparring = createAsyncThunk(
  'updateSparring',
  async (payload: { id: number; status: SparringStatus }) => {
    const response = await updateSparring(payload.status, payload.id);
    return response;
  }
);

export const setSparringRequestErrors =
  createAction<Partial<Record<keyof ISparringRequest, string>>>(
    'setSparringErrors'
  );

export const setCurrentPage = createAction<number>('setCurrentPage');
