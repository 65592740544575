import classNames from 'classnames';
import React from 'react';
import classes from './Card.module.scss';

export interface CardBodyProps {
  children?: React.ReactNode;
  className?: string;
}

const CardHeader: React.FC<CardBodyProps> = (props) => {
  const { children, className } = props;
  return (
    <div className={classNames(classes.header, className)}>{children}</div>
  );
};

export default CardHeader;
