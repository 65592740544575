import axios, { Response } from '../libs/axios';
import { Subscription } from '../types/subscription';

export const requestSubscriptions = () =>
  axios<Response<Subscription[]>>({
    url: '/subscriptions',
    method: 'GET',
  });

export const byuSubscriptions = () =>
  axios<Response<Subscription[]>>({
    url: '/subscriptions',
    method: 'Post',
  });
