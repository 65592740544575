import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  ERROR_FIELD_EDUCATION_REQUIRED,
  ERROR_FIELD_REQUIRED,
} from '../../../constants/error-messages';
import { useDispatch, useSelector } from '../../../libs/redux';
import useDynamicContent from '../../../hooks/useDynamicContent';
import { updateUserData } from '../../../store/actions/user';
import {
  deleteEducation,
  editEducation,
  addEducation,
  getEducation,
} from '../../../store/actions/user-education';
import { selectUserEducation } from '../../../store/reducers/user-education';
import { IBaseContent } from '../../../types/dynamic-content';
import { IEducation, IUser } from '../../../types/user';
import FormGroup from '../../elements/FormGroup';
import { Col, Row } from '../../elements/Grid';
import HelperText from '../../elements/HelperText';
import Label from '../../elements/Label';
import PersonalDataCard from '../../elements/PersonalDataCard';
import Select from '../../elements/Select';
import TextField from '../../elements/TextField';
import EducationForm from '../EducationForm';
import ExperienceList from '../ExperienceList';
import classes from './Onboarding.module.scss';
import StepsNavigation from './StepsNavigation';

interface IProps {
  onNext: () => void;
  onPrev: () => void;
  user: IUser;
}

const PersonalDetails: React.FC<IProps> = (props) => {
  const { onNext, onPrev, user } = props;
  const [error, setError] = useState('');
  const [searchInputs, setSearchInputs] = useState({
    country: user.city ? user.city.country.name : '',
    city: user.city ? user.city.name : '',
  });

  const [showModal, setShowModal] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<null | number>(null);
  const education = useSelector(selectUserEducation);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setShowModal(false);
    setItemToEdit(null);
  };

  const handleEdit = (id: number) => {
    setShowModal(true);
    setItemToEdit(id);
  };

  const handleSubmitEducation = (data: Omit<IEducation, 'id'>) => {
    if (itemToEdit !== null) {
      dispatch(editEducation({ ...data, id: itemToEdit }));
    } else {
      dispatch(addEducation(data));
    }
  };

  useEffect(() => {
    handleCloseModal();
  }, [education.list]);

  useEffect(() => {
    if (!education.loaded && !education.loading) {
      dispatch(getEducation());
    }
  }, []);

  const editableEducation =
    itemToEdit !== null
      ? education.list.find((i) => i.id === itemToEdit)
      : undefined;

  const defaultValues = {
    about: user.user_detail?.about,
    interests: user.interests
      ? user.interests.map((i) => ({ label: i.name, value: i.id }))
      : undefined,
    city: user.city
      ? {
          label: user.city.name,
          value: user.city.id,
        }
      : undefined,
    country: user.city
      ? { label: user.city.country.name, value: user.city.country.id }
      : undefined,
    language: user.language
      ? {
          label: user.language.name,
          value: user.language.id,
        }
      : undefined,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm<{
    about: string;
    interests: { label: string; value: number }[];
    country: { label: string; value: number };
    city: { label: string; value: number };
    language: { label: string; value: number };
  }>({ defaultValues });
  const fields = {
    about: register('about', {
      required: ERROR_FIELD_REQUIRED,
    }),
    interests: register('interests', {
      required: ERROR_FIELD_REQUIRED,
    }),
    country: register('country', {
      required: ERROR_FIELD_REQUIRED,
    }),
    city: register('city', {
      required: ERROR_FIELD_REQUIRED,
    }),
    language: register('language', {
      required: ERROR_FIELD_REQUIRED,
    }),
  };

  const country = watch('country');
  const city = watch('city');

  const { interests, countries, languages, cities } = useDynamicContent([
    'interests',
    'languages',
    { key: 'countries', search: searchInputs.country },
    {
      key: 'cities',
      search: searchInputs.city,
      data: country ? country.value : undefined,
    },
  ]);

  useEffect(() => {
    if (country) {
      if (
        !(
          city &&
          defaultValues.city &&
          city.value === defaultValues.city.value &&
          defaultValues.country &&
          country.value === defaultValues.country.value
        )
      ) {
        setValue('city', '' as any);
      }
    }
  }, [country]);

  const handleNewStep = () => {
    if (education.list.length) {
      setError('');

      handleSubmit((data) => {
        dispatch(
          updateUserData({
            city_id: data.city.value,
            language_id: data.language.value,
            interests: data.interests.map((i) => i.value),
            user_detail: {
              about: data.about,
            },
          })
        );
        onNext();
      })();
    } else {
      setError(ERROR_FIELD_EDUCATION_REQUIRED);
    }
  };

  const baseContentToOptions = (content: IBaseContent[]) =>
    content.map((i) => ({
      label: i.name,
      value: i.id,
    }));

  return (
    <div className={classes.contentWrap}>
      <div className={classNames({ [classes.hidden]: showModal })}>
        <FormGroup>
          <TextField
            fullWidth
            multiline
            rows={3}
            label="Tell about yourself"
            placeholder="Type here"
            name={fields.about.name}
            textareaRef={fields.about.ref}
            onChange={fields.about.onChange}
            error={Boolean(errors.about)}
            helperText={errors.about?.message}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            control={control}
            name={fields.interests.name}
            render={(field) => (
              <Select
                fullWidth
                label="Specify your interests"
                placeholder="cybersecurity, fintech..."
                value={field.field.value}
                onChange={field.field.onChange}
                isMulti
                options={baseContentToOptions(interests.list)}
                error={Boolean(field.fieldState.error)}
                helperText={field.fieldState.error?.message}
              />
            )}
          />
        </FormGroup>

        <Label error={Boolean(errors.city || errors.country)}>
          Your location
        </Label>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Controller
                control={control}
                name={fields.country.name}
                render={(field) => (
                  <Select
                    fullWidth
                    isLoading={countries.loading}
                    onInputChange={(v) =>
                      setSearchInputs({ ...searchInputs, country: v })
                    }
                    placeholder="Country"
                    value={field.field.value}
                    onChange={field.field.onChange}
                    options={baseContentToOptions(countries.list)}
                    error={Boolean(field.fieldState.error)}
                    helperText={field.fieldState.error?.message}
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Controller
                control={control}
                name={fields.city.name}
                render={(field) => (
                  <Select
                    fullWidth
                    placeholder="City"
                    isLoading={cities.loading}
                    value={field.field.value}
                    onInputChange={(v) =>
                      setSearchInputs({ ...searchInputs, city: v })
                    }
                    onChange={field.field.onChange}
                    isDisabled={!country}
                    options={baseContentToOptions(cities.list)}
                    error={Boolean(field.fieldState.error)}
                    helperText={field.fieldState.error?.message}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Controller
            control={control}
            name={fields.language.name}
            render={(field) => (
              <Select
                fullWidth
                label="Primary language for communication"
                placeholder="Language"
                value={field.field.value}
                onChange={field.field.onChange}
                options={baseContentToOptions(languages.list)}
                error={Boolean(field.fieldState.error)}
                helperText={field.fieldState.error?.message}
              />
            )}
          />
        </FormGroup>

        <ExperienceList
          title="Education"
          onAdd={() => setShowModal(true)}
          onDelete={(id) => dispatch(deleteEducation(id))}
          onEdit={(id) => handleEdit(id)}
          items={education.list.map((item) => ({
            id: item.id,
            title: item.school,
            subtitle: item.degree,
            date: `${new Date(item.start_date).toLocaleDateString()}-${new Date(
              item.end_date
            ).toLocaleDateString()}`,
          }))}
        />
      </div>
      {showModal && (
        <div className={classes.modal}>
          <PersonalDataCard subtitle="ADD EDUCATION">
            <EducationForm
              errors={education.errors}
              onSubmit={handleSubmitEducation}
              onCancel={handleCloseModal}
              values={editableEducation}
            />
          </PersonalDataCard>
        </div>
      )}
      <div className={classNames({ [classes.hidden]: showModal })}>
        {error && (
          <FormGroup>
            <HelperText color="error">{error}</HelperText>
          </FormGroup>
        )}

        <StepsNavigation
          nextBtn={{ onClick: handleNewStep }}
          prevBtn={{ onClick: onPrev }}
        />
      </div>
    </div>
  );
};

export default PersonalDetails;
