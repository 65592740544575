import React, { useState } from 'react';
import Camera from 'react-html5-camera-photo';
import Avatar from '../../elements/Avatar';
import Button from '../../elements/Button';
import Modal from '../../elements/Modal';
import SelectFileButton from '../../elements/SelectFileButton';
import classes from './AvatarUpdate.module.scss';
import 'react-html5-camera-photo/build/css/index.css';
import { ERROR_FIELD_PHOTO_FORMAT } from '../../../constants/error-messages';
import HelperText from '../../elements/HelperText';

interface IProps {
  avatar: File | string | null;
  onChang: (file: File | null) => void;
  error?: string;
  maxSize?: number;
}

const AvatarUpdate: React.FC<IProps> = (props) => {
  const { onChang, avatar, error: propsError, maxSize } = props;
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [error, setError] = useState('');

  const validImageTypes = ['image/jpeg', 'image/png'];

  const validatePhoto = (val: File) => {
    if (!validImageTypes.includes(val.type)) {
      return ERROR_FIELD_PHOTO_FORMAT;
    }

    if (maxSize && val.size * 10 ** -6 > maxSize) {
      return `The maximum file size is ${maxSize} MB`;
    }

    return undefined;
  };

  const handleChange = (val: File | null) => {
    let img = val;

    const validationError = val ? validatePhoto(val) : undefined;
    if (validationError) {
      setError(validationError);
      img = null;
    } else {
      setError('');
    }
    onChang(img);
  };

  const handleTakePhoto = (photo: string) => {
    setIsCameraOpen(false);
    fetch(photo)
      .then((res) => res.blob())
      .then((blob) => {
        const newPhoto = new File([blob], 'photo', { type: 'image/png' });
        handleChange(newPhoto);
      });
  };

  return (
    <div>
      <div className={classes.picture}>
        <Avatar
          src={
            typeof avatar === 'string'
              ? avatar
              : avatar && URL.createObjectURL(avatar)
          }
          size={200}
        />
      </div>
      {(error || propsError) && (
        <HelperText color="error" className={classes.error}>
          {error || propsError}
        </HelperText>
      )}
      <div className={classes.btnWrap}>
        <Button fullWidth size="sm" onClick={() => setIsCameraOpen(true)}>
          TAKE PHOTO
        </Button>
      </div>
      <div className={classes.btnWrap}>
        <SelectFileButton
          fullWidth
          onChange={(list) => {
            handleChange(list && list.length ? list[0] : null);
          }}
          size="sm"
          accept={validImageTypes.join(', ')}
        >
          SELECT FROM GALLERY
        </SelectFileButton>
      </div>

      <Modal open={isCameraOpen} onClose={() => setIsCameraOpen(false)}>
        <div className={classes.photo}>
          <Camera
            idealResolution={{ width: 600, height: 600 }}
            onTakePhoto={handleTakePhoto}
            isImageMirror
            isSilentMode
          />
        </div>
      </Modal>
    </div>
  );
};

export default AvatarUpdate;
