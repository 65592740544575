import axios, { Response } from '../libs/axios';
import { IChat, IChatPagination, IMessage, IMessageFile } from '../types/chat';

const server = process.env.REACT_APP_CHAT_SERVER_HTTP || '/';

export const attachFile = (data: File) => {
  const formData = new FormData();
  formData.append('file', data);
  return axios<IMessageFile>({
    url: `${server}/message-file`,
    data: formData,
    method: 'POST',
  });
};

export const requestMessages = (params: {
  page: number;
  userExternalId: number;
}) =>
  axios<{ data: IMessage[]; meta: IChatPagination }>({
    url: `${server}/messages`,
    method: 'GET',
    params,
  });

export const requestConversations = (params: {
  page: number;
  userExternalId?: number;
  name?: string;
}) =>
  axios<{ data: IChat[]; meta: IChatPagination }>({
    url: `${server}/conversations`,
    method: 'GET',
    params,
  });

export const requestChatSparring = (id: number) =>
  axios<
    Response<{
      id: number;
      round: number;
      status: number;
      status_name: string;
      sparring_users: {
        user_id: number;
        complete: boolean;
      }[];
      topic_knock_out: boolean;
    }>
  >({
    url: `/sparring/${id}`,
    method: 'GET',
  });

export const requestChatSparringCompleteRound = (id: number) =>
  axios<
    Response<{
      id: number;
      round: number;
      sparring_users: {
        user_id: number;
        complete: boolean;
      }[];
      status: number;
      status_name: string;
    }>
  >({
    url: `/sparring/complete-round/${id}`,
    method: 'POST',
  });

export const requestChatSparringKo = (params: {
  user_id: number;
  note?: string;
}) =>
  axios({
    url: '/sparring/knock-out',
    method: 'POST',
    params,
  });
