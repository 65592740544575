import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Session } from '../utils/session';
import { ISession } from '../types/authentication';

const sessionInstance = Session.getInstance();

const instance = axios.create();

const generateUrl = (url: string) =>
  url.startsWith('http') ? url : `${process.env.REACT_APP_API_URL || ''}${url}`;

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: Error | null, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

instance.interceptors.request.use(
  (config) => {
    const session = sessionInstance.getSession();
    const url = config.url ? generateUrl(config.url) : undefined;
    return {
      ...config,
      url,
      headers: {
        ...config.headers,
        Authorization: session ? `Bearer ${session.access_token}` : '',
      },
    };
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if (
      error.response.status === 400 &&
      originalRequest.url.includes('/user/refresh-token')
    ) {
      sessionInstance.removeSession();
      return Promise.reject(error);
    }
    const session = sessionInstance.getSession();
    if (error.response.status === 401 && !originalRequest.retry && session) {
      originalRequest.retry = true;

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return instance(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        instance
          .post<{ data: { token: ISession } }>('/user/refresh-token', {
            refresh_token: session.refresh_token,
          })
          .then(({ data }) => {
            sessionInstance.setSession(data.data.token);
            instance.defaults.headers.common.Authorization = `Bearer ${data.data.token.access_token}`;
            originalRequest.headers.Authorization = `Bearer ${data.data.token.access_token}`;
            processQueue(null, data.data.token.access_token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  }
);

type Error = {
  status: 'fail';
  message: string;
  errors?: {
    [k: string]: string | string[];
  };
};

const api = <Response, E = Error>(config: AxiosRequestConfig) =>
  new Promise<Response>((resolve, reject) => {
    instance
      .request<Response>({
        ...config,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        const error = err as AxiosError<E>;
        if (!error.response) {
          throw err;
        }
        reject(error.response.data);
      });
  });

export interface Response<T> {
  data: T;
}
export interface ResponseWithPagination<T> {
  data: T;
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    per_page: number;
    to: number;
    total: number;
  };
}

export default api;
