import { createReducer } from '@reduxjs/toolkit';
import {
  ChatSparringStatus,
  IChatPagination,
  IChatWithDetails,
} from '../../types/chat';
import { clearAuthData } from '../actions/authentication';
import {
  chatSparringKo,
  chatSparringComplateRound,
  getChatSparring,
  getChatsList,
  getMessage,
  readMessages,
  setChatsList,
  setChatsPagination,
  setChatsStatus,
  setUnreadMessages,
  setSparring,
  setKo,
  chatSparringRate,
} from '../actions/chat';

interface State {
  list: IChatWithDetails[];
  loading: boolean;
  pagination: IChatPagination | null;
  error: string;
  unreadCount: number;
}

const initialState: State = {
  list: [],
  pagination: null,
  loading: false,
  error: '',
  unreadCount: 0,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setChatsList, (state, action) => {
    state.list = action.payload;
    state.loading = false;
  });
  builder.addCase(setChatsPagination, (state, action) => {
    state.pagination = action.payload;
  });
  builder.addCase(setChatsStatus, (state, action) => ({
    ...state,
    ...action.payload,
  }));

  builder.addCase(getChatsList.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getChatsList.fulfilled, (state, action) => {
    state.list = action.payload.data
      .filter((chat) => chat.recipients.length)
      .map((chat) => ({
        ...chat,
        details: {
          type: 'single',
          userId: chat.recipients[0].id,
          name: chat.recipients[0].userFullName,
          photo: chat.recipients[0].photoSmallUrl,
          sparring: chat.recipients[0].sparring,
          topicKnockOut: chat.recipients[0].topicKnockOut,
          userRatingRated: chat.recipients[0].userRatingRated,
        },
      }));
    state.pagination = action.payload.meta;
    state.loading = false;
  });

  builder.addCase(getChatSparring.fulfilled, (state, action) => {
    const currentChatIndex = state.list.findIndex(
      (chat) => chat.details.userId === action.meta.arg
    );

    if (currentChatIndex > -1) {
      state.list[currentChatIndex].details.sparring = {
        id: action.payload.data.id,
        round: action.payload.data.round,
        sparringUsers: action.payload.data.sparring_users.map((user) => ({
          userId: user.user_id,
          knockOut: false,
          complete: user.complete,
        })),
        status: action.payload.data.status as any,
        statusName: action.payload.data.status_name,
      };
      state.list[currentChatIndex].details.topicKnockOut =
        action.payload.data.topic_knock_out;
    }
  });

  builder.addCase(chatSparringComplateRound.fulfilled, (state, action) => {
    const currentChatIndex = state.list.findIndex(
      (chat) => chat.details.userId === action.meta.arg
    );
    if (currentChatIndex > -1) {
      const { sparring } = state.list[currentChatIndex].details;
      if (sparring) {
        state.list[currentChatIndex].details.sparring = {
          ...sparring,
          round: action.payload.data.round,
          sparringUsers: action.payload.data.sparring_users.map((user) => ({
            userId: user.user_id,
            knockOut: false,
            complete: user.complete,
          })),
        };
      }
    }
  });

  builder.addCase(chatSparringKo.fulfilled, (state, action) => {
    const currentChatIndex = state.list.findIndex(
      (chat) => chat.details.userId === action.meta.arg.id
    );
    if (currentChatIndex > -1) {
      const { sparring } = state.list[currentChatIndex].details;
      if (sparring) {
        state.list[currentChatIndex].details.sparring = {
          ...sparring,
          status: ChatSparringStatus.ko,
          statusName: 'KO',
        };
      }
    }
  });

  builder.addCase(chatSparringRate.fulfilled, (state, action) => {
    const currentChatIndex = state.list.findIndex(
      (chat) => chat.details.userId === action.meta.arg.user_id
    );
    if (currentChatIndex > -1) {
      state.list[currentChatIndex].details.userRatingRated = true;
    }
  });

  builder.addCase(getChatsList.rejected, (state) => {
    state.loading = true;
  });

  builder.addCase(setUnreadMessages, (state, action) => {
    state.unreadCount = action.payload;
  });

  builder.addCase(readMessages, (state, action) => {
    state.list = state.list.map((chat) => ({
      ...chat,
      messageUnreadCount:
        chat.id === action.payload ? 0 : chat.messageUnreadCount,
    }));
  });

  builder.addCase(getMessage, (state, action) => {
    state.list = state.list
      .map((chat) => {
        const isCurrentChat = chat.id === action.payload.conversationId;
        const isMyNessage =
          chat.details.userId !== action.payload.senderExternalId;

        return !isCurrentChat
          ? chat
          : {
              ...chat,
              messageLast: action.payload,
              messageUnreadCount: isMyNessage
                ? chat.messageUnreadCount
                : chat.messageUnreadCount + 1,
            };
      })
      .sort((a, b) => {
        if (!a.messageLast) {
          return 0;
        }
        if (!b.messageLast) {
          return -1;
        }
        return b.messageLast.createdAt - a.messageLast.createdAt;
      });
  });

  builder.addCase(setSparring, (state, action) => {
    const chatIndex = state.list.findIndex(
      (chat) =>
        chat.details.sparring && chat.details.sparring.id === action.payload.id
    );
    const { sparring } = state.list[chatIndex].details;
    if (chatIndex > -1 && sparring) {
      state.list[chatIndex].details.sparring = action.payload;
    }
  });

  builder.addCase(setKo, (state, action) => {
    const chatIndex = state.list.findIndex(
      (chat) => chat.details.sparring && chat.details.userId === action.payload
    );
    const { sparring } = state.list[chatIndex].details;
    if (chatIndex > -1 && sparring) {
      state.list[chatIndex].details.sparring = {
        ...sparring,
        status: ChatSparringStatus.ko,
        sparringUsers: sparring.sparringUsers.map((user) => ({
          ...user,
          knockOut: true,
        })),
      };
    }
  });

  builder.addCase(clearAuthData, () => initialState);
});

interface Store {
  chats: State;
}

export const selectChat = (state: Store) => state.chats;
export const selectChatById = (state: Store, id: string | null) =>
  state.chats.list.find((chat) => chat.id === id);

export default reducer;
