import { combineReducers } from '@reduxjs/toolkit';
import user from './user';
import userSettings from './user-settings';
import userEducation from './user-education';
import userExperience from './user-experience';
import dynamicPages from './dynamic-pages';
import dynamicContent from './dynamic-content';
import authentication from './authentication';
import settings from './settings';
import sideMenu from './side-menu';
import search from './search';
import notifications from './notifications';
import topic from './topic';
import topics from './topics';
import member from './member';
import sparrings from './sparrings';
import webSocket from './web-socket';
import chats from './chats';
import currentChat from './current-chat';
import globalModals from './global-modals';
import subscriptions from './subscriptions';

const reducer = combineReducers({
  authentication,
  chats,
  currentChat,
  dynamicContent,
  dynamicPages,
  member,
  notifications,
  search,
  settings,
  sideMenu,
  sparrings,
  subscriptions,
  topic,
  topics,
  user,
  userEducation,
  userExperience,
  userSettings,
  webSocket,
  globalModals,
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;
