// Set only hex colors

const white = '#ffffff';
const black = '#333333';

const palette = {
  common: { black, white },
  background: {
    primary: white,
    secondary: '#E5E5E5',
  },
  text: {
    primary: black,
    secondary: '#889297',
  },
  primary: {
    main: '#00B0F2',
    contrastText: white,
    dark: '#009ed9',
    light: '#0dbdff',
  },
  secondary: {
    main: '#051F5F',
    contrastText: white,
    dark: '#041b55',
    light: '#072b85',
  },
  success: {
    main: '#00C213',
    contrastText: white,
    dark: '#00ae11',
    light: '#00e116',
  },
  warning: {
    main: '#FF7A00',
    contrastText: white,
    light: '#FFC107',
    dark: '#e56d00',
  },
  error: {
    main: '#E73131',
    contrastText: white,
    light: '#e94545',
    dark: '#e11a1a',
  },
  default: {
    main: '#889297',
    contrastText: white,
    light: '#939ca1',
    dark: '#788389',
  },
  divider: '#DDDDDD',
  gray: {
    200: '#f7f8fa',
    300: '#EEEEEE',
    900: '#2c2d32',
  },
};

export default palette;
