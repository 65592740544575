import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleError422 } from '../../helpers';
import { formatTimeToMs, formatTimeToS } from '../../helpers/time';
import {
  addEducation as addEducationByApi,
  requestEducation,
  removeEducation,
  updateEducation,
} from '../../repositories/user-education';
import { IError422 } from '../../types/errors';
import { IEducation } from '../../types/user';

export const getEducation = createAsyncThunk('getEducation', async () => {
  const response = await requestEducation();
  return response.data.map((i) => formatTimeToMs(i));
});

export const deleteEducation = createAsyncThunk(
  'deleteEducation',
  async (id: number) => {
    const response = await removeEducation(id);
    return formatTimeToMs(response.data);
  }
);

export const addEducation = createAsyncThunk<
  IEducation,
  Omit<IEducation, 'id'>,
  { rejectValue: Partial<Record<keyof IEducation, string>> }
>('addEducation', async (payload, { rejectWithValue }) => {
  try {
    const response = await addEducationByApi(formatTimeToS(payload));
    return formatTimeToMs(response.data);
  } catch (error) {
    const err = error as IError422;
    if (err.errors) {
      return rejectWithValue(handleError422(err));
    }
    return rejectWithValue({});
  }
});

export const editEducation = createAsyncThunk<
  IEducation,
  IEducation,
  { rejectValue: Partial<Record<keyof IEducation, string>> }
>('editEducation', async (payload, { rejectWithValue }) => {
  try {
    const response = await updateEducation(payload.id, formatTimeToS(payload));
    return formatTimeToMs(response.data);
  } catch (error) {
    const err = error as IError422;
    if (err.errors) {
      return rejectWithValue(handleError422(err));
    }
    return rejectWithValue({});
  }
});
