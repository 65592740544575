import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from '../Grid';
import HelperText from '../HelperText';
import Label from '../Label';
import Select from '../Select';

type Props = {
  value?: number;
  onChange?: (date: number) => void;
  maxYear?: number;
  minYear?: number;
  error?: boolean;
  helperTex?: string;
  label?: string;
};

interface Option {
  label: string;
  value: string;
}

const DateSelect: React.FC<Props> = (props) => {
  const {
    maxYear = new Date().getFullYear(),
    minYear = 1970,
    onChange,
    value,
    error,
    helperTex,
    label,
  } = props;

  const parseTimestamp = (date: number) => {
    const valDate = new Date(date);
    const separatedDate = {
      day: valDate.getDate(),
      month: valDate.getMonth() + 1,
      year: valDate.getFullYear(),
    };
    return {
      day: {
        label:
          separatedDate.day < 10
            ? `0${separatedDate.day}`
            : separatedDate.day.toString(),
        value:
          separatedDate.day < 10
            ? `0${separatedDate.day}`
            : separatedDate.day.toString(),
      },
      month: {
        label: moment(
          separatedDate.month < 10
            ? `0${separatedDate.month}`
            : separatedDate.month.toString(),
          'MM'
        ).format('MMMM'),
        value:
          separatedDate.month < 10
            ? `0${separatedDate.month}`
            : separatedDate.month.toString(),
      },
      year: {
        label: separatedDate.year.toString(),
        value: separatedDate.year.toString(),
      },
    };
  };

  const [day, setDay] = useState<Option | undefined>(
    value ? parseTimestamp(value).day : undefined
  );
  const [month, setMonth] = useState<Option | undefined>(
    value ? parseTimestamp(value).month : undefined
  );
  const [year, setYear] = useState<Option | undefined>(
    value ? parseTimestamp(value).year : undefined
  );

  const days = [];
  for (let i = 1; i <= 31; i += 1) {
    const val = i.toString().length < 2 ? `0${i}` : i.toString();
    days.push({ label: val, value: val });
  }
  const months = [];
  for (let i = 1; i <= 12; i += 1) {
    const val = i.toString().length < 2 ? `0${i}` : i.toString();
    months.push({ label: moment(val, 'MM').format('MMMM'), value: val });
  }
  const years = [];
  for (let i = maxYear; i >= minYear; i -= 1) {
    years.push({ label: i.toString(), value: i.toString() });
  }

  useEffect(() => {
    if (day && month && year) {
      const val = moment(`${month.value}/${day.value}/${year.value}`)
        .utc(true)
        .valueOf();
      if (val !== value && onChange) {
        onChange(val);
      }
    }
  }, [day, month, year]);

  return (
    <Row>
      {label && (
        <Col xs={12}>
          <Label>{label}</Label>
        </Col>
      )}
      <Col xs={4}>
        <Select
          fullWidth
          placeholder="Month"
          value={month}
          options={months}
          onChange={(v) => setMonth(v || undefined)}
          error={error}
          menuPortal="body"
        />
      </Col>
      <Col xs={4}>
        <Select
          fullWidth
          placeholder="Day"
          value={day}
          options={days}
          onChange={(v) => setDay(v || undefined)}
          error={error}
          menuPortal="body"
        />
      </Col>
      <Col xs={4}>
        <Select
          fullWidth
          placeholder="Year"
          value={year}
          options={years}
          onChange={(v) => setYear(v || undefined)}
          error={error}
          menuPortal="body"
        />
      </Col>
      <Col>
        {helperTex && <HelperText color="error">{helperTex}</HelperText>}
      </Col>
    </Row>
  );
};

export default DateSelect;
