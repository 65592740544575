import React, { useEffect } from 'react';
import { useDispatch, useSelector } from '../../../libs/redux';
import Routes from '../../../routes';
import { setSession } from '../../../store/actions/authentication';
import { getUser, setUser } from '../../../store/actions/user';
import { connect } from '../../../store/actions/web-socket';
import { selectAuthentication } from '../../../store/reducers/authentication';
import { selectUser } from '../../../store/reducers/user';
import { selectWebSocket } from '../../../store/reducers/web-socket';
import { WebSocketStatus } from '../../../types/general';
import { Session } from '../../../utils/session';
import Loader from '../../elements/Loader';
import ErrorTemplate from '../../templates/Error';
import { getSettings } from '../../../store/actions/settings';
import { selectSettings } from '../../../store/reducers/settings';

const App: React.FC = () => {
  const { loaded, user } = useSelector(selectUser);
  const { status } = useSelector(selectWebSocket);
  const { session } = useSelector(selectAuthentication);
  const settings = useSelector(selectSettings);
  const dispatch = useDispatch();
  // temporary solution removed useWindowFocus hook
  const windowFocus = true;

  const sessionInstance = Session.getInstance();

  const isOnline = navigator.onLine;

  useEffect(() => {
    sessionInstance.setListener((s) => {
      dispatch(setSession(s));
    });
    if (session) {
      dispatch(getUser());
    } else {
      dispatch(setUser(null));
    }
  }, []);

  useEffect(() => {
    if (!settings.loaded && !settings.loading) {
      dispatch(getSettings());
    }
  }, []);

  useEffect(() => {
    if (
      user &&
      status === WebSocketStatus.disconnected &&
      session &&
      windowFocus
    ) {
      dispatch(connect(session.access_token));
    }
  }, [user, status, session, windowFocus]);

  if (!loaded) {
    return <Loader position="fixed" />;
  }

  if (!isOnline && process.env.NODE_ENV === 'production') {
    return (
      <ErrorTemplate
        title="You are offline!"
        message="Reconnect to the internet and try again"
      />
    );
  }
  return <Routes />;
};

export default App;
