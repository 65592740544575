import React, { useEffect } from 'react';
import { useDispatch, useSelector } from '../../../libs/redux';
import { getFaqs } from '../../../store/actions/dynamic-content';
import { selectDynamicContent } from '../../../store/reducers/dynamic-content';
import Accordion from '../../elements/Accordion';

const FaqList: React.FC = () => {
  const { faq } = useSelector(selectDynamicContent);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!faq.loaded && !faq.loading) {
      dispatch(getFaqs());
    }
  }, []);

  return (
    <div>
      {faq.list.map((item) => (
        <Accordion key={item.id} title={item.question} content={item.answer} />
      ))}
    </div>
  );
};

export default FaqList;
