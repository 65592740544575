import React, { useState, useEffect } from 'react';
import { useSelector } from '../../../libs/redux';
import { selectUser } from '../../../store/reducers/user';
import Container from '../../elements/Container';
import Loader from '../../elements/Loader';
import PersonalDataCard from '../../elements/PersonalDataCard';
import Transition from '../../elements/Transition';
import BaseLayout from '../../layouts/Base';
import Experience from '../../modules/Onboarding/Experience';
import Interests from '../../modules/Onboarding/Interests';
import PersonalDetails from '../../modules/Onboarding/PersonalDetails';
import Picture from '../../modules/Onboarding/Picture';
import classes from './Onboarding.module.scss';

interface IStep {
  key: 'photo' | 'details' | 'experience' | 'interests';
  title: string;
  component: React.ReactNode;
}

const OnboardingTemplate: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { user, loading, updating } = useSelector(selectUser);

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const steps: IStep[] = !user
    ? []
    : [
        {
          key: 'photo',
          title: 'Picture',
          component: <Picture onNext={handleNextStep} />,
        },
        {
          key: 'details',
          title: 'Personal details',
          component: (
            <PersonalDetails
              onNext={handleNextStep}
              onPrev={handlePrevStep}
              user={user}
            />
          ),
        },
        {
          key: 'experience',
          title: 'LEVEL IN BUSINESS',
          component: (
            <Experience onNext={handleNextStep} onPrev={handlePrevStep} />
          ),
        },
        {
          key: 'interests',
          title: 'WHY ARE YOU HERE?',
          component: <Interests user={user} onPrev={handlePrevStep} />,
        },
      ];

  useEffect(() => {
    steps.every((step, i) => {
      if (!user || !user.onboarding_fields) {
        return false;
      }
      if (step.key === 'photo' && !user.photo_url) {
        setCurrentStep(i);
        return false;
      }
      if (
        step.key === 'details' &&
        (!user.user_detail ||
          !user.interests ||
          !user.city ||
          !user.language ||
          user.onboarding_fields.includes('user_educations'))
      ) {
        setCurrentStep(i);
        return false;
      }
      if (
        step.key === 'experience' &&
        user.onboarding_fields.includes('user_experiences')
      ) {
        setCurrentStep(i);
        return false;
      }
      if (
        step.key === 'interests' &&
        (!user.interaction_types?.length || !user.looking_occupations?.length)
      ) {
        setCurrentStep(i);
        return false;
      }
      return true;
    });
  }, []);

  return (
    <BaseLayout>
      <div className={classes.wrap}>
        <Container size="md" disablePaddings>
          {steps.map((step, i) => (
            <Transition
              in={currentStep === i}
              mode="slide"
              key={step.key}
              timeout={{
                enter: 500,
                exit: 0,
              }}
              unmountOnExit
            >
              <PersonalDataCard title="Onboarding:" subtitle={step.title}>
                <Loader open={loading || updating} position="absolute" />
                {step.component}
              </PersonalDataCard>
            </Transition>
          ))}
        </Container>
      </div>
    </BaseLayout>
  );
};

export default OnboardingTemplate;
