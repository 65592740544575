import React from 'react';
import classNames from 'classnames';
import classes from './Loader.module.scss';
import CircularProgress from '../CircularProgress';
import palette from '../../../styles/palette';
import Backdrop from '../Backdrop';

interface Props {
  position?: 'static' | 'fixed' | 'absolute';
  open?: boolean;
  className?: string;
  zIndex?: number;
  size?: number;
  color?: string;
}

const Loader: React.FC<Props> = (props) => {
  const {
    position = 'static',
    color = palette.primary.main,
    size = 60,
    open = true,
    className,
    zIndex,
  } = props;
  if (position === 'fixed') {
    return (
      <Backdrop open={open} zIndex={zIndex}>
        <CircularProgress color={color} size={size} />
      </Backdrop>
    );
  }
  return (
    <div
      className={classNames(classes.wrap, className, classes[position], {
        [classes.hidden]: !open,
      })}
      style={{ zIndex }}
    >
      <CircularProgress color={color} size={size} />
    </div>
  );
};

export default Loader;
