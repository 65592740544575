import React from 'react';
import { Card, CardBody } from '../Card';
import CardHeader from '../Card/CardHeader';
import Logo from '../Logo';
import classes from './PersonalDataCard.module.scss';

interface IProps {
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
}

const PersonalDataCard: React.FC<IProps> = (props) => {
  const { children, title, subtitle } = props;
  return (
    <Card>
      <CardHeader>
        <div className={classes.wrap}>
          <div className={classes.logo}>
            <Logo />
          </div>
          <div className={classes.textWrap}>
            <h2>{title}</h2>
            <h3>{subtitle}</h3>
          </div>
        </div>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
};

export default PersonalDataCard;
