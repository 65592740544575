import React from 'react';
import { useForm } from 'react-hook-form';
import { ERROR_FIELD_PASSWORD } from '../../../constants/error-messages';
import { PASSWORD } from '../../../constants/regexp';
import Button from '../../elements/Button';
import FormGroup from '../../elements/FormGroup';
import TextField from '../../elements/TextField';
import classes from './ChangePasswordForm.module.scss';

interface IProps {
  errors: {
    password?: string;
    email?: string;
  };
  onSubmit: (data: { password: string; confirmPassword: string }) => void;
}

const ChangePasswordForm: React.FC<IProps> = (props) => {
  const { errors: err, onSubmit } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    password: string;
    confirmPassword: string;
  }>();
  const fields = {
    password: register('password', {
      required: ERROR_FIELD_PASSWORD,
      pattern: {
        value: PASSWORD,
        message: ERROR_FIELD_PASSWORD,
      },
    }),
    confirmPassword: register('confirmPassword', {
      required: ERROR_FIELD_PASSWORD,
      pattern: {
        value: PASSWORD,
        message: ERROR_FIELD_PASSWORD,
      },
    }),
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {err.email && (
          <p style={{ color: 'var(--error-main)', marginBottom: 10 }}>
            {err.email}
          </p>
        )}
        <FormGroup>
          <TextField
            fullWidth
            label="Password"
            type="password"
            name={fields.password.name}
            onChange={fields.password.onChange}
            inputRef={fields.password.ref}
            error={Boolean(errors.password || err?.password)}
            helperText={errors.password?.message || err?.password}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            fullWidth
            label="Confirm Password"
            type="password"
            name={fields.confirmPassword.name}
            onChange={fields.confirmPassword.onChange}
            inputRef={fields.confirmPassword.ref}
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword?.message}
          />
        </FormGroup>

        <div className={classes.btnWrap}>
          <Button type="submit" variant="contained" shadow size="lg">
            SUBMIT
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
