import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from '../../../libs/redux';
import { selectSearch } from '../../../store/reducers/search';
import Loader from '../../elements/Loader';
import Pagination from '../../elements/Pagination';
import SearchForm from '../../modules/SearchForm';
import SearchBadges from '../../modules/SearchBadges';
import classes from './Search.module.scss';
import { ReactComponent as ArrowDown } from '../../../assets/img/icon/chevron-down.svg';
import InnerPageCard from '../../elements/InnerPageCard';
import { SEARCH } from '../../../constants/routes';
import MemberCard from '../../modules/MemberCard';
import usePagination from '../../../hooks/usePagination';
import { search } from '../../../store/actions/search';
import ScrollBar from '../../elements/ScrollBar';

const SearchTemplate: React.FC = () => {
  const { result, loading, pagination, fields } = useSelector(selectSearch);
  const [openSearchForm, setOpenSearchForm] = useState(false);
  const dispatch = useDispatch();

  const { currentPage, setCurrentPage, visibleList, totalPages } =
    usePagination({
      elementsPerPage: pagination.per_page,
      list: result,
      serverPagination: pagination,
      onRequest(page) {
        dispatch(search({ page, ...fields }));
      },
      scrollTopOnPAgeChange: true,
    });

  useEffect(() => {
    if (openSearchForm) {
      setOpenSearchForm(false);
    }
  }, [result]);

  return (
    <section className={classes.row}>
      <div className={classes.result}>
        <InnerPageCard>
          <div className={classes.resultList}>
            <div className={classes.searchInfo}>
              {result.length > 0 && (
                <div className={classes.count}>
                  {pagination.total} Result{pagination.total > 1 ? 's' : ''}
                </div>
              )}

              <button
                type="button"
                className={classNames(classes.displaySearchForm, {
                  [classes.open]: openSearchForm,
                })}
                onClick={() => setOpenSearchForm(!openSearchForm)}
              >
                Specify Your Search
                <ArrowDown />
              </button>
              <SearchBadges />
            </div>

            {!visibleList.length && !loading && (
              <div className={classes.nothingFound}>
                <h2>Nothing found</h2>
              </div>
            )}

            <div
              className={classNames({
                [classes.hidden]: openSearchForm,
              })}
            >
              {visibleList.map((user) => (
                <MemberCard
                  key={user.id}
                  id={user.id}
                  name={`${user.first_name} ${user.last_name}`}
                  avatar={user.photo_url}
                  position={
                    user.user_last_experience &&
                    `${user.user_last_experience.title} at ${user.user_last_experience.industry}`
                  }
                  interests={user.interests.map((i) => i.name).join(', ')}
                  additionalButton={{
                    text: 'REQUEST SPARRING',
                    to: `${SEARCH.fullPath}/${user.id}#form`,
                  }}
                  statistic={
                    user.user_sum_rating
                      ? {
                          rating: user.user_sum_rating,
                          communications: user.sparring_users_count || 0,
                          ko: user.sparring_knock_outs_percentage || 0,
                        }
                      : undefined
                  }
                />
              ))}
            </div>

            {result.length > 0 && pagination.total > pagination.per_page && (
              <div className={classes.pagination}>
                <Pagination
                  total={totalPages}
                  current={currentPage}
                  pageRangeDisplayed={3}
                  onChange={(p) => setCurrentPage(p)}
                />
              </div>
            )}
          </div>
          <Loader open={loading} position="absolute" />
        </InnerPageCard>
      </div>
      <div
        className={classNames(classes.searchForm, {
          [classes.open]: openSearchForm,
        })}
      >
        <InnerPageCard className={classes.searchCard} size="small">
          <div className={classes.formWrap}>
            <h4>Specify Your Search</h4>
            <div className={classes.form}>
              <ScrollBar>
                <SearchForm />
              </ScrollBar>
            </div>
          </div>
        </InnerPageCard>
      </div>
    </section>
  );
};

export default SearchTemplate;
