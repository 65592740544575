import { Access } from '../types/general';

const routes: Route[] = [];

class Route {
  path: string;

  name: string;

  access: Access;

  fullPath: string;

  constructor(config: {
    path: string;
    name: string;
    access?: Access;
    parent?: Route;
  }) {
    this.path = config.path;
    this.fullPath = `${config.parent ? config.parent.fullPath : ''}/${
      config.path
    }`;
    this.name = config.name;
    this.access =
      config.access || (config.parent && config.parent.access) || Access.all;
    routes.push(this);
  }
}

export const HOME = new Route({
  path: '',
  name: 'Home Page',
});

export const TEXT_PAGE = new Route({
  path: 'page',
  name: 'Text Page',
});

export const PROFILE = new Route({
  path: 'profile',
  name: 'Profile',
  access: Access.active,
});

export const EDIT_PROFILE = new Route({
  path: 'edit',
  name: 'Edit Profile',
  access: Access.active,
  parent: PROFILE,
});
export const STORE_EDUCATION = new Route({
  path: 'education',
  name: 'Education',
  access: Access.active,
  parent: PROFILE,
});
export const STORE_EXPERIENCE = new Route({
  path: 'experience',
  name: 'Experience',
  access: Access.active,
  parent: PROFILE,
});

export const MEMBER = new Route({
  path: 'member',
  name: 'Member',
  access: Access.active,
});

export const SEARCH = new Route({
  path: 'search',
  name: 'Search',
  access: Access.active,
});

export const SPARRING = new Route({
  path: 'sparring',
  name: 'Sparring',
  access: Access.active,
});

export const TOPICS = new Route({
  path: 'topics',
  name: 'Your Topics',
  access: Access.active,
});
export const STORE_TOPICS = new Route({
  path: 'store',
  name: 'Topics',
  parent: TOPICS,
});

export const MESSAGES = new Route({
  path: 'messages',
  name: 'Messages',
  access: Access.active,
});

export const LINKEDIN = new Route({
  path: 'linkedin',
  name: 'Linkedin',
});

export const SIGN_IN = new Route({
  path: 'sign-in',
  name: 'Sign In',
  access: Access.notAuthenticated,
});

export const SIGN_UP = new Route({
  path: 'sign-up',
  name: 'Sign Up',
  access: Access.notAuthenticated,
});
export const RESTORE_PASSWORD = new Route({
  path: 'restore-password',
  name: 'Forgot Password',
  access: Access.notAuthenticated,
});

export const ONBOARDING = new Route({
  path: 'onboarding',
  name: 'Onboarding',
  access: Access.authenticated,
});

export default routes;
