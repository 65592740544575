import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ERROR_FIELD_REQUIRED } from '../../../constants/error-messages';
import {
  dynamicContentToOptions,
  getOptionFromDynamicContent,
} from '../../../helpers/dynamic-content';
import { useDispatch } from '../../../libs/redux';
import useDynamicContent from '../../../hooks/useDynamicContent';
import { setSparringRequestErrors } from '../../../store/actions/sparrings';
import { selectSparrings } from '../../../store/reducers/sparrings';
import { ISparringRequest } from '../../../types/sparring';
import Button from '../../elements/Button';
import FormGroup from '../../elements/FormGroup';
import HelperText from '../../elements/HelperText';
import Loader from '../../elements/Loader';
import Select from '../../elements/Select';
import TextField from '../../elements/TextField';

interface IProps {
  onSubmit: (data: Omit<ISparringRequest, 'recipient_id'>) => void;
}

const SparringRequestForm: React.FC<IProps> = (props) => {
  const { onSubmit } = props;
  const [searchInput, setSearchInput] = useState({ toppics: '' });
  const { occupations, topics } = useDynamicContent([
    'occupations',
    { key: 'topics', search: searchInput.toppics },
  ]);
  const { sending, requestErrors } = useSelector(selectSparrings);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSparringRequestErrors({}));
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Omit<ISparringRequest, 'recipient_id'>>();

  const fields = {
    message: register('message', {
      required: ERROR_FIELD_REQUIRED,
    }),
    keywords: register('keywords', {
      required: ERROR_FIELD_REQUIRED,
    }),
    topic_id: register('topic_id', {
      required: ERROR_FIELD_REQUIRED,
    }),
    occupation_id: register('occupation_id', {
      required: ERROR_FIELD_REQUIRED,
    }),
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Loader position="absolute" open={sending} />
      {requestErrors.recipient_id && (
        <HelperText color="error">{requestErrors.recipient_id}</HelperText>
      )}
      <FormGroup>
        <TextField
          fullWidth
          multiline
          label="Request Message"
          placeholder="Type here"
          name={fields.message.name}
          onChange={fields.message.onChange}
          textareaRef={fields.message.ref}
          error={Boolean(errors.message || requestErrors.message)}
          helperText={errors.message?.message || requestErrors.message}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          control={control}
          name={fields.occupation_id.name}
          render={(field) => (
            <Select
              fullWidth
              label="Request Purpose"
              options={dynamicContentToOptions(occupations.list)}
              value={
                field.field.value
                  ? getOptionFromDynamicContent(
                      occupations.list,
                      field.field.value
                    )
                  : null
              }
              onChange={(v) => field.field.onChange(v && v.value)}
              error={Boolean(
                field.fieldState.error || requestErrors.occupation_id
              )}
              helperText={
                field.fieldState.error?.message || requestErrors.occupation_id
              }
            />
          )}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          label="Keywords"
          name={fields.keywords.name}
          onChange={fields.keywords.onChange}
          inputRef={fields.keywords.ref}
          error={Boolean(errors.keywords || requestErrors.keywords)}
          helperText={errors.keywords?.message || requestErrors.keywords}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          control={control}
          name={fields.topic_id.name}
          render={(field) => (
            <Select
              fullWidth
              label="Request Related Topic"
              placeholder="Search..."
              isLoading={topics.loading}
              onInputChange={(v) =>
                setSearchInput({ ...searchInput, toppics: v })
              }
              options={
                searchInput.toppics.length
                  ? dynamicContentToOptions(topics.list)
                  : []
              }
              noOptionsMessage={({ inputValue }) =>
                inputValue.length ? 'Nothing found' : 'Enter a topic name'
              }
              value={
                field.field.value
                  ? getOptionFromDynamicContent(topics.list, field.field.value)
                  : null
              }
              onChange={(v) => field.field.onChange(v && v.value)}
              error={Boolean(field.fieldState.error || requestErrors.topic_id)}
              helperText={
                field.fieldState.error?.message || requestErrors.topic_id
              }
            />
          )}
        />
      </FormGroup>

      <Button type="submit">REQUEST</Button>
    </form>
  );
};

export default SparringRequestForm;
