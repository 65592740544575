import React, { useState } from 'react';
import { Subscription } from '../../../types/subscription';
import Button from '../../elements/Button';
import Select from '../../elements/Select';
import classes from './Subscriptions.module.scss';
import { ReactComponent as Check } from '../../../assets/img/icon/check-circle.svg';

interface ItemProps {
  subscription: Subscription;
  onSelect: (id: number) => void;
}

const Item: React.FC<ItemProps> = (props) => {
  const { subscription, onSelect } = props;

  const sortedPrices = [...subscription.subscription_durations]
    .sort((a, b) => b.months - a.months)
    .map((price) => ({
      ...price,
      label: `${price.months} Months`,
      value: price.id,
    }));

  if (!sortedPrices.length) {
    sortedPrices.push({
      price: 0,
      months: 0,
      label: '',
      id: 0,
      promo: '',
      value: 0,
    });
  }
  const [plan, setPlan] = useState(sortedPrices[0]);

  const perMonth = plan.price / plan.months;

  const handlePrice = (price: number) => {
    if (!price) {
      return <>Free!</>;
    }
    const [integer, fraction] = price.toString().split('.');
    return (
      <>
        ${integer}
        <sup>{fraction}</sup>
      </>
    );
  };

  return (
    <div className={classes.item}>
      <h4>{subscription.name}</h4>
      <div className={classes.imgWrap}>
        <img src={subscription.image} alt="" />
      </div>
      <div className={classes.descripton}>{subscription.description}</div>

      <ul className={classes.list}>
        {subscription.subscription_benefits.map((benefit) => (
          <li key={benefit.id}>
            <Check />
            {benefit.description}
          </li>
        ))}
      </ul>
      {subscription.subscription_durations.length > 0 && (
        <>
          <div className={classes.subscribeTitle}>Subscribe for:</div>
          <Select
            fullWidth
            options={sortedPrices}
            value={plan}
            onChange={(value) => {
              if (value) {
                setPlan(value);
              }
            }}
            menuPortal="body"
            isSearchable={false}
          />
        </>
      )}

      <div className={classes.price}>{handlePrice(plan.price)}</div>
      <div className={classes.priceDetails}>
        <div className={classes.perMonth}>
          {Boolean(perMonth) && `$${perMonth.toFixed(2)}/per Month`}
        </div>
        <div>{plan.promo}</div>
      </div>

      <Button fullWidth onClick={() => onSelect(plan.id)}>
        {plan.price === 0 ? 'GET STARTED' : 'SUBSCRIBE NOW'}
      </Button>
    </div>
  );
};

export default Item;
