import React from 'react';
import { To } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import classNames from 'classnames';
import classes from './Button.module.scss';
import { Colors, Sizes } from '../../../types/general';

type HtmlButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export type IProps = HtmlButtonProps & {
  children?: React.ReactNode;
  variant?: 'outlined' | 'contained' | 'link';
  color?: Colors;
  size?: Sizes;
  shadow?: boolean;
  fullWidth?: boolean;
  minWidth?: number | 'auto';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  to?: To;
  state?: any;
};

const Button: React.FC<IProps> = (props) => {
  const {
    children,
    type = 'button',
    disabled,
    className,
    onClick,
    variant = 'outlined',
    color = 'primary',
    size = 'md',
    shadow = false,
    minWidth,
    fullWidth = false,
    startIcon,
    endIcon,
    to,
    state,
  } = props;
  const classList = classNames(
    className,
    classes[variant],
    classes[size],
    classes[color],
    {
      [classes.shadow]: shadow,
      [classes.btn]: variant !== 'link',
      [classes.link]: variant === 'link',
      [classes.fullWidth]: fullWidth,
      [classes.disable]: disabled,
    }
  );

  const btnContent = (
    <>
      {startIcon && <div className={classes.startIcon}>{startIcon}</div>}
      {children}
      {endIcon && <div className={classes.endIcon}>{endIcon}</div>}
    </>
  );

  if (to) {
    return (
      <HashLink
        className={classList}
        style={{ minWidth }}
        to={to}
        state={state}
      >
        {btnContent}
      </HashLink>
    );
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      className={classList}
      onClick={onClick}
      style={{ minWidth }}
    >
      {btnContent}
    </button>
  );
};

export default Button;
