import React, { useEffect, useRef } from 'react';
import ScrollBar from '../../elements/ScrollBar';
import classes from './ChatForm.module.scss';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  onFocus: () => void;
  onEnter: () => void;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
}

const TextField: React.FC<IProps> = (props) => {
  const { value, onChange, disabled, onFocus, onEnter, inputRef } = props;
  const wrap = useRef<HTMLDivElement>();

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onEnter();
    }
  };

  useEffect(() => {
    if (wrap.current) {
      wrap.current.scrollTo(0, wrap.current.scrollHeight);
    }
  }, [value]);

  return (
    <div className={classes.textFieldWrap}>
      <ScrollBar
        scrollableNodeProps={{
          ref: wrap,
        }}
        maxHeight={96}
      >
        <div className={classes.content}>
          {value}
          {value && value[value.length - 1] === '\n' ? '.' : ''}
          <textarea
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Write a message..."
            disabled={disabled}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            ref={inputRef}
          />
        </div>
      </ScrollBar>
    </div>
  );
};

export default TextField;
