import React from 'react';
import { MESSAGES } from '../../../constants/routes';
import useDynamicContent from '../../../hooks/useDynamicContent';
import { ITopicFull } from '../../../types/topic';
import { IUser } from '../../../types/user';
import InnerPageCard from '../../elements/InnerPageCard';
import MemberCard from '../../modules/MemberCard';
import TopicDetails from '../../modules/TopicDetails';
import classes from './Topic.module.scss';

interface IProps {
  topic: ITopicFull;
  user: IUser | null;
}

const TopicTemplate: React.FC<IProps> = (props) => {
  const { topic, user: currentUser } = props;
  const { interests } = useDynamicContent(['interests']);

  return (
    <section>
      <InnerPageCard>
        <TopicDetails
          name={topic.name}
          description={topic.description}
          status={topic.status}
          tags={topic.topic_tags.map((tag) => tag.name).join(', ')}
        />
        <div className={classes.members}>
          {topic.users.map((user) => (
            <MemberCard
              key={user.id}
              id={user.id}
              name={`${user.first_name} ${user.last_name}`}
              avatar={user.photo_url}
              position={
                user.user_last_experience &&
                `${user.user_last_experience.title} at ${user.user_last_experience.industry}`
              }
              interests={interests.list
                .filter((interest) => user.interests.includes(interest.id))
                .map((interest) => interest.name)
                .join(', ')}
              additionalButton={
                currentUser && currentUser.id === user.id
                  ? undefined
                  : {
                      text: 'SEND MESSAGE',
                      to: `${MESSAGES.fullPath}/${user.id}`,
                    }
              }
              statistic={
                user.user_sum_rating ||
                (currentUser && currentUser.id === user.id)
                  ? {
                      rating: user.user_sum_rating,
                      communications: user.sparring_users_count || 0,
                      ko: user.sparring_knock_outs_percentage || 0,
                      suggestUpdate:
                        currentUser &&
                        currentUser.id === user.id &&
                        currentUser.subscription_user
                          ? currentUser.subscription_user.subscription
                          : undefined,
                    }
                  : undefined
              }
              isCurrentUser={Boolean(currentUser && currentUser.id === user.id)}
            />
          ))}
        </div>
      </InnerPageCard>
    </section>
  );
};

export default TopicTemplate;
