import { createReducer } from '@reduxjs/toolkit';
import { IExperience } from '../../types/user';
import { clearAuthData } from '../actions/authentication';
import {
  addExperience,
  deleteExperience,
  editExperience,
  getExperience,
} from '../actions/user-experience';

interface State {
  list: IExperience[];
  errors: Partial<Record<keyof IExperience, string>>;
  loading: boolean;
  loaded: boolean;
}

const initialState: State = {
  list: [],
  errors: {},
  loaded: false,
  loading: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(addExperience.pending, (state) => {
    state.loading = true;
    state.errors = {};
  });
  builder.addCase(addExperience.fulfilled, (state, action) => {
    state.loading = false;
    state.list = [...state.list, action.payload].sort(
      (a, b) => a.end_date - b.end_date
    );
  });
  builder.addCase(addExperience.rejected, (state, action) => {
    state.loading = false;
    if (action.payload) {
      state.errors = action.payload;
    }
  });

  builder.addCase(editExperience.pending, (state) => {
    state.loading = true;
    state.errors = {};
  });
  builder.addCase(editExperience.fulfilled, (state, action) => {
    const index = state.list.findIndex((item) => item.id === action.payload.id);
    state.loading = false;
    if (index > -1) {
      state.list[index] = action.payload;
    }
  });
  builder.addCase(editExperience.rejected, (state, action) => {
    state.loading = false;
    if (action.payload) {
      state.errors = action.payload;
    }
  });

  builder.addCase(getExperience.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getExperience.fulfilled, (state, action) => {
    state.loading = false;
    state.loaded = true;
    state.list = action.payload;
  });
  builder.addCase(getExperience.rejected, (state) => {
    state.loading = false;
    state.loaded = true;
  });

  builder.addCase(deleteExperience.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(deleteExperience.fulfilled, (state, action) => {
    state.loading = false;
    state.list = state.list.filter((i) => i.id !== action.payload.id);
  });
  builder.addCase(clearAuthData, () => initialState);
});

interface Store {
  userExperience: State;
}

export const selectUserExperience = (state: Store) => state.userExperience;

export default reducer;
