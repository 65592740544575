import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleError422 } from '../../helpers';
import { formatTimeToMs, formatTimeToS } from '../../helpers/time';
import {
  addExperience as addExperienceByApi,
  requestExperience,
  removeExperience,
  updateExperience,
} from '../../repositories/user-experience';
import { IError422 } from '../../types/errors';
import { IExperience } from '../../types/user';

export const getExperience = createAsyncThunk('getExperience', async () => {
  const response = await requestExperience();
  return response.data.map((i) => formatTimeToMs(i));
});

export const deleteExperience = createAsyncThunk(
  'deleteExperience',
  async (id: number) => {
    const response = await removeExperience(id);
    return formatTimeToMs(response.data);
  }
);

export const addExperience = createAsyncThunk<
  IExperience,
  Omit<IExperience, 'id'>,
  { rejectValue: Partial<Record<keyof IExperience, string>> }
>('addExperience', async (payload, { rejectWithValue }) => {
  try {
    const response = await addExperienceByApi(formatTimeToS(payload));
    return formatTimeToMs(response.data);
  } catch (error) {
    const err = error as IError422;
    if (err.errors) {
      return rejectWithValue(handleError422(err));
    }
    return rejectWithValue({});
  }
});

export const editExperience = createAsyncThunk<
  IExperience,
  IExperience,
  { rejectValue: Partial<Record<keyof IExperience, string>> }
>('editExperience', async (payload, { rejectWithValue }) => {
  try {
    const response = await updateExperience(payload.id, formatTimeToS(payload));
    return formatTimeToMs(response.data);
  } catch (error) {
    const err = error as IError422;
    if (err.errors) {
      return rejectWithValue(handleError422(err));
    }
    return rejectWithValue({});
  }
});
