import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ERROR_FIELD_REQUIRED } from '../../../constants/error-messages';
import {
  dynamicContentToOptions,
  getMultiOptionFromDynamicContent,
  getOptionFromDynamicContent,
} from '../../../helpers/dynamic-content';
import { useDispatch, useSelector } from '../../../libs/redux';
import useDynamicContent from '../../../hooks/useDynamicContent';
import {
  getSettings,
  editSettings,
} from '../../../store/actions/user-settings';
import { selectUserSettings } from '../../../store/reducers/user-settings';
import { ISettings } from '../../../types/user';
import Button from '../../elements/Button';
import FormGroup from '../../elements/FormGroup';
import Loader from '../../elements/Loader';
import Select from '../../elements/Select';

const SearchSettingsForm: React.FC = () => {
  const { occupations, scaleSearch } = useDynamicContent([
    'scaleSearch',
    'occupations',
  ]);
  const { list, loading, loaded } = useSelector(selectUserSettings);
  const { register, handleSubmit, control, reset } = useForm<ISettings>({
    defaultValues: list,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded && !loading) {
      dispatch(getSettings());
    }
  }, []);

  useEffect(() => {
    reset(list);
  }, [list]);

  const fields = {
    occupations: register('user_available_occupations', {
      required: ERROR_FIELD_REQUIRED,
    }),
    scaleSearch: register('scale_search', {
      required: ERROR_FIELD_REQUIRED,
    }),
  };

  return (
    <form onSubmit={handleSubmit((d) => dispatch(editSettings(d)))}>
      <Loader position="absolute" open={loading} />
      <FormGroup>
        <Controller
          control={control}
          name={fields.scaleSearch.name}
          render={(field) => (
            <Select
              fullWidth
              label="Appear in search"
              options={dynamicContentToOptions(scaleSearch.list)}
              value={
                field.field.value
                  ? getOptionFromDynamicContent(
                      scaleSearch.list,
                      field.field.value
                    )
                  : null
              }
              onChange={(v) => field.field.onChange(v && v.value)}
              error={Boolean(field.fieldState.error)}
              helperText={field.fieldState.error?.message}
            />
          )}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          control={control}
          name={fields.occupations.name}
          render={(field) => (
            <Select
              fullWidth
              isMulti
              label="Available for"
              options={dynamicContentToOptions(occupations.list)}
              value={
                field.field.value
                  ? getMultiOptionFromDynamicContent(
                      occupations.list,
                      field.field.value
                    )
                  : null
              }
              onChange={(v) => field.field.onChange(v.map((i) => i.value))}
              error={Boolean(field.fieldState.error)}
              helperText={field.fieldState.error?.message}
            />
          )}
        />
      </FormGroup>

      <Button type="submit">SAVE</Button>
    </form>
  );
};

export default SearchSettingsForm;
