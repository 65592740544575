import React from 'react';
import PrivateRoute from '../../components/elements/PrivateRoute';
import SparringsTemplate from '../../components/templates/Sparrings';
import { SPARRING } from '../../constants/routes';
import useLayoutTitle from '../../hooks/useLayoutTitle';

const SparringPage: React.FC = () => {
  useLayoutTitle('Sparring Requests');

  return (
    <PrivateRoute access={SPARRING.access}>
      <SparringsTemplate />
    </PrivateRoute>
  );
};

export default SparringPage;
