import React from 'react';
import { useSelector } from '../../../libs/redux';
import { selectNotifications } from '../../../store/reducers/notifications';
import Button from '../../elements/Button';
import classes from './NewPWAVersion.module.scss';

const NewPWAVersion = () => {
  const { newVersionIsAvailable } = useSelector(selectNotifications);
  if (!newVersionIsAvailable) {
    return null;
  }
  document.body.style.overflow = 'hidden';
  return (
    <div className={classes.wrap}>
      <div className={classes.inner}>
        <h3>New version is available!</h3>
        <p>Refresh the page to use the latest version</p>
        <Button onClick={() => window.location.reload()}>Reload</Button>
      </div>
    </div>
  );
};

export default NewPWAVersion;
