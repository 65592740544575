import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IUser, IUserUpdatableFields } from '../../../types/user';
import { Card } from '../../elements/Card';
import classes from './EditProfile.module.scss';
import { Col, Row } from '../../elements/Grid';
import FormGroup from '../../elements/FormGroup';
import TextField from '../../elements/TextField';
import {
  ERROR_FIELD_ONLY_LETTERS,
  ERROR_FIELD_REQUIRED,
} from '../../../constants/error-messages';
import Select from '../../elements/Select';
import {
  dynamicContentToOptions,
  getMultiOptionFromDynamicContent,
  getOptionFromDynamicContent,
} from '../../../helpers/dynamic-content';
import useDynamicContent from '../../../hooks/useDynamicContent';
import Button from '../../elements/Button';
import { PROFILE } from '../../../constants/routes';
import AvatarUpdate from '../../modules/AvatarUpdate';
import Label from '../../elements/Label';
import Loader from '../../elements/Loader';
import { ONLY_LETTERS } from '../../../constants/regexp';

interface IProps {
  user: IUser;
  onUserEdit: (user: IUserUpdatableFields) => void;
  updating?: boolean;
  onPhotoUpdate: (photo: File) => void;
}

const EditProfileTemplate: React.FC<IProps> = (props) => {
  const { user, onUserEdit, updating = false, onPhotoUpdate } = props;
  const [country, setCountry] = useState(
    user.city
      ? { value: user.city.country.id, label: user.city.country.name }
      : null
  );
  const [searchInputs, setSearchInputs] = useState({
    country: user.city ? user.city.country.name : '',
    city: user.city ? user.city.name : '',
  });
  const [photo, setPhoto] = useState<File | null>(null);

  const { occupations, interests, countries, cities, languages, interaction } =
    useDynamicContent([
      'occupations',
      'interests',
      { key: 'countries', search: searchInputs.country },
      'languages',
      'interaction',
      { key: 'cities', data: country?.value, search: searchInputs.city },
    ]);

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    getValues,
  } = useForm<IUserUpdatableFields>({
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      city_id: user.city_id,
      user_detail: user.user_detail,
      language_id: user.language_id,
      interaction_types: user.interaction_types
        ? user.interaction_types.map((i) => i.id)
        : [],
      looking_occupations: user.looking_occupations
        ? user.looking_occupations.map((i) => i.id)
        : [],
      interests: user.interests ? user.interests.map((i) => i.id) : [],
    },
  });

  const fields = {
    first_name: register('first_name', {
      required: ERROR_FIELD_REQUIRED,
      pattern: {
        value: ONLY_LETTERS,
        message: ERROR_FIELD_ONLY_LETTERS,
      },
    }),
    last_name: register('last_name', {
      required: ERROR_FIELD_REQUIRED,
      pattern: {
        value: ONLY_LETTERS,
        message: ERROR_FIELD_ONLY_LETTERS,
      },
    }),
    looking_occupations: register('looking_occupations', {
      required: ERROR_FIELD_REQUIRED,
    }),
    interests: register('interests', {
      required: ERROR_FIELD_REQUIRED,
    }),
    about: register('user_detail.about', {
      required: ERROR_FIELD_REQUIRED,
    }),
    city: register('city_id', {
      required: ERROR_FIELD_REQUIRED,
    }),
    language: register('language_id', {
      required: ERROR_FIELD_REQUIRED,
    }),
    interaction: register('interaction_types', {
      required: ERROR_FIELD_REQUIRED,
    }),
  };

  useEffect(() => {
    const city = getValues('city_id');
    if (
      country &&
      user.city &&
      !(city === user.city.id && country.value === user.city.country.id)
    ) {
      setValue('city_id', '' as any);
    }
  }, [country]);

  const handleUpdatePhoto = () => {
    if (photo) {
      onPhotoUpdate(photo);
    }
  };

  return (
    <section>
      <Card noPadding>
        <div className={classes.cardBody}>
          <Loader open={updating} position="absolute" />
          <form onSubmit={handleSubmit((d) => onUserEdit(d))}>
            <div className={classes.wrap}>
              <div className={classes.avatar}>
                <AvatarUpdate
                  avatar={photo || user.photo_url}
                  onChang={setPhoto}
                  maxSize={1}
                />
                <Button
                  fullWidth
                  variant="contained"
                  disabled={!photo}
                  onClick={handleUpdatePhoto}
                >
                  UPDATE
                </Button>
              </div>
              <div className={classes.info}>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <TextField
                        fullWidth
                        label="First name"
                        name={fields.first_name.name}
                        inputRef={fields.first_name.ref}
                        onChange={fields.first_name.onChange}
                        error={Boolean(errors.first_name)}
                        helperText={errors.first_name?.message}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <TextField
                        fullWidth
                        label="Last name"
                        name={fields.last_name.name}
                        inputRef={fields.last_name.ref}
                        onChange={fields.last_name.onChange}
                        error={Boolean(errors.last_name)}
                        helperText={errors.last_name?.message}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Label error={Boolean(errors.city_id)}>Your location</Label>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Select
                        fullWidth
                        placeholder="Country"
                        isLoading={countries.loading}
                        value={country}
                        onInputChange={(v) =>
                          setSearchInputs({ ...searchInputs, country: v })
                        }
                        onChange={setCountry}
                        options={dynamicContentToOptions(countries.list)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Controller
                        control={control}
                        name={fields.city.name}
                        render={(field) => (
                          <Select
                            fullWidth
                            placeholder="City"
                            isLoading={cities.loading}
                            onInputChange={(v) =>
                              setSearchInputs({ ...searchInputs, city: v })
                            }
                            value={
                              field.field.value
                                ? getOptionFromDynamicContent(
                                    cities.list,
                                    field.field.value
                                  )
                                : null
                            }
                            onChange={(v) =>
                              field.field.onChange(v ? v.value : null)
                            }
                            isDisabled={!country}
                            options={dynamicContentToOptions(cities.list)}
                            error={Boolean(field.fieldState.error)}
                            helperText={field.fieldState.error?.message}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Controller
                    control={control}
                    name={fields.language.name}
                    render={(field) => (
                      <Select
                        fullWidth
                        label="Primary language for communication"
                        placeholder="Language"
                        value={
                          field.field.value
                            ? getOptionFromDynamicContent(
                                languages.list,
                                field.field.value
                              )
                            : null
                        }
                        onChange={(v) =>
                          field.field.onChange(v ? v.value : null)
                        }
                        options={dynamicContentToOptions(languages.list)}
                        error={Boolean(field.fieldState.error)}
                        helperText={field.fieldState.error?.message}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <Controller
                    control={control}
                    name={fields.interaction.name}
                    rules={{
                      required: ERROR_FIELD_REQUIRED,
                    }}
                    render={(field) => (
                      <Select
                        fullWidth
                        isMulti
                        label="Interaction Types"
                        options={dynamicContentToOptions(interaction.list)}
                        value={getMultiOptionFromDynamicContent(
                          interaction.list,
                          field.field.value
                        )}
                        onChange={(v) =>
                          field.field.onChange(v.map((i) => i.value))
                        }
                        error={Boolean(field.fieldState.error)}
                        helperText={field.fieldState.error?.message}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <Controller
                    control={control}
                    name="looking_occupations"
                    render={(field) => (
                      <Select
                        fullWidth
                        isMulti
                        label="Who are you looking for?"
                        options={dynamicContentToOptions(occupations.list)}
                        value={getMultiOptionFromDynamicContent(
                          occupations.list,
                          field.field.value
                        )}
                        onChange={(v) =>
                          field.field.onChange(v.map((i) => i.value))
                        }
                        error={Boolean(field.fieldState.error)}
                        helperText={field.fieldState.error?.message}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <Controller
                    control={control}
                    name="interests"
                    render={(field) => (
                      <Select
                        fullWidth
                        isMulti
                        label="Specify your interests"
                        placeholder="cybersecurity, fintech..."
                        options={dynamicContentToOptions(interests.list)}
                        value={getMultiOptionFromDynamicContent(
                          interests.list,
                          field.field.value
                        )}
                        onChange={(v) =>
                          field.field.onChange(v.map((i) => i.value))
                        }
                        error={Boolean(field.fieldState.error)}
                        helperText={field.fieldState.error?.message}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Tell about yourself"
                    placeholder="Type here"
                    name={fields.about.name}
                    textareaRef={fields.about.ref}
                    onChange={fields.about.onChange}
                    error={Boolean(errors.user_detail)}
                    helperText={errors.user_detail?.message}
                  />
                </FormGroup>
                <div className={classes.buttons}>
                  <Button color="default" to={PROFILE.fullPath}>
                    BACK
                  </Button>
                  <Button type="submit">SAVE</Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Card>
    </section>
  );
};

export default EditProfileTemplate;
