import React from 'react';
import { dynamicContentToOptions } from '../../../helpers/dynamic-content';
import useDynamicContent from '../../../hooks/useDynamicContent';
import { IExperience } from '../../../types/user';
import { Card } from '../../elements/Card';
import Loader from '../../elements/Loader';
import ExperienceForm from '../../modules/ExperienceForm';
import classes from './StoreExperience.module.scss';

interface IProps {
  experience?: Omit<IExperience, 'id'>;
  onStore: (experience: Omit<IExperience, 'id'>) => void;
  onCancel: () => void;
  errors: Partial<Record<keyof IExperience, string>>;
  loading: boolean;
}

const StoreExperienceTemplate: React.FC<IProps> = (props) => {
  const { experience, onStore, onCancel, errors, loading } = props;
  const { employmentTypes } = useDynamicContent(['employmentTypes']);

  return (
    <section>
      <Card>
        <div className={classes.cardBody}>
          <Loader open={loading} position="absolute" />
          <h2>{experience ? 'Edit' : 'Add new'} Experience</h2>
          <ExperienceForm
            errors={errors}
            values={experience}
            onSubmit={onStore}
            onCancel={onCancel}
            employmentTypes={dynamicContentToOptions(employmentTypes.list)}
          />
        </div>
      </Card>
    </section>
  );
};

export default StoreExperienceTemplate;
