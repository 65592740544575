import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  ERROR_FIELD_DATE_GRATER_THEN_TODAY,
  ERROR_FIELD_END_DATE,
  ERROR_FIELD_REQUIRED,
  ERROR_FIELD_START_DATE,
} from '../../../constants/error-messages';
import { IExperience } from '../../../types/user';
import Button from '../../elements/Button';
import DateSelect from '../../elements/DateSelect';
import FormGroup from '../../elements/FormGroup';
import { Col, Row } from '../../elements/Grid';
import Select from '../../elements/Select';
import TextField from '../../elements/TextField';
import classes from './ExperienceForm.module.scss';

interface IExperienceLocal extends Omit<IExperience, 'id'> {}

interface IProps {
  onSubmit: (data: IExperienceLocal) => void;
  errors: Partial<Record<keyof IExperience, string>>;
  onCancel: () => void;
  values?: IExperienceLocal;
  employmentTypes?: { value: number; label: string }[];
}

const ExperienceForm: React.FC<IProps> = (props) => {
  const {
    onSubmit,
    errors: propErrors,
    onCancel,
    values,
    employmentTypes,
  } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm<IExperienceLocal>({
    defaultValues: values || { start_date: Date.now(), end_date: Date.now() },
  });

  const fields = {
    title: register('title', {
      required: ERROR_FIELD_REQUIRED,
    }),
    company_name: register('company_name', {
      required: ERROR_FIELD_REQUIRED,
    }),
    location: register('location', {
      required: ERROR_FIELD_REQUIRED,
    }),
    industry: register('industry', {
      required: ERROR_FIELD_REQUIRED,
    }),
    description: register('description', {
      required: ERROR_FIELD_REQUIRED,
    }),
    employment_type_id: register('employment_type_id', {
      required: ERROR_FIELD_REQUIRED,
    }),
    start_date: register('start_date', {
      required: ERROR_FIELD_REQUIRED,
      validate: (value) => {
        if (value > getValues('end_date')) {
          return ERROR_FIELD_START_DATE;
        }
        if (value > Date.now()) {
          return ERROR_FIELD_DATE_GRATER_THEN_TODAY;
        }
        return true;
      },
    }),
    end_date: register('end_date', {
      required: ERROR_FIELD_REQUIRED,
      validate: (value) => {
        if (value < getValues('start_date')) {
          return ERROR_FIELD_END_DATE;
        }
        if (value > Date.now()) {
          return ERROR_FIELD_DATE_GRATER_THEN_TODAY;
        }
        return true;
      },
    }),
    headline: register('headline', {
      required: ERROR_FIELD_REQUIRED,
    }),
  };

  const getEmploymentTypeById = (id: number) =>
    employmentTypes && employmentTypes.find((i) => i.value === id);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md={6}>
          <FormGroup>
            <TextField
              fullWidth
              label="Title"
              placeholder="CEO"
              name={fields.title.name}
              inputRef={fields.title.ref}
              onChange={fields.title.onChange}
              error={Boolean(errors.title || propErrors.title)}
              helperText={errors.title?.message || propErrors.title}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Controller
              control={control}
              name="employment_type_id"
              render={(field) => (
                <Select
                  fullWidth
                  label="Employment Type"
                  options={employmentTypes}
                  value={getEmploymentTypeById(field.field.value)}
                  onChange={(v) => field.field.onChange(v && v.value)}
                  error={Boolean(
                    field.fieldState.error || propErrors.employment_type_id
                  )}
                  helperText={
                    field.fieldState.error?.message ||
                    propErrors.employment_type_id
                  }
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <TextField
              fullWidth
              label="Company Name"
              placeholder="WinStar Development"
              name={fields.company_name.name}
              inputRef={fields.company_name.ref}
              onChange={fields.company_name.onChange}
              error={Boolean(errors.company_name || propErrors.company_name)}
              helperText={
                errors.company_name?.message || propErrors.company_name
              }
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <TextField
              fullWidth
              label="Location"
              placeholder="Ex. London, UK"
              name={fields.location.name}
              inputRef={fields.location.ref}
              onChange={fields.location.onChange}
              error={Boolean(errors.location || propErrors.location)}
              helperText={errors.location?.message || propErrors.location}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Controller
              control={control}
              name="start_date"
              render={(field) => (
                <DateSelect
                  label="Start Date"
                  value={field.field.value || Date.now()}
                  onChange={(d) => field.field.onChange(d || Date.now())}
                  error={Boolean(
                    field.fieldState.error || propErrors.start_date
                  )}
                  helperTex={
                    field.fieldState.error?.message || propErrors.start_date
                  }
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Controller
              control={control}
              name="end_date"
              render={(field) => (
                <DateSelect
                  label="End Date"
                  value={field.field.value || Date.now()}
                  onChange={(d) => field.field.onChange(d || Date.now())}
                  error={Boolean(
                    field.fieldState.error || propErrors.start_date
                  )}
                  helperTex={
                    field.fieldState.error?.message || propErrors.start_date
                  }
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <TextField
          fullWidth
          label="Industry"
          placeholder="Ex. Information Technologies"
          name={fields.industry.name}
          inputRef={fields.industry.ref}
          onChange={fields.industry.onChange}
          error={Boolean(errors.industry || propErrors.industry)}
          helperText={errors.industry?.message || propErrors.industry}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          label="Headline"
          placeholder="Ex. Information Technologies"
          name={fields.headline.name}
          inputRef={fields.headline.ref}
          onChange={fields.headline.onChange}
          error={Boolean(errors.headline || propErrors.headline)}
          helperText={errors.headline?.message || propErrors.headline}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          multiline
          label="Description"
          name={fields.description.name}
          textareaRef={fields.description.ref}
          onChange={fields.description.onChange}
          error={Boolean(errors.description || propErrors.description)}
          helperText={errors.description?.message || propErrors.description}
        />
      </FormGroup>

      <div className={classes.btnWrap}>
        <Button color="default" onClick={onCancel}>
          CANCEL
        </Button>
        <Button type="submit">SAVE</Button>
      </div>
    </form>
  );
};

export default ExperienceForm;
