import qs from 'qs';
import axios, { Response, ResponseWithPagination } from '../libs/axios';
import { IPaginationParams } from '../types/general';
import { IBaseTopic, ITopic, ITopicFull } from '../types/topic';

export const requestTopics = (
  params?: IPaginationParams & {
    my_topics?: boolean;
    without_paginate?: boolean;
    include?: string;
    search?: string;
    fields?: { [k: string]: any };
    sort?: string;
    filter?: { [k: string]: any };
  }
) =>
  axios<ResponseWithPagination<ITopic[]>>({
    url: `/topics?${qs.stringify(params, {
      encode: false,
      arrayFormat: 'comma',
    })}`,
    method: 'GET',
  });
export const addTopic = (
  data: Omit<IBaseTopic, 'topic_tags'> & {
    topic_tags: number[];
  }
) =>
  axios<Response<ITopic>>({
    url: '/topics',
    method: 'POST',
    data,
  });

export const updateTopic = (
  id: number,
  data: Omit<IBaseTopic, 'topic_tags'> & {
    topic_tags: number[];
  }
) =>
  axios<Response<ITopicFull>>({
    url: `/topics/${id}`,
    method: 'PUT',
    data,
  });

export const getTopicById = (id: number) =>
  axios<Response<ITopicFull>>({
    url: `/topics/${id}`,
    method: 'GET',
  });

export const deleteTopic = (id: number) =>
  axios<Response<ITopic>>({
    url: `/topics/${id}`,
    method: 'DELETE',
  });
