import { createReducer } from '@reduxjs/toolkit';
import { ITopic, ITopicFull } from '../../types/topic';
import { clearAuthData } from '../actions/authentication';
import { editTopic, getTopic, setTopic } from '../actions/topics';

interface State {
  topic: ITopicFull | null;
  errors: Partial<Record<keyof ITopic, string>>;
  loading: boolean;
  updating: boolean;
}

const initialState: State = {
  topic: null,
  errors: {},
  loading: false,
  updating: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(getTopic.pending, (state) => {
    state.topic = null;
    state.loading = true;
    state.errors = {};
  });
  builder.addCase(getTopic.fulfilled, (state, action) => {
    state.topic = action.payload;
    state.loading = false;
  });
  builder.addCase(getTopic.rejected, (state, action) => {
    state.loading = false;
    if (action.payload) {
      state.errors = action.payload;
    }
  });

  builder.addCase(editTopic.pending, (state) => {
    state.updating = true;
  });
  builder.addCase(editTopic.fulfilled, (state, action) => {
    state.topic = { ...state.topic, ...action.payload };
    state.updating = false;
  });
  builder.addCase(editTopic.rejected, (state, action) => {
    state.updating = false;
    if (action.payload) {
      state.errors = action.payload;
    }
  });
  builder.addCase(setTopic, (state, action) => {
    state.topic = action.payload;
  });
  builder.addCase(clearAuthData, () => initialState);
});

interface Store {
  topic: State;
}

export const selectTopic = (state: Store) => state.topic;

export default reducer;
