import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { handleError422 } from '../../helpers';
import { requestUser, updateData, updatePhoto } from '../../repositories/user';
import { IError422 } from '../../types/errors';
import { IUser, IUserStatus, IUserUpdatableFields } from '../../types/user';
import { addFastNotification } from './notifications';

export const setUser = createAction<IUser | null>('setCurrentUser');
export const setUserLoading = createAction<boolean>('setUserLoading');
export const setUserErrors =
  createAction<Partial<Record<keyof IUserUpdatableFields, string>>>(
    'setUserErrors'
  );

export const getUser = createAsyncThunk(
  'getUser',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setUserLoading(true));
    try {
      const response = await requestUser();
      dispatch(setUser(response.data));
    } catch (error) {
      dispatch(setUser(null));
      rejectWithValue(error);
    } finally {
      dispatch(setUserLoading(false));
    }
  }
);

export const setUserPhoto = createAsyncThunk<
  string,
  File,
  { rejectValue: Partial<Record<keyof IUser, string>> }
>('setUserPhoto', async (photo: File, { rejectWithValue }) => {
  try {
    const data = new FormData();
    data.set('photo', photo);
    const response = await updatePhoto(data);
    return response.data.photo_url;
  } catch (error) {
    const err = error as IError422;
    if (err.errors) {
      return rejectWithValue(handleError422(err));
    }
    return rejectWithValue({});
  }
});

export const updateUserData = createAsyncThunk<
  IUser,
  Partial<IUserUpdatableFields>,
  { rejectValue: Partial<Record<keyof IUserUpdatableFields, string>> }
>('updateUserData', async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await updateData(data);
    if (response.data.status === IUserStatus.active) {
      dispatch(
        addFastNotification({
          color: 'success',
          content: 'Data successfully updated',
        })
      );
    }
    return response.data;
  } catch (error) {
    const err = error as IError422;
    if (err.errors) {
      return rejectWithValue(handleError422(err));
    }
    return rejectWithValue({});
  }
});
