import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  ERROR_FIELD_DATE_GRATER_THEN_TODAY,
  ERROR_FIELD_END_DATE,
  ERROR_FIELD_REQUIRED,
  ERROR_FIELD_START_DATE,
} from '../../../constants/error-messages';
import { IEducation } from '../../../types/user';
import Button from '../../elements/Button';
import DateSelect from '../../elements/DateSelect';
import FormGroup from '../../elements/FormGroup';
import { Col, Row } from '../../elements/Grid';
import TextField from '../../elements/TextField';
import classes from './EducationForm.module.scss';

interface IEducationLocal extends Omit<IEducation, 'id'> {}

interface IProps {
  onSubmit: (data: IEducationLocal) => void;
  errors: Partial<Record<keyof IEducation, string>>;
  onCancel: () => void;
  values?: IEducationLocal;
}

const EducationForm: React.FC<IProps> = (props) => {
  const { onSubmit, errors: propErrors, onCancel, values } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm<IEducationLocal>({
    defaultValues: values || { start_date: Date.now(), end_date: Date.now() },
  });
  const fields = {
    school: register('school', {
      required: ERROR_FIELD_REQUIRED,
    }),
    degree: register('degree', {
      required: ERROR_FIELD_REQUIRED,
    }),
    grade: register('grade', {
      required: ERROR_FIELD_REQUIRED,
    }),
    field_study: register('field_study', {
      required: ERROR_FIELD_REQUIRED,
    }),
    start_date: register('start_date', {
      required: ERROR_FIELD_REQUIRED,
      validate: (value) => {
        if (value > getValues('end_date')) {
          return ERROR_FIELD_START_DATE;
        }
        if (value > Date.now()) {
          return ERROR_FIELD_DATE_GRATER_THEN_TODAY;
        }
        return true;
      },
    }),
    end_date: register('end_date', {
      required: ERROR_FIELD_REQUIRED,
      validate: (value) => {
        if (value < getValues('start_date')) {
          return ERROR_FIELD_END_DATE;
        }
        if (value > Date.now()) {
          return ERROR_FIELD_DATE_GRATER_THEN_TODAY;
        }
        return true;
      },
    }),
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <TextField
          fullWidth
          label="School"
          placeholder="Ex. London University"
          name={fields.school.name}
          inputRef={fields.school.ref}
          onChange={fields.school.onChange}
          error={Boolean(errors.school || propErrors.school)}
          helperText={errors.school?.message || propErrors.school}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          label="Degree"
          placeholder="Ex. Bachelor"
          name={fields.degree.name}
          inputRef={fields.degree.ref}
          onChange={fields.degree.onChange}
          error={Boolean(errors.degree || propErrors.degree)}
          helperText={errors.degree?.message || propErrors.degree}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          label="Field of Study"
          placeholder="Ex. Finances"
          name={fields.field_study.name}
          inputRef={fields.field_study.ref}
          onChange={fields.field_study.onChange}
          error={Boolean(errors.field_study || propErrors.field_study)}
          helperText={errors.field_study?.message || propErrors.field_study}
        />
      </FormGroup>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Controller
              control={control}
              name="start_date"
              render={(field) => (
                <DateSelect
                  label="Start Date"
                  value={field.field.value || Date.now()}
                  onChange={(d) => field.field.onChange(d || Date.now())}
                  error={Boolean(
                    field.fieldState.error || propErrors.start_date
                  )}
                  helperTex={
                    field.fieldState.error?.message || propErrors.start_date
                  }
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Controller
              control={control}
              name="end_date"
              render={(field) => (
                <DateSelect
                  label="End Date"
                  value={field.field.value || Date.now()}
                  onChange={(d) => field.field.onChange(d || Date.now())}
                  error={Boolean(
                    field.fieldState.error || propErrors.start_date
                  )}
                  helperTex={
                    field.fieldState.error?.message || propErrors.start_date
                  }
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <TextField
          fullWidth
          label="Grade"
          placeholder="Ex. Finances"
          name={fields.grade.name}
          inputRef={fields.grade.ref}
          onChange={fields.grade.onChange}
          error={Boolean(errors.grade || propErrors.grade)}
          helperText={errors.grade?.message || propErrors.grade}
        />
      </FormGroup>

      <div className={classes.btnWrap}>
        <Button color="default" onClick={onCancel}>
          CANCEL
        </Button>
        <Button type="submit">SAVE</Button>
      </div>
    </form>
  );
};

export default EducationForm;
