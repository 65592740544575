import axios, { Response } from '../libs/axios';
import { IBaseContent, IFaqItem } from '../types/dynamic-content';

export const requestFaqs = () =>
  axios<Response<IFaqItem[]>>({
    url: '/faqs',
  });

export const requestInterests = () =>
  axios<Response<IBaseContent[]>>({
    url: '/interests',
    params: {
      all: 1,
    },
  });

export const requestCountries = (search?: string) =>
  axios<Response<IBaseContent[]>>({
    url: '/countries',
    params: {
      all: 1,
      search,
    },
  });

export const requestCities = (id?: number, search?: string) =>
  axios<Response<IBaseContent[]>>({
    url: '/cities',
    params: {
      all: 1,
      country_id: id,
      search,
    },
  });

export const requestLanguages = () =>
  axios<Response<IBaseContent[]>>({
    url: '/languages',
    params: {
      all: 1,
    },
  });

export const requestEmploymentTypes = () =>
  axios<Response<IBaseContent[]>>({
    url: '/employment-types',
    params: {
      all: 1,
    },
  });

export const requestOccupation = () =>
  axios<Response<IBaseContent[]>>({
    url: '/occupations',
    params: {
      all: 1,
    },
  });

export const requestInteraction = () =>
  axios<Response<IBaseContent[]>>({
    url: '/interaction-types',
    params: {
      all: 1,
    },
  });

export const requestScaleSearch = () =>
  axios<Response<{ key: number; name: string }[]>>({
    url: '/scale-search',
    params: {
      all: 1,
    },
  });

export const requestTopicStatuses = () =>
  axios<Response<{ key: number; name: string }[]>>({
    url: '/topic-statuses',
    params: {
      all: 1,
    },
  });

export const requestTopicTags = (search?: string) =>
  axios<Response<IBaseContent[]>>({
    url: '/topic-tags',
    params: {
      all: 1,
      search,
    },
  });

export const requestTopicsList = () =>
  axios<Response<IBaseContent[]>>({
    url: `/my-topics`,
    method: 'GET',
    params: {
      all: 1,
    },
  });
