import React from 'react';
import { IBaseTopic, ITopic } from '../../../types/topic';
import InnerPageCard from '../../elements/InnerPageCard';
import Loader from '../../elements/Loader';
import TopicForm from '../../modules/TopicForm';
import classes from './StoreTopic.module.scss';

interface IProps {
  topic?: IBaseTopic;
  onStore: (
    topic: Omit<IBaseTopic, 'topic_tags'> & {
      topic_tags: number[];
    }
  ) => void;
  onCancel: () => void;
  errors: Partial<Record<keyof ITopic, string>>;
  loading: boolean;
  sending: boolean;
}

const StoreTopicTemplate: React.FC<IProps> = (props) => {
  const { topic, onStore, onCancel, errors, loading, sending } = props;

  return (
    <section>
      <InnerPageCard>
        <div className={classes.cardBody}>
          <Loader open={loading} position="absolute" />
          <TopicForm
            errors={errors}
            values={
              topic && {
                ...topic,
                topic_tags: topic ? topic.topic_tags.map((tag) => tag.id) : [],
              }
            }
            onSubmit={onStore}
            onCancel={onCancel}
            sending={sending}
          />
        </div>
      </InnerPageCard>
    </section>
  );
};

export default StoreTopicTemplate;
