import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '../../../libs/redux';
import useDynamicContent from '../../../hooks/useDynamicContent';
import usePagination from '../../../hooks/usePagination';
import useWindowSize from '../../../hooks/useWindowSize';
import { getSparrings, handleSparring } from '../../../store/actions/sparrings';
import { selectSparrings } from '../../../store/reducers/sparrings';
import { breakpoints } from '../../../styles/breakpoints';
import { SparringStatus } from '../../../types/sparring';
import Avatar from '../../elements/Avatar';
import Button from '../../elements/Button';
import { Card } from '../../elements/Card';
import { Col, Row } from '../../elements/Grid';
import Loader from '../../elements/Loader';
import Pagination from '../../elements/Pagination';
import classes from './Sparrings.module.scss';
import Rating from '../../modules/Rating';

const SparringsTemplate: React.FC = () => {
  const [elementsPerPage, setElementPerPage] = useState(3);
  const { list, pagination, loading, sending } = useSelector(selectSparrings);
  const dispatch = useDispatch();
  const { occupations } = useDynamicContent(['occupations']);
  const { width } = useWindowSize();

  const isMobile = width && width < breakpoints.md;

  const { currentPage, setCurrentPage, visibleList, totalPages } =
    usePagination({
      elementsPerPage,
      list,
      serverPagination: pagination,
      onRequest(page) {
        dispatch(getSparrings({ page }));
      },
    });

  const getOccupation = (id: number) => {
    const result = occupations.list.find((occupation) => occupation.id === id);
    if (result) {
      return result.name;
    }
    return '-';
  };

  useEffect(() => {
    if (!loading) {
      dispatch(getSparrings({ page: 1 }));
    }
  }, []);
  useEffect(() => {
    if (width && width >= breakpoints.xl) {
      setElementPerPage(3);
    }
    if (width && width < breakpoints.xl) {
      setElementPerPage(2);
    }
    if (isMobile) {
      setElementPerPage(3);
    }
  }, [width]);

  return (
    <section>
      {!list.length && (
        <Card>
          <div className={classes.cardBody}>
            <h2>You have no new Sparring Requests.</h2>
          </div>
        </Card>
      )}

      <Loader open={loading || sending} position="absolute" />

      <Row className={classes.requests__list}>
        {visibleList.map((request) => (
          <Col xs={isMobile ? 12 : 12 / elementsPerPage} key={request.id}>
            <Card noPadding className={classes.item}>
              <div className={classes.cardBody}>
                <div className={classes.main__info}>
                  <div className={classes.avatar}>
                    <Avatar fullWidth src={request.sender.photo_url} />
                  </div>
                  <div>
                    <h3>
                      {request.sender.first_name} {request.sender.last_name}
                    </h3>
                    <div className={classes.profile__info}>
                      <p className={classes.title}>
                        {request.sender.user_last_experience &&
                          `${request.sender.user_last_experience.title} at ${request.sender.user_last_experience.industry}`}
                      </p>
                      <p className={classes.location}>
                        {request.sender.city?.country.name},{' '}
                        {request.sender.city?.name}
                      </p>
                      <div className={classes.rating}>
                        {request.sender.user_sum_rating && (
                          <Rating
                            rating={request.sender.user_sum_rating}
                            name={`${request.sender.first_name} ${request.sender.last_name}`}
                            communications={
                              request.sender.sparring_users_count || 0
                            }
                            ko={
                              request.sender.sparring_knock_outs_percentage || 0
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.profile__info__list}>
                  <div className={classes.infoItem}>
                    <b className={classes.label}>Request Purpose:</b>
                    Looking for {getOccupation(request.occupation_id)}
                  </div>
                  <div className={classes.infoItem}>
                    <b className={classes.label}>Related Topic:</b>{' '}
                    {request.topic.name}
                  </div>
                  <div className={classes.infoItem}>
                    <b className={classes.label}>Keywords:</b>
                    {request.keywords}
                  </div>
                  <div
                    className={classNames(classes.message, classes.infoItem)}
                  >
                    <b className={classes.label}>Request Message:</b>
                    <div>{request.message}</div>
                  </div>
                </div>
                <div className={classes.btn__group}>
                  <Button
                    color="secondary"
                    onClick={() =>
                      dispatch(
                        handleSparring({
                          id: request.id,
                          status: SparringStatus.rejected,
                        })
                      )
                    }
                  >
                    REJECT
                  </Button>
                  <Button
                    onClick={() =>
                      dispatch(
                        handleSparring({
                          id: request.id,
                          status: SparringStatus.accepted,
                        })
                      )
                    }
                  >
                    ACCEPT
                  </Button>
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      {list.length > 0 && totalPages > 1 && (
        <Card noPadding>
          <div className={classes.pagination}>
            {isMobile ? (
              <Button
                fullWidth
                onClick={() => setElementPerPage(elementsPerPage + 3)}
                className={classes.btn}
              >
                LOAD MORE RESULTS
              </Button>
            ) : (
              <Pagination
                total={totalPages}
                current={currentPage}
                pageRangeDisplayed={3}
                onChange={setCurrentPage}
              />
            )}
          </div>
        </Card>
      )}
    </section>
  );
};

export default SparringsTemplate;
