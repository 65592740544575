import moment from 'moment';

export const formatTimeToMs = <
  T extends { start_date?: number; end_date?: number }
>(
  data: T
): T => ({
  ...data,
  start_date: data.start_date && data.start_date * 1000,
  end_date: data.end_date && data.end_date * 1000,
});

export const formatTimeToS = <
  T extends { start_date?: number; end_date?: number }
>(
  data: T
): T => ({
  ...data,
  start_date: data.start_date && Math.floor(data.start_date / 1000),
  end_date: data.end_date && Math.floor(data.end_date / 1000),
});

export const getTimeRange = (time: { from: number; to: number }): string => {
  const from = moment(time.from);
  const to = moment(time.to);

  const daysDif = Math.abs(from.diff(to, 'days'));
  const yearsDif = Math.floor(Math.abs(from.diff(to, 'months')) / 12);
  const monthsDif = Math.abs(from.diff(to, 'months')) - yearsDif * 12;

  const getMultipleEnd = (number: number) => (number > 1 ? 's' : '');

  if (!yearsDif && !monthsDif) {
    return `${daysDif} day${getMultipleEnd(daysDif)}`;
  }

  return `${yearsDif > 0 ? `${yearsDif} yr${getMultipleEnd(yearsDif)}` : ''} ${
    monthsDif > 0 ? `${monthsDif} month${getMultipleEnd(monthsDif)}` : ``
  }`;
};
