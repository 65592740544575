import React from 'react';
import classes from './Error.module.scss';

type Codes = 404 | 500;

interface IProps {
  code?: Codes;
  title?: string;
  message?: string;
}

const ErrorTemplate: React.FC<IProps> = (props) => {
  const { code, title, message } = props;

  const getPageContent = (c?: Codes) => {
    switch (c) {
      case 404:
        return {
          title: title || 'PAGE NOT FOUND!',
          message:
            message || `Sorry, the page you're looking for doesn't exist`,
        };

      default:
        return { title, message };
    }
  };

  const content = getPageContent(code);

  return (
    <section className={classes.wrap}>
      <div className={classes.code}>{code}</div>
      <h1>{content.title}</h1>
      <p>{content.message}</p>
    </section>
  );
};

export default ErrorTemplate;
