import React from 'react';
import classNames from 'classnames';
import { Colors } from '../../../types/general';
import classes from './HelperText.module.scss';

interface IProps {
  children?: React.ReactNode;
  color?: Colors;
  className?: string;
}

const HelperText: React.FC<IProps> = (props) => {
  const { children, color = 'default', className } = props;
  return (
    <div className={classNames(classes.wrap, classes[color], className)}>
      {children}
    </div>
  );
};

export default HelperText;
