import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { HOME } from '../../../constants/routes';
import useWindowSize from '../../../hooks/useWindowSize';
import { breakpoints } from '../../../styles/breakpoints';
import Transition from '../../elements/Transition';
import classes from './HomePageMenu.module.scss';
import { ReactComponent as Menu } from '../../../assets/img/icon/menu.svg';

const items = [
  {
    to: { pathname: HOME.fullPath, hash: 'about' },
    name: 'ABOut us',
  },
  {
    to: { pathname: HOME.fullPath, hash: 'why' },
    name: 'WHY us',
  },
  {
    to: { pathname: HOME.fullPath, hash: 'faq' },
    name: 'FAQ',
  },
  {
    to: { pathname: HOME.fullPath, hash: 'social' },
    name: 'CONTACT',
  },
];

const HomePageMenu: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { width } = useWindowSize();

  return (
    <>
      <button
        type="button"
        className={classes.menu}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <Menu />
      </button>
      <div className={classes.nav__box_wrap}>
        <Transition in={(width && width > breakpoints.md) || isMenuOpen}>
          <div className={classes.nav__box}>
            {items.map((item) => (
              <HashLink
                key={item.name}
                smooth
                className={classes.nav__link}
                to={item.to}
                onClick={() => setIsMenuOpen(false)}
              >
                {item.name}
              </HashLink>
            ))}
          </div>
        </Transition>
      </div>
    </>
  );
};

export default HomePageMenu;
