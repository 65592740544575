import { createReducer } from '@reduxjs/toolkit';
import { IEducation } from '../../types/user';
import { clearAuthData } from '../actions/authentication';
import {
  addEducation,
  deleteEducation,
  editEducation,
  getEducation,
} from '../actions/user-education';

interface State {
  list: IEducation[];
  errors: Partial<Record<keyof IEducation, string>>;
  loading: boolean;
  loaded: boolean;
}

const initialState: State = {
  list: [],
  errors: {},
  loaded: false,
  loading: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(addEducation.pending, (state) => {
    state.loading = true;
    state.errors = {};
  });
  builder.addCase(addEducation.fulfilled, (state, action) => {
    state.loading = false;
    state.list = [...state.list, action.payload].sort(
      (a, b) => a.end_date - b.end_date
    );
  });
  builder.addCase(addEducation.rejected, (state, action) => {
    state.loading = false;
    if (action.payload) {
      state.errors = action.payload;
    }
  });

  builder.addCase(editEducation.pending, (state) => {
    state.loading = true;
    state.errors = {};
  });
  builder.addCase(editEducation.fulfilled, (state, action) => {
    const index = state.list.findIndex((item) => item.id === action.payload.id);
    state.loading = false;
    if (index > -1) {
      state.list[index] = action.payload;
    }
  });
  builder.addCase(editEducation.rejected, (state, action) => {
    state.loading = false;
    if (action.payload) {
      state.errors = action.payload;
    }
  });

  builder.addCase(getEducation.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getEducation.fulfilled, (state, action) => {
    state.loading = false;
    state.loaded = true;
    state.list = action.payload;
  });
  builder.addCase(getEducation.rejected, (state) => {
    state.loading = false;
    state.loaded = true;
  });

  builder.addCase(deleteEducation.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(deleteEducation.fulfilled, (state, action) => {
    state.loading = false;
    state.list = state.list.filter((i) => i.id !== action.payload.id);
  });

  builder.addCase(clearAuthData, () => initialState);
});

interface Store {
  userEducation: State;
}

export const selectUserEducation = (state: Store) => state.userEducation;

export default reducer;
