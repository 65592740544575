import { ITopic } from './topic';
import { IUser, IExperience } from './user';

export interface ISparring {
  id: number;
  message: string;
  keywords: string;
  status: number;
  topic_id: number;
  occupation_id: number;
  topic: ITopic;
  sender: IUser & { user_last_experience: IExperience };
}

export interface ISparringRequest
  extends Pick<ISparring, 'message' | 'keywords' | 'topic_id'> {
  occupation_id: number;
  recipient_id: number;
}

export enum SparringStatus {
  pending = 1,
  accepted,
  rejected,
}
