import React from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

const ScrollBar: React.FC<
  Omit<SimpleBar.Props, 'children'> & {
    children?: React.ReactNode;
    maxHeight?: number | string;
  }
> = (props) => {
  const { maxHeight, children, ...rest } = props;
  return (
    <SimpleBar
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      style={{ maxHeight: maxHeight || '100%', width: '100%' }}
    >
      <div>{children}</div>
    </SimpleBar>
  );
};
export default ScrollBar;
