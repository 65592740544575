import classNames from 'classnames';
import React from 'react';
import classes from './FormGroup.module.scss';

interface IProps {
  children?: React.ReactNode;
  className?: string;
}

const FormGroup: React.FC<IProps> = (props) => {
  const { children, className } = props;
  return (
    <div className={classNames(classes.formGroup, className)}>{children}</div>
  );
};

export default FormGroup;
