import { SESSION } from '../constants/local-storage';
import { ISession } from '../types/authentication';

export class Session {
  private static instance: Session;

  private isListened = false;

  private listener: (session: ISession | null) => void;

  private session: ISession | null;

  constructor() {
    this.session = null;
    this.listener = () => {};
  }

  public getSession(): ISession | null {
    return (
      this.session ||
      (JSON.parse(localStorage.getItem(SESSION) || 'null') as ISession | null)
    );
  }

  public setSession(session: ISession) {
    this.session = session;
    localStorage.setItem(SESSION, JSON.stringify(session));
    this.listener(session);
  }

  public removeSession() {
    this.session = null;
    localStorage.removeItem(SESSION);
    this.listener(null);
  }

  public setListener(listener: (session: ISession | null) => void) {
    if (!this.isListened) {
      this.listener = listener;
      this.isListened = true;
    } else {
      console.warn('You have already set listener');
    }
  }

  public static getInstance(): Session {
    if (!Session.instance) {
      Session.instance = new Session();
    }
    return Session.instance;
  }
}

export default Session;
