import React from 'react';
import PrivateRoute from '../../components/elements/PrivateRoute';
import RestorePasswordTemplate from '../../components/templates/RestorePassword';
import { Access } from '../../types/general';

const RestorePasswordPage: React.FC = () => (
  <PrivateRoute access={Access.notAuthenticated}>
    <RestorePasswordTemplate />
  </PrivateRoute>
);

export default RestorePasswordPage;
