import React, { createContext, useContext } from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';
import classes from './Grid.module.scss';
import { getBreakpoint } from '../../../styles/breakpoints';

interface BaseProps {
  children?: React.ReactNode;
  className?: string;
}

interface RowProps extends BaseProps {
  direction?: 'column-reverse' | 'column' | 'row-reverse' | 'row';
  alignItems?: 'center' | 'flex-start' | 'flex-end';
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-around'
    | 'space-between';
  wrap?: 'wrap' | 'nowrap';
  cols?: number;
}

const GridContext = createContext(12);

export const Row: React.FC<RowProps> = (props) => {
  const {
    children,
    direction,
    alignItems,
    justifyContent,
    className,
    cols = 12,
    wrap = 'wrap',
  } = props;
  const myStyle = css`
    justify-content: ${justifyContent};
    flex-direction: ${direction};
    align-items: ${alignItems};
    flex-wrap: ${wrap};
  `;
  return (
    <GridContext.Provider value={cols}>
      <div className={classNames(classes.row, myStyle, className)}>
        {children}
      </div>
    </GridContext.Provider>
  );
};

interface ColProps extends BaseProps {
  xl?: number;
  lg?: number;
  md?: number;
  sm?: number;
  xs?: number;
}

export const Col: React.FC<ColProps> = (props) => {
  const { children, xl, lg, md, sm, xs, className } = props;

  const columns = useContext(GridContext) || 12;
  const collStyle = css`
    ${xs &&
    `${getBreakpoint('xs', 'up')} {
      width: ${((xs / columns) * 100).toFixed(6)}%;
    }`}
    ${sm &&
    `${getBreakpoint('sm', 'up')} {
            width: ${((sm / columns) * 100).toFixed(6)}%;
    }`}
    ${md &&
    `${getBreakpoint('md', 'up')} {
          width: ${((md / columns) * 100).toFixed(6)}%;
    }`}
    ${lg &&
    `${getBreakpoint('lg', 'up')} {
          width: ${((lg / columns) * 100).toFixed(6)}%;
      }`}
      ${xl &&
    `${getBreakpoint('xl', 'up')} {
            width: ${((xl / columns) * 100).toFixed(6)}%;
        }`}
  `;

  return (
    <div className={classNames(classes.col, className, collStyle)}>
      {children}
    </div>
  );
};
