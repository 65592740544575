export enum ISubscription {
  novice = 1,
  amateur,
  pro,
  greatMind,
}

export interface ISubscriptionInfo {
  name: string;
  type: ISubscription;
}
