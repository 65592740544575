import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleError422 } from '../../helpers';
import {
  updateSettings,
  requestSettings,
} from '../../repositories/user-settings';
import { IError422 } from '../../types/errors';
import { ISettings } from '../../types/user';

export const getSettings = createAsyncThunk('getSettings', async () => {
  const response = await requestSettings();
  return response.data;
});

export const editSettings = createAsyncThunk<
  ISettings,
  ISettings,
  { rejectValue: Partial<Record<keyof ISettings, string>> }
>('editSettings', async (payload, { rejectWithValue }) => {
  try {
    const response = await updateSettings(payload);
    return response.data;
  } catch (error) {
    const err = error as IError422;
    if (err.errors) {
      return rejectWithValue(handleError422(err));
    }
    return rejectWithValue({});
  }
});
