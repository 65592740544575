import { useEffect } from 'react';

export interface User {
  id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
}

export interface FacebookAuthResponse {
  accessToken: string;
  expiresIn: number;
  signedRequest: number;
  userID: string;
}

export interface FacebookLoginProps {
  appId: string;
  onError?: (response: any) => void;
  onSuccess?: (response: {
    user: User;
    authState: FacebookAuthResponse;
  }) => void;
}

const getWindow = (): any => window as any;

const setFacebookAsyncInit = (appId: string): void => {
  getWindow().fbAsyncInit = () => {
    getWindow().FB.init({
      version: 'v14.0',
      appId,
      xfbml: false,
      cookie: false,
    });
  };
};

const loadSdkAsynchronously = (): void => {
  ((doc: Document, script: string, sdkId: string) => {
    const newScriptElement = doc.createElement(script) as HTMLScriptElement;

    newScriptElement.id = sdkId;
    newScriptElement.src = `https://connect.facebook.net/en_US/sdk.js`;
    doc.head.appendChild(newScriptElement);

    let fbRoot = doc.getElementById('fb-root');
    if (!fbRoot) {
      fbRoot = doc.createElement('div');
      fbRoot.id = 'fb-root';
      doc.body.appendChild(fbRoot);
    }
  })(document, 'script', 'facebook-jssdk');
};

export const useFacebookLogin = (props: FacebookLoginProps): (() => void) => {
  const { appId, onSuccess = () => {}, onError = () => {} } = props;

  const login = (): void => {
    getWindow().FB.login(
      (response: {
        status: 'connected' | 'not_authorized' | 'unknown';
        authResponse: FacebookAuthResponse;
      }) => {
        if (response.authResponse) {
          getWindow().FB.api(
            '/me',
            { fields: ['email', 'first_name', 'last_name'] },
            (user: User) => {
              onSuccess({ user, authState: response.authResponse });
            }
          );
        } else {
          onError(response);
        }
      },
      { scope: 'public_profile,email' }
    );
  };

  useEffect(() => {
    setFacebookAsyncInit(appId);
    loadSdkAsynchronously();
  }, [appId]);

  return login;
};
