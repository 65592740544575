import {
  Middleware,
  Action,
  AnyAction,
  ThunkDispatch,
  Dispatch,
} from '@reduxjs/toolkit';
import { io, Socket } from 'socket.io-client';
import { uid } from 'uid';
import {
  ERROR,
  EXCEPTION,
  FAIL,
  GET_CHAT_BY_USER_ID,
  GET_UNREAD_MESSAGES_COUNT,
  KO,
  NEW_MESSAGE,
  READ_MESSAGES,
  RESPONSE,
  ROUND_COMPLETE,
  SEND_MESSAGE,
} from '../../constants/web-socket-events';
import { IMessage, ChatSparing } from '../../types/chat';
import { WebSocketStatus } from '../../types/general';
import { clearAuthData } from '../actions/authentication';
import {
  getChatByUserId,
  getChatsList,
  getMessage,
  readMessages,
  sendMessage,
  setChatsStatus,
  setKo,
  setSparring,
  setUnreadMessages,
} from '../actions/chat';
import { addFastNotificationsGroup } from '../actions/notifications';
import { connect, setStatus } from '../actions/web-socket';
import { RootState } from '../reducers';

const server = process.env.REACT_APP_CHAT_SERVER_WS || '/';

let socket: Socket;

type AppDispatch = ThunkDispatch<RootState, undefined, AnyAction> &
  Dispatch<AnyAction>;

const webSocket: Middleware<{}, RootState, AppDispatch> = (store) => {
  const { dispatch, getState } = store;

  const init = (token: string) => {
    if (socket && socket.connected) {
      socket.close();
    }
    socket = io(server, {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
      autoConnect: true,
    });
    socket.on('connect', () => {
      dispatch(setStatus(WebSocketStatus.connected));
      dispatch(getChatsList({ page: 1 }));
      (window as any).socket = socket;
    });
    socket.on('disconnect', (e) => {
      console.log(e);

      if (e === 'io server disconnect') {
        dispatch(setStatus(WebSocketStatus.error));
      } else {
        dispatch(setStatus(WebSocketStatus.disconnected));
      }
    });
    socket.on('connect_error', (error) => {
      console.log(error);
    });
    socket.on(EXCEPTION, (response: { states: string; message: string }) => {
      dispatch(
        addFastNotificationsGroup([
          { id: uid(), content: response.message, color: 'warning' },
        ])
      );
    });
    socket.on(ERROR, (response: { error: string }) => {
      dispatch(
        addFastNotificationsGroup([
          { id: uid(), content: response.error, color: 'error' },
        ])
      );
    });
    socket.on(SEND_MESSAGE + RESPONSE, (response: IMessage) => {
      const { chats } = getState();
      const isLoadedChat = chats.list.find(
        (chat) => chat.id === response.conversationId
      );
      if (!isLoadedChat) {
        dispatch(getChatsList({ page: 1 }));
      }

      dispatch(getMessage(response));
    });

    socket.on(NEW_MESSAGE + FAIL, (response: { error: string }) => {
      dispatch(
        addFastNotificationsGroup([
          { content: response.error, color: 'error', id: uid() },
        ])
      );
    });

    socket.on(
      GET_UNREAD_MESSAGES_COUNT + RESPONSE,
      (response: { count: number }) => {
        dispatch(setUnreadMessages(response.count));
      }
    );

    socket.on(ROUND_COMPLETE, (data: { sparring: ChatSparing }) => {
      dispatch(setSparring(data.sparring));
    });

    socket.on(KO, (data: { userExternalId: number }) => {
      dispatch(setKo(data.userExternalId));
    });
  };

  return (next) => (action: Action) => {
    if (connect.match(action)) {
      const { payload } = action;
      dispatch(setStatus(WebSocketStatus.pending));
      init(payload);
    }

    if (clearAuthData.match(action)) {
      socket.disconnect();
    }

    if (getChatByUserId.match(action)) {
      dispatch(setChatsStatus({ loading: true }));
      socket.emit(GET_CHAT_BY_USER_ID, { userExternalId: action.payload.id });
    }

    if (sendMessage.match(action)) {
      socket.emit(SEND_MESSAGE, action.payload);
    }

    if (readMessages.match(action)) {
      socket.emit(READ_MESSAGES, { id: action.payload });
    }

    return next(action);
  };
};

export default webSocket;
