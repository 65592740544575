import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { IChatMedia, IMessage } from '../../../types/chat';
import Avatar from '../Avatar';
import classes from './ChatMessage.module.scss';
import File from './File';

interface IProps extends Pick<IMessage, 'createdAt' | 'text' | 'messageFiles'> {
  avatar: string | null;
  isMy: boolean;
  onMediaOpen: (data: IChatMedia) => void;
}

const ChatMessage: React.FC<IProps> = (props) => {
  const { createdAt, text, messageFiles, avatar, isMy, onMediaOpen } = props;

  const files = {
    media: messageFiles.filter((file) => file.mimeType.includes('image')),
    other: messageFiles.filter((file) => !file.mimeType.includes('image')),
  };

  return (
    <div className={classNames(classes.item, { [classes.my]: isMy })}>
      <div className={classes.avatar}>
        <Avatar fullWidth src={avatar} />
      </div>
      <div>
        <div className={classes.msgInner}>
          <div className={classes.text}>{text.trim()}</div>
          {files.media.length > 0 && (
            <div className={classes.mediaList}>
              {files.media.map((file) => (
                <div className={classes.mediaItem} key={file.id}>
                  <File onMediaOpen={onMediaOpen} file={file} />
                </div>
              ))}
            </div>
          )}

          {files.other.map((file) => (
            <File onMediaOpen={onMediaOpen} key={file.id} file={file} />
          ))}
        </div>
        <div className={classes.time}>{moment(createdAt).format('hh:mm')}</div>
      </div>
    </div>
  );
};

export default ChatMessage;
