import { IBaseContent } from '../types/dynamic-content';

interface IOption {
  value: number;
  label: string;
}

export const dynamicContentToOption = (data: IBaseContent): IOption => ({
  value: data.id,
  label: data.name,
});
export const optionToDynamicContent = (data: IOption): IBaseContent => ({
  id: data.value,
  name: data.label,
});

export const dynamicContentToOptions = (data: IBaseContent[]): IOption[] =>
  data.map((i) => ({ value: i.id, label: i.name }));

export const getOptionFromDynamicContent = (
  data: IBaseContent[],
  value: number
): IOption | undefined => {
  const result = data.find((i) => i.id === value);

  return result && { value: result.id, label: result.name };
};

export const getMultiOptionFromDynamicContent = (
  data: IBaseContent[],
  value: number[]
): IOption[] =>
  data
    .filter((i) => value.includes(i.id))
    .map((i) => ({ value: i.id, label: i.name }));
