import React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';
import { breakpoints } from '../../../styles/breakpoints';
import { Sizes } from '../../../types/general';
import classes from './Container.module.scss';

interface IProps {
  children?: React.ReactNode;
  size?: Sizes;
  fullWidth?: boolean;
  disablePaddings?: boolean;
  className?: string;
}

const Container: React.FC<IProps> = (props) => {
  const { children, size, fullWidth, disablePaddings, className } = props;
  const styles = css`
    max-width: ${fullWidth ? '100%' : `${size && breakpoints[size]}px`};
    padding: ${disablePaddings && '0;'};
  `;
  return (
    <div className={classNames(classes.container, styles, className)}>
      {children}
    </div>
  );
};

export default Container;
