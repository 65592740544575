import React from 'react';
import moment from 'moment';
import { ISubscription } from '../../../types/subscriptions';
import Chip from '../../elements/Chip';
import Link from '../../elements/Link';
import classes from './SubscriptionDetails.module.scss';

interface SubscriptionDetailsProps {
  name: string;
  expired: number | null;
  subscription: ISubscription;
}

const SubscriptionDetails: React.FC<SubscriptionDetailsProps> = (props) => {
  const { name, expired, subscription } = props;
  return (
    <div className={classes.wrap}>
      <Chip className={classes.el} size="xs">
        {name}
      </Chip>
      {expired && (
        <div className={classes.el}>
          {moment(expired).format('Do MMM YYYY')}
        </div>
      )}
      {subscription !== ISubscription.greatMind && (
        <Link underline to={{ hash: 'subscription' }}>
          Upgrade
        </Link>
      )}
    </div>
  );
};

export default SubscriptionDetails;
