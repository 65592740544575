import React, { useEffect, useRef, useState } from 'react';
import { Card } from '../../elements/Card';
import { ReactComponent as Search } from '../../../assets/img/icon/search.svg';
import TextField from '../../elements/TextField';
import classes from './ChatsList.module.scss';
import { useDispatch, useSelector } from '../../../libs/redux';
import { selectChat, selectChatById } from '../../../store/reducers/chats';
import Loader from '../../elements/Loader';
import { getChatsList } from '../../../store/actions/chat';
import { selectCurrentChat } from '../../../store/reducers/current-chat';
import ScrollBar from '../../elements/ScrollBar';
import Item from './Item';

const ChatsList: React.FC = () => {
  const { list, loading, pagination } = useSelector(selectChat);
  const { id: chatId } = useSelector(selectCurrentChat);
  const currentChat = useSelector((state) => selectChatById(state, chatId));
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const chatRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(getChatsList({ page: 1, name: search }));
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    if (!pagination && !loading) {
      dispatch(getChatsList({ page: 1 }));
    }
  }, []);

  const onScroll = () => {
    if (chatRef.current) {
      if (
        list &&
        chatRef.current.scrollHeight - chatRef.current.scrollTop < 200 &&
        pagination &&
        pagination.total > list.length &&
        !loading
      ) {
        dispatch(
          getChatsList({
            page: pagination.currentPage + 1,
            userExternalId: currentChat
              ? currentChat.details.userId
              : undefined,
            name: search,
          })
        );
      }
    }
  };

  return (
    <Card className={classes.contacts} noPadding>
      <div className={classes.cardBody}>
        <div className={classes.contacts__search}>
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            startIcon={<Search />}
            fullWidth
            placeholder="Search"
          />
        </div>

        <div className={classes.contacts__list}>
          <ScrollBar
            scrollableNodeProps={{
              ref: chatRef,
              onScroll,
            }}
          >
            {list.map((item) => (
              <Item
                key={item.id}
                chat={item}
                active={Boolean(
                  currentChat &&
                    item.details.userId === currentChat.details.userId
                )}
              />
            ))}
            <Loader open={loading} />
          </ScrollBar>
        </div>
      </div>
    </Card>
  );
};

export default ChatsList;
