import classNames from 'classnames';
import React from 'react';
import HelperText from '../HelperText';
import Label from '../Label';
import classes from './InputBase.module.scss';

export interface InputBaseProps {
  label?: React.ReactNode;
  children?: React.ReactNode;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  variant?: 'standart' | 'transparent';
}

const InputBase: React.FC<InputBaseProps> = (props) => {
  const {
    label,
    children,
    error,
    helperText,
    fullWidth = false,
    startIcon,
    endIcon,
    variant = 'standart',
  } = props;
  return (
    <div
      className={classNames(classes.w, classes[variant], {
        [classes.error]: error,
        [classes.fullWidth]: fullWidth,
      })}
    >
      {label && <Label error={error}>{label}</Label>}
      <div className={classes.inputWrap}>
        {startIcon && (
          <div className={classNames(classes.icon, classes.startIcon)}>
            {startIcon}
          </div>
        )}
        {children}
        {endIcon && (
          <div className={classNames(classes.icon, classes.endIcon)}>
            {endIcon}
          </div>
        )}
      </div>
      {helperText && (
        <HelperText
          color={error ? 'error' : undefined}
          className={classes.helperText}
        >
          {helperText}
        </HelperText>
      )}
    </div>
  );
};

export default InputBase;
