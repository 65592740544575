import classNames from 'classnames';
import React from 'react';
import { css } from '@emotion/css';
import { Colors } from '../../../types/general';
import classes from './Progress.module.scss';

export interface ProgressProps {
  // The value of the progress indicator between 0 and 100.
  value: number;
  color?: Colors;
  size?: number;
  className?: string;
}

const Progress: React.FC<ProgressProps> = (props) => {
  const { value, color = 'primary', size = 10, className } = props;

  const styles = {
    wrap: css`
      background: rgb(var(--${color}-main-rgb) / 10%);
      font-size: ${size}px;
    `,
    bar: css`
      background: var(--${color}-main);
      width: ${value}%;
    `,
  };
  return (
    <div className={classNames(classes.wrap, styles.wrap, className)}>
      <div className={classNames(classes.bar, styles.bar)} />
    </div>
  );
};

export default Progress;
