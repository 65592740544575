import React from 'react';
import classNames from 'classnames';
import ChatBody from '../../modules/ChatBody';
import ChatsList from '../../modules/ChatsList';

import classes from './Chat.module.scss';

interface IProps {
  activeChat: boolean;
}

const ChatTemplate: React.FC<IProps> = (props) => {
  const { activeChat } = props;

  return (
    <section className={classes.wrap}>
      <div className={classes.list}>
        <ChatsList />
      </div>
      <div
        className={classNames(classes.body, { [classes.active]: activeChat })}
      >
        <ChatBody />
      </div>
    </section>
  );
};

export default ChatTemplate;
