import classNames from 'classnames';
import React from 'react';
import classes from './Card.module.scss';

export interface CardProps {
  children?: React.ReactNode;
  noPadding?: boolean;
  className?: string;
}

const Card: React.FC<CardProps> = (props) => {
  const { children, noPadding, className } = props;
  return (
    <div
      className={classNames(classes.card, className, {
        [classes.noPadding]: noPadding,
      })}
    >
      {children}
    </div>
  );
};

export default Card;
