import { useLocation } from 'react-router-dom';

const useQueryParams = (params: string | string[]) => {
  const { search } = useLocation();
  const getQuery = (param: string) => new URLSearchParams(search).get(param);

  return (typeof params === 'string' ? [params] : params).reduce(
    (acc: { [k: string]: string | null }, item) => ({
      ...acc,
      [item]: getQuery(item),
    }),
    {}
  );
};

export default useQueryParams;
