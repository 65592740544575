import axios, { Response } from '../libs/axios';
import { ISettings } from '../types/general';
import { IUser } from '../types/user';

export const requestUser = () =>
  axios<Response<IUser>>({
    url: '/user',
  });

export const requestContact = (data: {
  name: string;
  email: string;
  subject: string;
  message: string;
  re_captcha: string;
}) =>
  axios({
    url: '/contact-requests',
    method: 'POST',
    data,
  });

export const requestSettings = () =>
  axios<Response<ISettings>>({
    url: '/settings',
  });

export const getFile = (url: string) =>
  axios<Blob>({
    url,
    method: 'GET',
    responseType: 'blob',
  });
