export const fieldNames: Record<string, string> = {
  communication: 'Communication',
  creativity: 'Creativity',
  empathy: 'Empathy',
  inspirational: 'Inspirational',
  listening: 'Listening',
  problem_solving: 'Problem Solver',
  strategy: 'Strategy',
  technical: 'Technical',
  vision: 'Vision',
  humility: 'Humility',
};

export default fieldNames;
