import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  ERROR_FIELD_EMAIL_REQUIRED,
  ERROR_FIELD_EMAIL_VALIDATION,
  ERROR_FIELD_PASSWORD,
} from '../../../constants/error-messages';
import { EMAIL, PASSWORD } from '../../../constants/regexp';
import { RESTORE_PASSWORD } from '../../../constants/routes';
import Button from '../../elements/Button';
import Checkbox from '../../elements/Checkbox';
import FormGroup from '../../elements/FormGroup';
import TextField from '../../elements/TextField';
import classes from './SignInForm.module.scss';

interface IProps {
  onSubmit: (data: { email: string; password: string }) => void;
  errors?: { email?: string; password?: string };
}

const SignInForm: React.FC<IProps> = (props) => {
  const { onSubmit, errors: propsErrors } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    email: string;
    password: string;
  }>();
  const fields = {
    email: register('email', {
      required: ERROR_FIELD_EMAIL_REQUIRED,
      pattern: { value: EMAIL, message: ERROR_FIELD_EMAIL_VALIDATION },
    }),
    password: register('password', {
      required: ERROR_FIELD_PASSWORD,
      pattern: {
        value: PASSWORD,
        message: ERROR_FIELD_PASSWORD,
      },
    }),
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <TextField
          fullWidth
          label="Email"
          name={fields.email.name}
          onChange={fields.email.onChange}
          inputRef={fields.email.ref}
          error={Boolean(errors.email || propsErrors?.email)}
          helperText={errors.email?.message || propsErrors?.email}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          label="Password"
          type="password"
          name={fields.password.name}
          onChange={fields.password.onChange}
          inputRef={fields.password.ref}
          error={Boolean(errors.password || propsErrors?.password)}
          helperText={errors.password?.message || propsErrors?.password}
        />
      </FormGroup>
      <div className={classes.forgotWrap}>
        <Checkbox label="Keep signed in" />
        <Link to={RESTORE_PASSWORD.fullPath} className="link">
          Forgot password?
        </Link>
      </div>
      <div className={classes.btnWrap}>
        <Button type="submit" variant="contained" shadow size="lg">
          SIGN IN
        </Button>
      </div>
    </form>
  );
};

export default SignInForm;
