import classNames from 'classnames';
import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  TOPICS,
  SEARCH,
  MESSAGES,
  PROFILE,
  SPARRING,
} from '../../../constants/routes';
import Logo from '../../elements/Logo';
import classes from './SideMenu.module.scss';
import topics from '../../../assets/img/side-menu/aside-topics.svg';
import search from '../../../assets/img/side-menu/aside-search.svg';
import sparring from '../../../assets/img/side-menu/aside-sparring.svg';
import messages from '../../../assets/img/side-menu/aside-messages.svg';
import profile from '../../../assets/img/side-menu/aside-profile.svg';
import logout from '../../../assets/img/side-menu/aside-logout.svg';
import { useDispatch, useSelector } from '../../../libs/redux';
import { logOut } from '../../../store/actions/authentication';
import Backdrop from '../../elements/Backdrop';
import { selectSideMenu } from '../../../store/reducers/side-menu';
import { toggleSideMenu } from '../../../store/actions/side-menu';
import ScrollBar from '../../elements/ScrollBar';

const items = [
  {
    ...TOPICS,
    icon: topics,
  },
  {
    ...SPARRING,
    icon: sparring,
  },
  {
    ...SEARCH,
    icon: search,
  },
  {
    ...MESSAGES,
    icon: messages,
  },
  {
    ...PROFILE,
    icon: profile,
  },
];

const SideMenu: React.FC = () => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(selectSideMenu);
  const location = useLocation();

  useEffect(() => {
    if (isOpen) {
      dispatch(toggleSideMenu(false));
    }
  }, [location]);
  return (
    <aside
      className={classNames(classes.sideMenu, { [classes.hide]: !isOpen })}
    >
      <Backdrop open={isOpen} onClick={() => dispatch(toggleSideMenu(false))} />
      <div className={classes.logo}>
        <Logo />
      </div>
      <div className={classes.navigation}>
        <ScrollBar>
          {items.map((item) => (
            <NavLink
              key={item.fullPath}
              to={item.fullPath}
              className={({ isActive }) =>
                classNames(classes.menu__item, { [classes.active]: isActive })
              }
            >
              <img src={item.icon} alt="icon" />
              {item.name}
            </NavLink>
          ))}
        </ScrollBar>
      </div>
      <button
        type="button"
        className={classNames(classes.menu__item, classes.logout)}
        onClick={() => dispatch(logOut())}
      >
        <img src={logout} alt="icon" />
        Log out
      </button>
    </aside>
  );
};

export default SideMenu;
