import React from 'react';
import {
  IEducation,
  IExperience,
  IUser,
  IUserUpdatableFields,
} from '../../../types/user';
import Avatar from '../../elements/Avatar';
import classes from './ProfileInfoCard.module.scss';
import { ReactComponent as Edit } from '../../../assets/img/icon/edit.svg';
import ExperienceList from '../ExperienceList';
import { EDIT_PROFILE } from '../../../constants/routes';
import { getTimeRange } from '../../../helpers/time';
import useDynamicContent from '../../../hooks/useDynamicContent';
import Rating from '../Rating';
import Link from '../../elements/Link';
import SubscriptionDetails from '../SubscriptionDetails';

interface IProps {
  user: IUser;
  education?: {
    list: IEducation[];
    onAdd?: () => void;
    onEdit?: (id: number) => void;
    onDelete?: (id: number) => void;
  };
  experience?: {
    list: IExperience[];
    onAdd?: () => void;
    onEdit?: (id: number) => void;
    onDelete?: (id: number) => void;
  };
  onUserEdit?: (user: IUserUpdatableFields) => void;
}

const ProfileInfoCard: React.FC<IProps> = (props) => {
  const { user, onUserEdit, experience, education } = props;
  const { employmentTypes } = useDynamicContent(['employmentTypes']);

  const getemploymentTypeName = (id: number) => {
    const result = employmentTypes.list.find((item) => item.id === id);
    return (result && result.name) || '';
  };

  return (
    <div>
      <div className={classes.mainInfo}>
        <div className={classes.avatar}>
          <Avatar src={user.photo_url} fullWidth />
        </div>

        <div className={classes.infoHead}>
          <div>
            <div className={classes.name}>
              <h3>
                {user.first_name} {user.last_name}
              </h3>
              {user.subscription_user && (
                <SubscriptionDetails
                  name={user.subscription_user.name}
                  subscription={user.subscription_user.subscription}
                  expired={user.subscription_user.expired_at}
                />
              )}
            </div>

            <div className={classes.experienceInfo}>
              {experience && experience.list.length > 0 && (
                <p className={classes.title}>
                  {experience.list[0].title} at {experience.list[0].industry}
                </p>
              )}
              <p className={classes.location}>
                {user.city?.name}, {user.city?.country.name}
              </p>
            </div>
          </div>
          {onUserEdit ? (
            <Link
              className={classes.edit}
              to={EDIT_PROFILE.fullPath}
              color="secondary"
            >
              <Edit />
            </Link>
          ) : (
            <div>
              {user.user_sum_rating && (
                <Rating
                  variant="vertical"
                  communications={user.sparring_users_count || 0}
                  ko={user.sparring_knock_outs_percentage || 0}
                  name={`${user.first_name} ${user.last_name}`}
                  rating={user.user_sum_rating}
                />
              )}
            </div>
          )}
        </div>
        <div className={classes.info}>
          {user.looking_occupations && (
            <div className={classes.row}>
              <p className={classes.th}>Looking for:</p>
              <p>{user.looking_occupations.map((i) => i.name).join(', ')}</p>
            </div>
          )}

          {user.interests && (
            <div className={classes.row}>
              <p className={classes.th}>Interests:</p>
              <p>{user.interests.map((i) => i.name).join(', ')}</p>
            </div>
          )}
          {user.language && (
            <div className={classes.row}>
              <p className={classes.th}>Language:</p>
              <p>{user.language.name}</p>
            </div>
          )}
          {user.interaction_types && (
            <div className={classes.row}>
              <p className={classes.th}>Interactions:</p>
              <p>{user.interaction_types.map((i) => i.name).join(', ')}</p>
            </div>
          )}

          {onUserEdit && (
            <div className={classes.row}>
              <p className={classes.th}>Rating:</p>
              <div>
                <Rating
                  communications={user.sparring_users_count || 0}
                  ko={user.sparring_knock_outs_percentage || 0}
                  name={`${user.first_name} ${user.last_name}`}
                  rating={user.user_sum_rating}
                  suggestUpdate={
                    user.subscription_user
                      ? user.subscription_user.subscription
                      : undefined
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {user.user_detail && (
        <div className={classes.info__wrap}>
          <div className={classes.info__head}>
            <h4>About</h4>
          </div>
          <div className={classes.main__info}>
            <p>{user.user_detail.about}</p>
          </div>
        </div>
      )}

      {experience && (
        <div className={classes.info__wrap}>
          <ExperienceList
            title="Experience"
            items={experience.list.map((item) => ({
              ...item,
              title: `${item.headline} & ${item.title}`,
              subtitle: `${item.company_name} - ${getemploymentTypeName(
                item.employment_type_id
              )}`,
              date: `
                    ${new Date(item.start_date).getFullYear()}-${new Date(
                item.end_date
              ).getFullYear()} —
                    ${getTimeRange({
                      from: item.start_date,
                      to: item.end_date,
                    })}`,
              caption: item.location,
              description: item.description,
            }))}
            onAdd={experience.onAdd}
            onDelete={experience.onDelete}
            onEdit={experience.onEdit}
          />
        </div>
      )}
      {education && (
        <div className={classes.info__wrap}>
          <ExperienceList
            title="Education"
            items={education.list.map((item) => ({
              ...item,
              title: item.school,
              subtitle: `${item.degree}, ${item.field_study}`,
              date: `
                  ${new Date(item.start_date).getFullYear()}-${new Date(
                item.end_date
              ).getFullYear()}`,
            }))}
            onAdd={education.onAdd}
            onDelete={education.onDelete}
            onEdit={education.onEdit}
          />
        </div>
      )}
    </div>
  );
};

export default ProfileInfoCard;
