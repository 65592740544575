import { useCallback, useEffect, useRef } from 'react';

type Props = {
  onTriggered: () => void;
};
const useClickOutside = ({ onTriggered }: Props) => {
  const ref = useRef(null);

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (ref && ref.current) {
        if (!(ref.current! as any).contains(e.target)) {
          onTriggered();
        }
      }
    },
    [ref.current]
  );

  useEffect(() => {
    document.addEventListener('click', clickListener);
    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, []);

  return ref;
};

export default useClickOutside;
