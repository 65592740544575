import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loader from '../../components/elements/Loader';
import LandingLayout from '../../components/layouts/Landing';
import TextPageTemplate from '../../components/templates/TextPage';
import { useDispatch, useSelector } from '../../libs/redux';
import { getPage } from '../../store/actions/dynamic-pages';
import { selectDynamicPages } from '../../store/reducers/dynamic-pages';
import ErrorTemplate from '../../components/templates/Error';

const TextPage: React.FC = () => {
  const { slug } = useParams();
  const { pages } = useSelector(selectDynamicPages);
  const dispatch = useDispatch();

  useEffect(() => {
    if (slug && !pages.loading && !pages.list[slug]) {
      dispatch(getPage(slug));
    }
  }, [slug]);

  if (!slug || pages.error) {
    return <ErrorTemplate code={404} />;
  }

  const currentPage = pages.list[slug];

  return (
    <LandingLayout pageTitle={currentPage && currentPage.title}>
      {pages.loading || !currentPage ? (
        <Loader position="absolute" />
      ) : (
        <>
          <Helmet>
            {currentPage.description && (
              <meta name="description" content={currentPage.description} />
            )}
            {currentPage.keywords && (
              <meta name="keywords" content={currentPage.keywords} />
            )}
          </Helmet>
          <TextPageTemplate
            title={currentPage.title}
            content={currentPage.content}
          />
        </>
      )}
    </LandingLayout>
  );
};

export default TextPage;
