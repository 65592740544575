import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import classes from './ChatForm.module.scss';
import { ReactComponent as Send } from '../../../assets/img/icon/send.svg';
import { ReactComponent as Smile } from '../../../assets/img/icon/smile.svg';
import { ReactComponent as Attachment } from '../../../assets/img/icon/attachment.svg';
import { ReactComponent as Upload } from '../../../assets/img/icon/cloud-upload.svg';
import FilesList from './FilesList';
import EmojiPicker from '../EmojiPicker';
import Transition from '../../elements/Transition';
import useClickOutside from '../../../hooks/useOutsideClick';
import { useDispatch, useSelector } from '../../../libs/redux';
import { readMessages, sendMessage } from '../../../store/actions/chat';
import { attachFile } from '../../../repositories/chat';
import { IMessageFile } from '../../../types/chat';
import { selectCurrentChat } from '../../../store/reducers/current-chat';
import { addFastNotification } from '../../../store/actions/notifications';
import TextField from './TextField';
import { selectChatById } from '../../../store/reducers/chats';

interface IProps {
  onSend?: () => void;
}

const ChatForm: React.FC<IProps> = (props) => {
  const { onSend } = props;
  const [isVisibleEmojipicker, setIsVisibleEmojipicker] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const emojiRef = useClickOutside({
    onTriggered() {
      setIsVisibleEmojipicker(false);
    },
  });
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { id: chatId } = useSelector(selectCurrentChat);
  const currentChat = useSelector((state) => selectChatById(state, chatId));
  const dispatch = useDispatch();

  const { setValue, getValues, handleSubmit, reset, control } = useForm<{
    message: string;
  }>({ defaultValues: { message: '' } });
  const resetForm = () => {
    setFiles([]);
    reset({ message: '' });
  };

  useEffect(() => {
    resetForm();
  }, [chatId]);

  const handleReadChat = () => {
    if (chatId) {
      dispatch(readMessages(chatId));
    }
  };

  const onSubmit = async (data: { message: string }) => {
    let filesList: IMessageFile[] = [];

    if (files.length) {
      setUploadingFiles(true);
      filesList = await Promise.all(files.map((file) => attachFile(file)));
      setUploadingFiles(false);
    }
    if (currentChat && (data.message.length || files.length)) {
      resetForm();
      handleReadChat();
      dispatch(
        sendMessage({
          userExternalId: currentChat.details.userId,
          text: data.message,
          files: filesList.map((file) => file.id),
        })
      );
      if (onSend) {
        onSend();
      }
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }
  };

  const handleEmojiSelect = (emoji: string) => {
    setValue('message', getValues('message') + emoji);
  };

  const setFile = (filelist: FileList | null) => {
    const maxSize = 5;
    if (filelist && filelist.length) {
      const file = filelist[0];
      if (file.size > 10 ** 6 * maxSize) {
        dispatch(
          addFastNotification({
            content: `Max file size ${maxSize}Mb`,
            color: 'error',
          })
        );
      } else {
        setFiles([...files, filelist[0]]);
      }
    }
  };

  return (
    <form className={classes.message__form} onSubmit={handleSubmit(onSubmit)}>
      <FilesList files={files} onRemove={setFiles} />
      <div className={classes.wrap}>
        <label className={classes.file}>
          <input
            key={files.map((f) => f.name).join('')}
            type="file"
            onChange={(e) => setFile(e.target.files)}
            disabled={uploadingFiles}
          />
          <Attachment />
        </label>
        <Controller
          control={control}
          name="message"
          render={({ field }) => (
            <TextField
              inputRef={textareaRef}
              value={field.value || ''}
              onChange={field.onChange}
              onEnter={handleSubmit(onSubmit)}
              onFocus={handleReadChat}
            />
          )}
        />
        <div className={classes.emojiWrap} ref={emojiRef}>
          <Transition in={isVisibleEmojipicker} unmountOnExit>
            <div className={classes.picker}>
              <EmojiPicker onSelect={handleEmojiSelect} />
            </div>
          </Transition>
          <button
            type="button"
            onClick={() => setIsVisibleEmojipicker(!isVisibleEmojipicker)}
            className={classes.emoji}
          >
            <Smile />
          </button>
        </div>
        <button
          type="submit"
          className={classNames(classes.submit, {
            [classes.uploading]: uploadingFiles,
          })}
          disabled={uploadingFiles}
        >
          {uploadingFiles ? <Upload className={classes.upload} /> : <Send />}
        </button>
      </div>
    </form>
  );
};

export default ChatForm;
