import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import Item from './Item';
import Modal from '../../elements/Modal';
import { useDispatch, useSelector } from '../../../libs/redux';
import { selectSubscriptions } from '../../../store/reducers/subscriptions';
import { getSubscriptions } from '../../../store/actions/subscriptions';
import 'swiper/css';
import 'swiper/css/pagination';
import classes from './Subscriptions.module.scss';
import useWindowSize from '../../../hooks/useWindowSize';
import { breakpoints } from '../../../styles/breakpoints';

const Subscriptions: React.FC = () => {
  const { list, loading, loaded } = useSelector(selectSubscriptions);
  const dispatch = useDispatch();
  const { width = 0 } = useWindowSize();
  const { hash } = useLocation();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate({ hash: '' });
  };

  useEffect(() => {
    if (!loaded && !loading) {
      dispatch(getSubscriptions());
    }
  }, []);

  const getSlidesCount = () => {
    if (width >= breakpoints.xl) {
      return 3;
    }
    if (width > breakpoints.md) {
      return 2;
    }
    return 1;
  };

  return (
    <Modal
      fullWidth
      size="xl"
      open={hash === '#subscription'}
      onClose={handleClose}
    >
      <div className={classes.wrap}>
        <Swiper
          modules={[Pagination]}
          slidesPerView={getSlidesCount()}
          pagination={{ clickable: true }}
          focusableElements="div"
        >
          {list.map((subscription) => (
            <SwiperSlide key={subscription.id}>
              <Item subscription={subscription} onSelect={console.log} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Modal>
  );
};

export default Subscriptions;
