import React from 'react';
import classes from './Tooltip.module.scss';

interface Props {
  children?: React.ReactNode;
  text: string;
}

const Tooltip: React.FC<Props> = ({ children, text }) => (
  <div className={classes.tooltip}>
    <div className={classes.childrenWrap}>{children}</div>
    <div className={classes.text}>{text}</div>
  </div>
);

export default Tooltip;
