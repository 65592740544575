import { createReducer } from '@reduxjs/toolkit';
import { IUser } from '../../types/user';
import { clearAuthData } from '../actions/authentication';
import {
  setUser,
  setUserLoading,
  setUserPhoto,
  updateUserData,
} from '../actions/user';

interface State {
  user: IUser | null;
  errors: Partial<Record<keyof IUser | 'photo', string>>;
  loading: boolean;
  updating: boolean;
  loaded: boolean;
}

const initialState: State = {
  user: null,
  errors: {},
  loading: false,
  updating: false,
  loaded: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setUser, (state, action) => {
    state.user = action.payload && {
      ...action.payload,
      subscription_user: action.payload.subscription_user && {
        ...action.payload.subscription_user,
        expired_at:
          (action.payload.subscription_user.expired_at || 0) * 1000 || null,
      },
    };
    state.loaded = true;
  });
  builder.addCase(setUserLoading, (state, action) => {
    state.loading = action.payload;
  });

  builder.addCase(setUserPhoto.pending, (state) => {
    state.errors = {};
    state.updating = true;
  });
  builder.addCase(setUserPhoto.fulfilled, (state, action) => {
    state.updating = false;
    if (state.user) {
      state.user.photo_url = action.payload;
    }
  });
  builder.addCase(setUserPhoto.rejected, (state, action) => {
    state.updating = false;
    if (action.payload) {
      state.errors = action.payload;
    }
  });
  builder.addCase(updateUserData.pending, (state) => {
    state.errors = {};
    state.updating = true;
  });
  builder.addCase(updateUserData.fulfilled, (state, action) => {
    state.updating = false;
    state.user = action.payload;
  });
  builder.addCase(updateUserData.rejected, (state, action) => {
    state.updating = false;
    if (action.payload) {
      state.errors = action.payload;
    }
  });
  builder.addCase(clearAuthData, (state) => {
    state.user = null;
  });
});

interface Store {
  user: State;
}

export const selectUser = (state: Store) => state.user;

export default reducer;
