import React, { useEffect } from 'react';
import Container from '../../elements/Container';
import BaseLayout from '../../layouts/Base';
import CardWithLogo from '../../elements/CardWithLogo';
import { useDispatch, useSelector } from '../../../libs/redux';
import {
  changePassword,
  resetPassword,
  setAuthErrors,
  toggleResetState,
} from '../../../store/actions/authentication';
import { selectAuthentication } from '../../../store/reducers/authentication';
import ResetPasswordForm from '../../modules/ResetPasswordForm';
import classes from './RestorePassword.module.scss';
import { ResetPassworState } from '../../../types/authentication';
import ChangePasswordForm from '../../modules/ChangePasswordForm';
import useQueryParams from '../../../hooks/useQueryParams';
import Loader from '../../elements/Loader';

const RestorePasswordTemplate: React.FC = () => {
  const { errors, resetPasswordState, loading } =
    useSelector(selectAuthentication);
  const { email, token } = useQueryParams(['token', 'email']);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      email &&
      token &&
      resetPasswordState !== ResetPassworState.changePassword
    ) {
      toggleResetState(ResetPassworState.changePassword);
    }
  }, [email, token]);

  const handleChangePassword = (data: {
    password: string;
    confirmPassword: string;
  }) => {
    if (!email || !token) {
      dispatch(
        setAuthErrors({
          email: 'email is invalid',
        })
      );
    } else {
      dispatch(changePassword({ ...data, email, token }));
    }
  };

  const steps: Record<
    keyof typeof ResetPassworState,
    { title: string; text: string; component: React.ReactNode | null }
  > = {
    resetPassword: {
      title: 'Forgot your password?',
      text: 'Enter the email address you use to sign in to.',
      component: (
        <ResetPasswordForm
          errors={errors}
          onSubmit={(d) => dispatch(resetPassword(d))}
        />
      ),
    },
    changePassword: {
      title: 'RESET YOUR PASSWORD',
      text: 'Strong password include numbers, letters and punctuation marks..',
      component: (
        <ChangePasswordForm errors={errors} onSubmit={handleChangePassword} />
      ),
    },
    resetEmailSent: {
      title: 'Check your email',
      text: 'Follow the directions in the email to reset your password.',
      component: null,
    },
    passwordChanged: {
      title: 'Password Updated!',
      text: 'Your password has been changed successfully. Use your new password to Sign In',
      component: null,
    },
  };

  const step =
    steps[
      ResetPassworState[resetPasswordState] as keyof typeof ResetPassworState
    ];

  return (
    <BaseLayout>
      <Container size="sm">
        <Loader position="fixed" open={loading} />
        <CardWithLogo>
          <div className={classes.text__box}>
            <h2>{step.title}</h2>
            <p>{step.text}</p>
          </div>
          {step.component}
        </CardWithLogo>
      </Container>
    </BaseLayout>
  );
};

export default RestorePasswordTemplate;
