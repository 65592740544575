import { createAction } from '@reduxjs/toolkit';
import { ModalName } from '../../types/global-modals';

export const updateModal = createAction<{
  name: ModalName;
  open: boolean;
}>('updateModal');

export const setRatingModalData = createAction<{
  userId: number;
  name: string;
} | null>('setRatingModalData');
