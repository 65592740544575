import React, { useState } from 'react';
import { IChatMedia, IMessageFile } from '../../../types/chat';
import classes from './ChatMessage.module.scss';
import { mimeType } from '../../../constants/file';
import useFileDownloader from '../../../hooks/useFileDownloader';
import Button from '../Button';
import { getFile } from '../../../repositories/general';
import Loader from '../Loader';
import broken from '../../../assets/img/file-icons/broken.svg';

interface IProps {
  file: IMessageFile;
  onMediaOpen: (data: IChatMedia) => void;
}

const File: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const { file, onMediaOpen } = props;
  const downloader = useFileDownloader();
  const beautifySize = (val: number) => {
    if (val >= 10 ** 6) {
      return `${(val / 10 ** 6).toFixed(2)} Mb`;
    }
    if (val >= 10 ** 3) {
      return `${(val / 10 ** 3).toFixed(2)} Kb`;
    }
    return `${val} b`;
  };

  if (mimeType.image.includes(file.mimeType)) {
    if (!imageSrc && !loading) {
      setLoading(true);
      getFile(file.url)
        .then(async (response) => {
          setImageSrc(URL.createObjectURL(response));
        })
        .catch(() => setImageSrc(broken))
        .finally(() => setLoading(false));
    }

    return (
      <div className={classes.image}>
        {loading ? (
          <Loader />
        ) : (
          <button
            type="button"
            onClick={() =>
              onMediaOpen({ id: file.id, src: imageSrc, viewer: 'image' })
            }
          >
            <img src={imageSrc} alt="" />
          </button>
        )}
      </div>
    );
  }

  return (
    <div className={classes.file}>
      <div className={classes.name}>{file.originalName}</div>
      <span className={classes.size}>{beautifySize(file.size)}</span>
      <Button
        variant="link"
        onClick={() => downloader(file.url, file.originalName)}
      >
        Open
      </Button>
    </div>
  );
};

export default File;
