import React from 'react';
import useDynamicContent from '../../../hooks/useDynamicContent';
import classes from './TopicDetails.module.scss';

interface IProps {
  name: string;
  description: string;
  tags: string;
  status: number;
  usersCount?: number;
  descriptionLength?: number;
}

const TopicDetails: React.FC<IProps> = (props) => {
  const { description, status, tags, name, descriptionLength, usersCount } =
    props;

  const { topicStatuses } = useDynamicContent(['topicStatuses']);

  const handledescription = (text: string) => {
    if (!descriptionLength || text.length < descriptionLength) {
      return text;
    }
    return `${text.substring(0, descriptionLength)}...`;
  };
  return (
    <div className={classes.topic__info}>
      <div className={classes.topic__head}>
        <h3>{name}</h3>
      </div>
      <div className={classes.topic__details}>
        <div className={classes.description}>
          {handledescription(description)}
        </div>
        <div className={classes.details}>
          <div>
            <p className={classes.th}>Tags:</p>
            <p>{tags}</p>
          </div>
          <div>
            <p className={classes.th}>Status:</p>
            <p>{topicStatuses.list.find((s) => s.id === status)?.name}</p>
          </div>
          {usersCount !== undefined && (
            <div>
              <p className={classes.th}>Members:</p>
              <p>{usersCount}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopicDetails;
