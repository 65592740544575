import { createReducer } from '@reduxjs/toolkit';
import {
  IBaseContent,
  IDynamicContentKeys,
  IFaqItem,
} from '../../types/dynamic-content';
import { getBaseContent, getFaqs } from '../actions/dynamic-content';

interface ContentItem {
  loading: boolean;
  loaded: boolean;
  error: string;
}

interface State
  extends Record<IDynamicContentKeys, ContentItem & { list: IBaseContent[] }> {
  faq: ContentItem & { list: IFaqItem[] };
}

const initialState: State = {
  faq: {
    list: [],
    error: '',
    loading: false,
    loaded: false,
  },
  interests: {
    list: [],
    error: '',
    loading: false,
    loaded: false,
  },
  countries: {
    list: [],
    error: '',
    loading: false,
    loaded: false,
  },
  cities: {
    list: [],
    error: '',
    loading: false,
    loaded: false,
  },
  languages: {
    list: [],
    error: '',
    loading: false,
    loaded: false,
  },
  employmentTypes: {
    list: [],
    error: '',
    loading: false,
    loaded: false,
  },
  occupations: {
    list: [],
    error: '',
    loading: false,
    loaded: false,
  },
  interaction: {
    list: [],
    error: '',
    loading: false,
    loaded: false,
  },
  scaleSearch: {
    list: [],
    error: '',
    loading: false,
    loaded: false,
  },
  topicTags: {
    list: [],
    error: '',
    loading: false,
    loaded: false,
  },
  topicStatuses: {
    list: [],
    error: '',
    loading: false,
    loaded: false,
  },
  topics: {
    list: [],
    error: '',
    loading: false,
    loaded: false,
  },
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(getFaqs.pending, (state) => {
    state.faq.loading = true;
  });
  builder.addCase(getFaqs.fulfilled, (state, action) => {
    state.faq.list = action.payload;
    state.faq.loading = false;
    state.faq.loaded = true;
  });
  builder.addCase(getFaqs.rejected, (state) => {
    state.faq.list = [];
    state.faq.loading = false;
    state.faq.loaded = true;
  });

  builder.addCase(getBaseContent.pending, (state, action) => {
    state[action.meta.arg.key].loading = true;
  });
  builder.addCase(getBaseContent.fulfilled, (state, action) => {
    state[action.meta.arg.key].list = action.payload;
    state[action.meta.arg.key].loading = false;
    state[action.meta.arg.key].loaded = true;
  });
  builder.addCase(getBaseContent.rejected, (state, action) => {
    state[action.meta.arg.key].list = [];
    state[action.meta.arg.key].loading = false;
    state[action.meta.arg.key].loaded = true;
  });
});

interface Store {
  dynamicContent: State;
}

export const selectDynamicContent = (state: Store) => state.dynamicContent;

export default reducer;
