import React from 'react';
import PrivateRoute from '../../components/elements/PrivateRoute';
import SignInTemplate from '../../components/templates/SignIn';
import { Access } from '../../types/general';

const SignInPage: React.FC = () => (
  <PrivateRoute access={Access.notAuthenticated}>
    <SignInTemplate />
  </PrivateRoute>
);

export default SignInPage;
