import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  requestCities,
  requestCountries,
  requestEmploymentTypes,
  requestFaqs,
  requestInteraction,
  requestInterests,
  requestLanguages,
  requestOccupation,
  requestScaleSearch,
  requestTopicStatuses,
  requestTopicTags,
} from '../../repositories/dynamic-content';
import { requestTopics } from '../../repositories/topics';
import { IDynamicContentKeys } from '../../types/dynamic-content';

export const getFaqs = createAsyncThunk('getFaqs', async () => {
  const response = await requestFaqs();
  return response.data;
});

export const getBaseContent = createAsyncThunk(
  'getBaseContent',
  async (payload: {
    key: IDynamicContentKeys;
    data?: number;
    search?: string;
  }) => {
    const getRequestFn = () => {
      switch (payload.key) {
        case 'interests':
          return requestInterests();
        case 'countries':
          return requestCountries(payload.search);
        case 'cities':
          return requestCities(payload.data, payload.search);
        case 'languages':
          return requestLanguages();
        case 'employmentTypes':
          return requestEmploymentTypes();
        case 'occupations':
          return requestOccupation();
        case 'interaction':
          return requestInteraction();
        case 'scaleSearch':
          return requestScaleSearch();
        case 'topicTags':
          return requestTopicTags(payload.search);
        case 'topicStatuses':
          return requestTopicStatuses();
        case 'topics':
          return requestTopics({
            search: payload.search,
            my_topics: true,
            filter: {
              status: 1,
            },
            fields: { topics: ['id', 'name'] },
          });
        default:
          return requestInterests();
      }
    };
    const response = await getRequestFn();
    return response.data.map((i) => {
      if ('key' in i) {
        return { id: i.key, name: i.name };
      }
      return i;
    });
  }
);
