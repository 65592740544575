import React from 'react';
import {
  Link as ReactLink,
  LinkProps as ReactLinkProps,
} from 'react-router-dom';
import classNames from 'classnames';
import { css } from '@emotion/css';
import { Colors } from '../../../types/general';

interface LinkProps extends ReactLinkProps {
  color?: Colors;
  underline?: boolean;
}

const Link: React.FC<LinkProps> = (props) => {
  const {
    color = 'primary',
    underline = false,
    className,
    children,
    ...reactLinkProps
  } = props;
  const styles = css`
    color: var(--${color}-main);
    text-decoration: ${underline ? 'underline' : 'none'};
    &:hover {
      color: var(--${color}-light);
      text-decoration: ${underline ? 'none' : 'none'};
    }
  `;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ReactLink className={classNames(className, styles)} {...reactLinkProps}>
      {children}
    </ReactLink>
  );
};

export default Link;
