import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../components/elements/Loader';
import PrivateRoute from '../../components/elements/PrivateRoute';
import ChatTemplate from '../../components/templates/Chat';
import ErrorTemplate from '../../components/templates/Error';
import { MESSAGES } from '../../constants/routes';
import { useDispatch, useSelector } from '../../libs/redux';
import useLayoutTitle from '../../hooks/useLayoutTitle';
import useWindowSize from '../../hooks/useWindowSize';
import { requestConversations } from '../../repositories/chat';
import { resetCurrentChat, setCurrentChat } from '../../store/actions/chat';
import { selectChat, selectChatById } from '../../store/reducers/chats';
import { selectCurrentChat } from '../../store/reducers/current-chat';
import { selectWebSocket } from '../../store/reducers/web-socket';
import { breakpoints } from '../../styles/breakpoints';
import { WebSocketStatus } from '../../types/general';

const MessagesPage: React.FC = () => {
  const { list, loading } = useSelector(selectChat);
  const { id: chatId } = useSelector(selectCurrentChat);
  const currentChat = useSelector((state) => selectChatById(state, chatId));
  const { width } = useWindowSize();
  const { status } = useSelector(selectWebSocket);
  const dispatch = useDispatch();
  const { id } = useParams();

  const parsedId = parseInt(id || '', 10);

  useLayoutTitle(
    !parsedId || (width && width > breakpoints.md) ? MESSAGES.name : ''
  );

  useEffect(
    () => () => {
      dispatch(resetCurrentChat());
    },
    []
  );

  useEffect(() => {
    if (parsedId && !loading && status === WebSocketStatus.connected) {
      const foundChat = list.find((chat) => chat.details.userId === parsedId);
      if (
        (foundChat && !currentChat) ||
        (foundChat && currentChat && foundChat.id !== currentChat.id)
      ) {
        dispatch(setCurrentChat(foundChat.id));
      }

      if (
        !foundChat &&
        (!currentChat || currentChat.details.userId !== parsedId)
      ) {
        requestConversations({ page: 1, userExternalId: parsedId }).then(
          (response) => {
            const result = {
              ...response.data[0],
              details: {
                type: 'single',
                userId: response.data[0].recipients[0].id,
                name: response.data[0].recipients[0].userFullName,
                photo: response.data[0].recipients[0].photoSmallUrl,
              },
            };
            if (result.details.userId === parsedId) {
              dispatch(setCurrentChat(result as any));
            }
          }
        );
      }
    }
    if (!id && currentChat) {
      dispatch(resetCurrentChat());
    }
  }, [parsedId, list]);

  return (
    <PrivateRoute access={MESSAGES.access}>
      {status === WebSocketStatus.connected && (
        <ChatTemplate activeChat={Boolean(parsedId)} />
      )}
      {status === WebSocketStatus.pending && <Loader />}
      {(status === WebSocketStatus.disconnected ||
        status === WebSocketStatus.error) && (
        <ErrorTemplate
          title="Something went wrong."
          message="No connection to the server. Try reloading the page."
        />
      )}
    </PrivateRoute>
  );
};

export default MessagesPage;
