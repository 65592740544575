import classNames from 'classnames';
import React from 'react';
import classes from './Checkbox.module.scss';

interface IProps {
  label?: React.ReactNode;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef?: React.Ref<HTMLInputElement>;
  checked?: boolean;
  error?: boolean;
}

const Checkbox: React.FC<IProps> = (props) => {
  const { label, name, onChange, inputRef, checked, error } = props;
  return (
    <label className={classes.checkbox__label}>
      <input
        type="checkbox"
        checked={checked}
        name={name}
        onChange={onChange}
        ref={inputRef}
      />
      <span
        className={classNames(classes.customCheckbox, {
          [classes.error]: error,
        })}
      />
      {label && <div className={classes.label}>{label}</div>}
    </label>
  );
};

export default Checkbox;
