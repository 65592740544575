import { useGoogleLogin } from '@react-oauth/google';
import React from 'react';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { ReactComponent as Facebook } from '../../../assets/img/social-icons/facebook.svg';
import { ReactComponent as Google } from '../../../assets/img/social-icons/google.svg';
import { ReactComponent as Linkedin } from '../../../assets/img/social-icons/linkedin.svg';
import { useDispatch } from '../../../libs/redux';
import { useFacebookLogin } from '../../../hooks/useFacebookLogin';
import { socialAuth } from '../../../store/actions/authentication';
import classes from './SocialAuth.module.scss';

const SocialAuth: React.FC = () => {
  const dispatch = useDispatch();
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID || '',
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (r) => {
      dispatch(
        socialAuth({
          provider: 'linkedin',
          token: r,
        })
      );
    },
    onError: (error) => {
      console.log(error);
    },
    scope: 'r_liteprofile r_emailaddress',
  });

  const fbLogin = useFacebookLogin({
    appId: process.env.REACT_APP_FACEBOOK_APP_ID || '',
    onSuccess: (r) =>
      dispatch(
        socialAuth({
          provider: 'facebook',
          token: r.authState.accessToken,
        })
      ),
    onError: (r) => console.log(r),
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (r) =>
      dispatch(
        socialAuth({
          provider: 'google',
          token: r.access_token,
        })
      ),
    scope: 'email',
  });

  return (
    <div className={classes.socialWrap}>
      <button
        className={classes.item}
        type="button"
        style={{ color: '#4285f4' }}
        onClick={() => googleLogin()}
      >
        <Google />
      </button>
      <button
        className={classes.item}
        type="button"
        style={{ color: '#4267b2' }}
        onClick={() => fbLogin()}
      >
        <Facebook />
      </button>
      <button
        className={classes.item}
        type="button"
        style={{ color: '#0077b5' }}
        onClick={() => linkedInLogin()}
      >
        <Linkedin />
      </button>
    </div>
  );
};

export default SocialAuth;
