import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import '../../../styles/style.scss';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '../../../store';
import ScrollToTop from '../../elements/ScrollToTop';
import App from '../App';

const Root: React.FC = () => (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
    <BrowserRouter>
      <ScrollToTop />
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </GoogleOAuthProvider>
);

export default Root;
