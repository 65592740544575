import { createReducer } from '@reduxjs/toolkit';
import { ISettings } from '../../types/user';
import { clearAuthData } from '../actions/authentication';
import { getSettings, editSettings } from '../actions/user-settings';

interface State {
  list: ISettings;
  errors: Partial<Record<keyof ISettings, string>>;
  loading: boolean;
  loaded: boolean;
}

const initialState: State = {
  list: {
    scale_search: 1,
    user_available_occupations: [],
  },
  errors: {},
  loaded: false,
  loading: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(getSettings.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getSettings.fulfilled, (state, action) => {
    state.loading = false;
    state.loaded = true;
    state.list = action.payload;
  });
  builder.addCase(getSettings.rejected, (state) => {
    state.loading = false;
    state.loaded = true;
  });

  builder.addCase(editSettings.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(editSettings.fulfilled, (state, action) => {
    state.loading = false;
    state.list = action.payload;
  });
  builder.addCase(editSettings.rejected, (state, action) => {
    state.loading = false;
    if (action.payload) {
      state.errors = action.payload;
    }
  });
  builder.addCase(clearAuthData, () => initialState);
});

interface Store {
  userSettings: State;
}

export const selectUserSettings = (state: Store) => state.userSettings;

export default reducer;
