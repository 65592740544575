import { useEffect, useState } from 'react';
import { IPagination } from '../types/general';

interface IParams<T> {
  elementsPerPage: number;
  serverPagination: IPagination;
  onRequest: (page: number) => void;
  list: T[];
  scrollTopOnPAgeChange?: boolean;
}

const usePagination = <T>(params: IParams<T>) => {
  const [currentPage, setCurrentPage] = useState(1);
  const {
    elementsPerPage,
    serverPagination,
    onRequest,
    list,
    scrollTopOnPAgeChange,
  } = params;
  const totalPages = Math.ceil(serverPagination.total / elementsPerPage);

  const visibleRange = {
    start: currentPage * elementsPerPage - elementsPerPage,
    end: currentPage * elementsPerPage - 1,
  };

  const visibleList = list.slice(visibleRange.start, visibleRange.end + 1);

  const lastItemIndex =
    serverPagination.total - 1 < visibleRange.end
      ? serverPagination.total - 1
      : visibleRange.end;

  useEffect(() => {
    if (!list.length) {
      onRequest(currentPage);
    }
  }, []);

  useEffect(() => {
    if (list.length && !list[lastItemIndex]) {
      onRequest(
        Math.ceil((currentPage * elementsPerPage) / serverPagination.per_page)
      );
    }
  }, [currentPage, elementsPerPage, list.length, serverPagination]);

  useEffect(() => {
    if (scrollTopOnPAgeChange) {
      document.documentElement.scrollTo({
        top: 1,
        left: 0,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage > totalPages && totalPages) {
      setCurrentPage(totalPages);
    }
  }, [list.length, currentPage, totalPages]);

  return { currentPage, setCurrentPage, visibleList, totalPages };
};

export default usePagination;
