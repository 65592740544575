import React from 'react';
import classNames from 'classnames';
import ReactSelect, {
  CSSObjectWithLabel,
  GroupBase,
  Props,
} from 'react-select';
import InputBase, { InputBaseProps } from '../InputBase';
import classes from './Select.module.scss';

export interface SelectProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends Props<Option, IsMulti, Group>,
    InputBaseProps {
  menuPortal?: HTMLElement | null | 'body';
}

const Select = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: SelectProps<Option, IsMulti, Group>
) => {
  const {
    options,
    isMulti,
    label,
    helperText,
    error,
    fullWidth,
    placeholder,
    value,
    onChange,
    isDisabled,
    menuPortal,
    onInputChange,
    isLoading,
    isSearchable,
    variant,
    noOptionsMessage,
  } = props;

  const styles = {
    menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 20 }),
  };

  return (
    <InputBase
      label={label}
      helperText={helperText}
      error={error}
      fullWidth={fullWidth}
      variant={variant}
    >
      <ReactSelect
        className={classNames(classes.select, {
          [classes[variant || '']]: variant,
        })}
        classNamePrefix="reactSelect"
        options={options}
        isMulti={isMulti}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onInputChange={onInputChange}
        isLoading={isLoading}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        menuPortalTarget={menuPortal === 'body' ? document.body : menuPortal}
        styles={styles}
        noOptionsMessage={noOptionsMessage}
      />
    </InputBase>
  );
};

export default Select;
