import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import {
  dynamicContentToOption,
  dynamicContentToOptions,
  optionToDynamicContent,
} from '../../../helpers/dynamic-content';
import { useDispatch, useSelector } from '../../../libs/redux';
import useDynamicContent from '../../../hooks/useDynamicContent';
import { search, setSearchFields } from '../../../store/actions/search';
import { selectSearch } from '../../../store/reducers/search';
import { ISearchFields } from '../../../types/search';
import Button from '../../elements/Button';
import FormGroup from '../../elements/FormGroup';
import Loader from '../../elements/Loader';
import Select from '../../elements/Select';
import classes from './SearchForm.module.scss';
import TextField from '../../elements/TextField';
import { selectUser } from '../../../store/reducers/user';
import { ISubscription } from '../../../types/subscriptions';

const SearchForm: React.FC = () => {
  const [searchInput, setSearch] = useState({ topicTags: '' });
  const { occupations, interaction, languages, scaleSearch, topicTags } =
    useDynamicContent([
      'occupations',
      'interaction',
      'languages',
      'interests',
      'scaleSearch',
      { key: 'topicTags', search: searchInput.topicTags },
    ]);
  const {
    loading,
    fields: defaultValues,
    pagination,
    result,
  } = useSelector(selectSearch);
  const dispatch = useDispatch();
  const { user } = useSelector(selectUser);

  const { register, handleSubmit, reset, control } = useForm<ISearchFields>({
    defaultValues,
  });
  const fields = {
    looking_occupation: register('looking_occupation'),
    topic_tags: register('topic_tags'),
    interaction_types: register('interaction_types'),
    scale_search: register('scale_search'),
    interests: register('interests'),
    languages: register('languages'),
    total_points: register('total_points', {
      setValueAs: (value) => parseInt(value, 10) || 0,
    }),
    successful_communication: register('successful_communication', {
      setValueAs: (value) => parseInt(value, 10) || 0,
    }),
    ko: register('ko', {
      setValueAs: (value) => parseInt(value, 10) || 0,
    }),
  };

  const onSubmit = (data: ISearchFields) => {
    dispatch(setSearchFields(data));
    dispatch(search(data));
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (
      pagination.current_page !== 1 &&
      !result[
        pagination.current_page * pagination.per_page - pagination.per_page
      ]
    ) {
      dispatch(search({ ...defaultValues, page: pagination.current_page }));
    }
  }, [pagination.current_page]);

  const isSearchByRatingAllowed = Boolean(
    user &&
      user.subscription_user &&
      user.subscription_user.subscription === ISubscription.greatMind
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.wrap}>
      <Loader position="absolute" open={loading} />
      <FormGroup>
        <Controller
          control={control}
          name={fields.looking_occupation.name}
          render={(field) => (
            <Select
              fullWidth
              label="What are you looking for?"
              options={dynamicContentToOptions(occupations.list)}
              value={
                field.field.value && field.field.value.length
                  ? dynamicContentToOption(field.field.value[0])
                  : null
              }
              onChange={(v) =>
                field.field.onChange(v ? [optionToDynamicContent(v)] : null)
              }
              error={Boolean(field.fieldState.error)}
              helperText={field.fieldState.error?.message}
            />
          )}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          control={control}
          name={fields.topic_tags.name}
          render={(field) => (
            <Select
              isMulti
              fullWidth
              label="Topics to discuss"
              isLoading={topicTags.loading}
              onInputChange={(v) => setSearch({ ...searchInput, topicTags: v })}
              options={dynamicContentToOptions(topicTags.list)}
              value={
                field.field.value
                  ? field.field.value.map((i) => dynamicContentToOption(i))
                  : null
              }
              onChange={(v) =>
                field.field.onChange(
                  v ? v.map((i) => optionToDynamicContent(i)) : null
                )
              }
              error={Boolean(field.fieldState.error)}
              helperText={field.fieldState.error?.message}
            />
          )}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          control={control}
          name={fields.interaction_types.name}
          render={(field) => (
            <Select
              isMulti
              fullWidth
              label="Interaction type"
              options={dynamicContentToOptions(interaction.list)}
              value={
                field.field.value
                  ? field.field.value.map((i) => dynamicContentToOption(i))
                  : null
              }
              onChange={(v) =>
                field.field.onChange(
                  v ? v.map((i) => optionToDynamicContent(i)) : null
                )
              }
              error={Boolean(field.fieldState.error)}
              helperText={field.fieldState.error?.message}
            />
          )}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          control={control}
          name={fields.languages.name}
          render={(field) => (
            <Select
              fullWidth
              isMulti
              label="Language to communicate"
              options={dynamicContentToOptions(languages.list)}
              value={
                field.field.value
                  ? field.field.value.map((i) => dynamicContentToOption(i))
                  : null
              }
              onChange={(v) =>
                field.field.onChange(
                  v ? v.map((i) => optionToDynamicContent(i)) : null
                )
              }
              error={Boolean(field.fieldState.error)}
              helperText={field.fieldState.error?.message}
            />
          )}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          control={control}
          name={fields.scale_search.name}
          render={(field) => (
            <Select
              fullWidth
              label="Scale of search"
              options={dynamicContentToOptions(scaleSearch.list)}
              value={
                field.field.value && field.field.value.length
                  ? dynamicContentToOption(field.field.value[0])
                  : null
              }
              onChange={(v) =>
                field.field.onChange(v ? [optionToDynamicContent(v)] : null)
              }
              error={Boolean(field.fieldState.error)}
              helperText={field.fieldState.error?.message}
            />
          )}
        />
      </FormGroup>
      <div className={classes.ratingTitle}>
        <h5>Rating</h5>
        {!isSearchByRatingAllowed && (
          <Button size="xs" variant="contained" to={{ hash: 'subscription' }}>
            UPGRADE TO SEARCH BY
          </Button>
        )}
      </div>
      <div className={classes.row}>
        <div
          className={classNames(classes.label, {
            [classes.disabled]: !isSearchByRatingAllowed,
          })}
        >
          Total point <br /> more than
        </div>
        <div className={classes.inputWrap}>
          <TextField
            fullWidth
            placeholder="0"
            inputRef={fields.total_points.ref}
            name={fields.total_points.name}
            onChange={fields.total_points.onChange}
            onBlur={fields.total_points.onBlur}
            disabled={!isSearchByRatingAllowed}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div
          className={classNames(classes.label, {
            [classes.disabled]: !isSearchByRatingAllowed,
          })}
        >
          Successful communication <br /> more than
        </div>
        <div className={classes.inputWrap}>
          <TextField
            fullWidth
            placeholder="0"
            inputRef={fields.successful_communication.ref}
            name={fields.successful_communication.name}
            onChange={fields.successful_communication.onChange}
            onBlur={fields.successful_communication.onBlur}
            disabled={!isSearchByRatingAllowed}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div
          className={classNames(classes.label, {
            [classes.disabled]: !isSearchByRatingAllowed,
          })}
        >
          KO less <br /> than
        </div>
        <div className={classes.inputWrap}>
          <TextField
            fullWidth
            placeholder="0"
            inputRef={fields.ko.ref}
            name={fields.ko.name}
            onChange={fields.ko.onChange}
            onBlur={fields.ko.onBlur}
            disabled={!isSearchByRatingAllowed}
          />
        </div>
      </div>
      <Button fullWidth type="submit" className={classes.btn}>
        SEARCH
      </Button>
    </form>
  );
};

export default SearchForm;
