import classNames from 'classnames';
import React, { useState } from 'react';
import classes from './Accordion.module.scss';

interface IProps {
  title: string;
  content: string;
}

const Accordion: React.FC<IProps> = (props) => {
  const { title, content } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={classNames(classes.accordion, { [classes.open]: isOpen })}
      data-testid="accordion"
    >
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={classes.title}
      >
        {title}
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M18.5437 5.10317C18.2117 4.77265 17.7623 4.5871 17.2937 4.5871C16.8252 4.5871 16.3758 4.77265 16.0437 5.10317L9.99999 11.1407L3.95937 5.10317C3.7973 4.92992 3.60204 4.79104 3.38519 4.6948C3.16835 4.59856 2.93437 4.54693 2.69716 4.54297C2.45996 4.53902 2.22438 4.58282 2.00445 4.67178C1.78452 4.76073 1.58473 4.89303 1.41698 5.06078C1.24923 5.22853 1.11694 5.42832 1.02798 5.64825C0.939021 5.86818 0.895217 6.10375 0.899172 6.34096C0.903126 6.57817 0.95476 6.81215 1.051 7.02899C1.14724 7.24583 1.28612 7.4411 1.45937 7.60317L8.74999 14.8969C9.08205 15.2274 9.53149 15.413 9.99999 15.413C10.4685 15.413 10.9179 15.2274 11.25 14.8969L18.5437 7.60629C18.8752 7.27411 19.0614 6.824 19.0614 6.35473C19.0614 5.88546 18.8752 5.43535 18.5437 5.10317Z" />
        </svg>
      </button>
      <div className={classes.content}>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default Accordion;
