import { createReducer } from '@reduxjs/toolkit';
import { IChatMedia, IChatPagination, IMessage } from '../../types/chat';
import { clearAuthData } from '../actions/authentication';
import {
  getMessage,
  getMessages,
  resetCurrentChat,
  setChatMedia,
  setCurrentChat,
  setMessagesStatus,
} from '../actions/chat';

interface IReducerMedia extends Omit<IChatMedia, 'id'> {
  id: string | null;
}

interface State {
  id: string | null;
  messages: {
    list: IMessage[];
    pagination: IChatPagination | null;
    loading: boolean;
    error: string;
  };
  media: IReducerMedia;
}

const emptuMessages = {
  list: [],
  pagination: null,
  loading: false,
  error: '',
};

const emptyMedia: IReducerMedia = {
  id: null,
  src: '',
  viewer: null,
};

const initialState: State = {
  id: null,
  messages: emptuMessages,
  media: emptyMedia,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(getMessages.pending, (state) => {
    state.messages.loading = true;
  });
  builder.addCase(getMessages.fulfilled, (state, action) => {
    state.messages.list.splice(
      (action.payload.meta.currentPage || 1) * action.payload.meta.perPage -
        action.payload.meta.perPage,
      action.payload.meta.perPage,
      ...action.payload.data
    );
    state.messages.loading = false;

    state.messages.pagination = action.payload.meta;
  });
  builder.addCase(getMessages.rejected, (state) => {
    state.messages.loading = false;
  });

  builder.addCase(setMessagesStatus, (state, action) => {
    state.messages = {
      ...state.messages,
      ...action.payload,
    };
  });

  builder.addCase(getMessage, (state, action) => {
    if (state.id && action.payload.conversationId === state.id) {
      state.messages.list.unshift(action.payload);
    }
  });
  builder.addCase(setCurrentChat, (state, action) => {
    state.id = action.payload;
    state.messages = emptuMessages;
  });
  builder.addCase(setChatMedia, (state, action) => {
    state.media = action.payload
      ? { viewer: null, ...action.payload }
      : emptyMedia;
  });

  builder.addCase(resetCurrentChat, () => initialState);
  builder.addCase(clearAuthData, () => initialState);
});

interface Store {
  currentChat: State;
}

export const selectCurrentChat = (state: Store) => state.currentChat;

export default reducer;
