import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  ERROR_FIELD_EMAIL_REQUIRED,
  ERROR_FIELD_EMAIL_VALIDATION,
} from '../../../constants/error-messages';
import { EMAIL } from '../../../constants/regexp';
import { SIGN_IN } from '../../../constants/routes';
import Button from '../../elements/Button';
import FormGroup from '../../elements/FormGroup';
import TextField from '../../elements/TextField';
import classes from './ResetPasswordForm.module.scss';

interface IProps {
  errors: { email?: string };
  onSubmit: (data: { email: string }) => void;
}

const ResetPasswordForm: React.FC<IProps> = (props) => {
  const { errors: err, onSubmit } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    email: string;
  }>();
  const fields = {
    email: register('email', {
      required: ERROR_FIELD_EMAIL_REQUIRED,
      pattern: { value: EMAIL, message: ERROR_FIELD_EMAIL_VALIDATION },
    }),
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <TextField
            fullWidth
            label="Email"
            name={fields.email.name}
            onChange={fields.email.onChange}
            inputRef={fields.email.ref}
            error={Boolean(errors.email || err.email)}
            helperText={errors.email?.message || err.email}
          />
        </FormGroup>

        <div className={classes.btnWrap}>
          <Button type="submit" variant="contained" shadow size="lg">
            SUBMIT
          </Button>
        </div>
      </form>
      <div className={classes.btnWrap}>
        <Link className="link" to={SIGN_IN.fullPath}>
          Back to Sign In
        </Link>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
