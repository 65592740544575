import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../elements/Button';
import NumberField from '../../elements/NumberField';
import TextField from '../../elements/TextField';
import { ReactComponent as ResetIcon } from '../../../assets/img/icon/reset.svg';
import classes from './RateForm.module.scss';
import Tooltip from '../../elements/Tooltip';
import { IRating } from '../../../types/user';
import { fieldNames } from '../../../constants/rating';

interface FormFields extends Omit<IRating, 'total' | 'user_id'> {
  feedback?: string;
}

interface RateFormProps {
  onSubmit: (data: FormFields) => void;
  onClose: () => void;
  maxValue: number;
  maxTotalCount: number;
  allowRating?: boolean;
}

const RateForm: React.FC<RateFormProps> = (props) => {
  const {
    onClose,
    onSubmit,
    maxTotalCount,
    maxValue,
    allowRating = true,
  } = props;
  const { control, handleSubmit, register, watch } = useForm<FormFields>();

  const fields = allowRating
    ? {
        vision: register('vision', { value: 0 }),
        strategy: register('strategy', { value: 0 }),
        technical: register('technical', { value: 0 }),
        problem_solving: register('problem_solving', { value: 0 }),
        communication: register('communication', { value: 0 }),
        listening: register('listening', { value: 0 }),
        empathy: register('empathy', { value: 0 }),
        creativity: register('creativity', { value: 0 }),
        inspirational: register('inspirational', { value: 0 }),
        humility: register('humility', { value: 0 }),
      }
    : {};
  const feedback = register('feedback');

  const total = watch(Object.values(fields).map((i) => i.name)).reduce(
    (acc, i) => acc + (i || 0),
    0
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {allowRating && (
        <div className={classes.criterions}>
          {Object.values(fields).map((field) => (
            <div className={classes.row} key={field.name}>
              <p className={classes.name}>{fieldNames[field.name]}:</p>

              <Controller
                control={control}
                name={field.name}
                render={({ field: { value, onChange } }) => (
                  <>
                    <div className={classes.counter}>
                      <NumberField
                        fulWidth
                        value={value}
                        onChange={onChange}
                        max={total < maxTotalCount ? maxValue : value}
                        min={0}
                      />
                    </div>
                    <div className={classes.btnBox}>
                      {value < 1 ? (
                        <Tooltip
                          text={`Assign ${maxValue} rating points for the category.`}
                        >
                          <Button
                            className={classes.btn}
                            size="xs"
                            variant="contained"
                            onClick={() =>
                              onChange(
                                maxTotalCount - total >= maxValue
                                  ? maxValue
                                  : maxTotalCount - total
                              )
                            }
                          >
                            {maxValue}
                          </Button>
                        </Tooltip>
                      ) : (
                        <Tooltip text="Delete a rating for a category.">
                          <Button
                            className={classes.btn}
                            size="xs"
                            variant="contained"
                            onClick={() => onChange(0)}
                          >
                            <ResetIcon />
                          </Button>
                        </Tooltip>
                      )}
                    </div>
                  </>
                )}
              />
            </div>
          ))}
        </div>
      )}

      <div className={classes.inputWrap}>
        <TextField
          fullWidth
          label="Write your feedback here"
          name={feedback.name}
          inputRef={feedback.ref}
          onChange={feedback.onChange}
          onBlur={feedback.onBlur}
        />
      </div>
      <div className={classes.btnWrap}>
        <Button color="default" onClick={onClose}>
          CLOSE
        </Button>
        <Button type="submit">SEND</Button>
      </div>
    </form>
  );
};

export default RateForm;
