import React from 'react';
import Container from '../../elements/Container';
import BaseLayout from '../../layouts/Base';
import CardWithLogo from '../../elements/CardWithLogo';
import AuthWrap from '../../modules/AuthWrap';
import { useDispatch, useSelector } from '../../../libs/redux';
import { signUp } from '../../../store/actions/authentication';
import { selectAuthentication } from '../../../store/reducers/authentication';
import SignUpForm from '../../modules/SignUpForm';

const SignUpTemplate: React.FC = () => {
  const { loading, errors } = useSelector(selectAuthentication);
  const dispatch = useDispatch();
  return (
    <BaseLayout>
      <Container size="sm">
        <CardWithLogo>
          <AuthWrap loading={loading}>
            <SignUpForm errors={errors} onSubmit={(d) => dispatch(signUp(d))} />
          </AuthWrap>
        </CardWithLogo>
      </Container>
    </BaseLayout>
  );
};

export default SignUpTemplate;
