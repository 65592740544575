import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import routes from '../../../constants/routes';

interface IProps {
  title?: string;
}

const PageTitle: React.FC<IProps> = (props) => {
  const { title } = props;
  const { pathname } = useLocation();

  const getTitle = () => {
    const separator = ' | ';
    if (title) {
      return title + separator;
    }
    const route = routes.find((r) => r.fullPath === pathname);
    return route ? route.name + separator : '';
  };

  return (
    <Helmet>
      <title>{`${getTitle()} ${process.env.REACT_APP_SITE_NAME || ''}`}</title>
    </Helmet>
  );
};

export default PageTitle;
