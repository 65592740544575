import React, { useEffect } from 'react';
import PrivateRoute from '../../components/elements/PrivateRoute';
import TopicsTemplate from '../../components/templates/Topics';
import { STORE_TOPICS, TOPICS } from '../../constants/routes';
import { useDispatch, useSelector } from '../../libs/redux';
import useLayoutTitle from '../../hooks/useLayoutTitle';
import { getTopics } from '../../store/actions/topics';
import { selectTopics } from '../../store/reducers/topics';

const TopicsPage: React.FC = () => {
  const { pagination, list, loading, sort } = useSelector(selectTopics);
  useLayoutTitle({
    title: TOPICS.name,
    button: {
      text: 'CREATE NEW TOPIC',
      to: STORE_TOPICS.fullPath,
    },
    fullpath: TOPICS.fullPath,
  });
  const dispatch = useDispatch();

  const requestTopics = (params: { page: number }) => {
    dispatch(
      getTopics({
        page: params.page,
        sort,
      })
    );
  };

  useEffect(() => {
    if (!loading) {
      requestTopics({ page: 1 });
    }
  }, [sort]);
  useEffect(() => {
    if (
      pagination.current_page !== 1 &&
      !list[pagination.current_page * pagination.per_page - pagination.per_page]
    ) {
      requestTopics({ page: pagination.current_page });
    }
  }, [pagination.current_page, sort]);

  return (
    <PrivateRoute access={TOPICS.access}>
      <TopicsTemplate />
    </PrivateRoute>
  );
};

export default TopicsPage;
