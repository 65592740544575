import React from 'react';
import classes from './HTMLParser.module.scss';

interface IProps {
  html: string;
}

const HTMLParser: React.FC<IProps> = (props) => {
  const { html } = props;
  return (
    <div className={classes.w} dangerouslySetInnerHTML={{ __html: html }} />
  );
};

export default HTMLParser;
