import React, { useState } from 'react';
import Button, { IProps as ButtonProps } from '../Button';

interface IProps extends Omit<ButtonProps, 'onChange'> {
  onChange: (files: FileList | null) => void;
  accept?: string;
  multiple?: boolean;
}

const SelectFileButton: React.FC<IProps> = (props) => {
  const [value, setValue] = useState<FileList | null>(null);
  const {
    children,
    size,
    color,
    variant,
    onChange,
    accept,
    multiple = false,
    fullWidth,
  } = props;
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.files);
    setValue(e.target.files);
  };
  return (
    <>
      <input
        type="file"
        key={JSON.stringify(value)}
        ref={inputFileRef}
        onChange={handleChange}
        style={{ width: 0, opacity: 0, visibility: 'hidden' }}
        accept={accept}
        multiple={multiple}
      />
      <Button
        size={size}
        color={color}
        variant={variant}
        onClick={handleClick}
        fullWidth={fullWidth}
      >
        {children}
      </Button>
    </>
  );
};

export default SelectFileButton;
