import React from 'react';
import { ReactComponent as DefaultImg } from '../../../assets/img/icon/user.svg';
import classes from './Avatar.module.scss';

interface IProps {
  src?: string | null;
  size?: number;
  alt?: string;
  fullWidth?: boolean;
}

const Avatar: React.FC<IProps> = (props) => {
  const { src, size = 50, alt, fullWidth } = props;
  return (
    <div
      className={classes.wrap}
      style={{
        width: fullWidth ? '100%' : size,
        height: fullWidth ? 'auto' : size,
      }}
    >
      {src ? <img src={src} alt={alt} /> : <DefaultImg />}
    </div>
  );
};

export default Avatar;
