import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatch } from '../../../libs/redux';
import { deleteFastNotification } from '../../../store/actions/notifications';
import { Card } from '../../elements/Card';
import Transition from '../../elements/Transition';
import classes from './Notification.module.scss';
import { ReactComponent as Close } from '../../../assets/img/icon/close.svg';
import { INotification } from '../../../types/notifications';
import Avatar from '../../elements/Avatar';

interface IProps {
  notification: INotification;
  autoDelete?: boolean;
  autoDeleteTime?: number;
}

const Notification: React.FC<IProps> = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const {
    notification: { color, content, id, url },
    autoDelete = true,
    autoDeleteTime = 7500,
  } = props;
  const dispatch = useDispatch();

  const timeout = 300;

  const handleRemove = () => {
    if (!deleting) {
      setDeleting(true);
      setIsVisible(false);
      setTimeout(() => {
        dispatch(deleteFastNotification(id));
      }, timeout);
    }
  };

  useEffect(() => {
    setIsVisible(true);
    if (autoDelete) {
      setTimeout(() => {
        handleRemove();
      }, autoDeleteTime);
    }
  }, []);

  const colors = color
    ? css`
        background: var(--${color}-main);
        color: var(--${color}-contrastText);
      `
    : '';

  const notificationContent =
    typeof content === 'string' ? (
      content
    ) : (
      <div className={classes.fullNotification}>
        <div className={classes.avatar}>
          {content.image && <Avatar fullWidth src={content.image} />}
        </div>
        <div>
          {content.title && <h5>{content.title}</h5>}
          {content.text}
        </div>
      </div>
    );

  return (
    <Transition in={isVisible} timeout={timeout} mode="zoom">
      <Card noPadding className={classNames(classes.card, colors)}>
        <div className={classes.wrap}>
          {url ? (
            <Link className={classes.link} to={url}>
              {notificationContent}
            </Link>
          ) : (
            notificationContent
          )}
          <button
            type="button"
            className={classNames(classes.btn, colors)}
            onClick={handleRemove}
          >
            <Close />
          </button>
        </div>
      </Card>
    </Transition>
  );
};

export default Notification;
