export interface IChat {
  id: string;
  recipients: {
    id: number;
    photoSmallUrl: string | null;
    userFullName: string;
    sparring: ChatSparing | null;
    topicKnockOut: boolean;
    userRatingRated: boolean;
  }[];
  createdAt: number;
  messageLast: IMessage | null;
  messageUnreadCount: number;
}

export enum ChatSparringStatus {
  open = 1,
  finished,
  ko = 4,
}
export interface ChatSparing {
  id: number;
  round: number;
  status: ChatSparringStatus;
  statusName: string;
  sparringUsers: {
    complete: boolean;
    knockOut: boolean;
    userId: number;
  }[];
}

interface ChatDetails {
  photo: string | null;
  name: string;
  sparring: ChatSparing | null;
  topicKnockOut: boolean;
  userRatingRated: boolean;
}

interface SingleChat extends ChatDetails {
  type: 'single';
  userId: number;
}

interface GroupChat extends ChatDetails {
  type: 'group';
  userId: never;
}

export interface IChatWithDetails extends IChat {
  details: SingleChat | GroupChat;
}

export interface IMessageFile {
  id: string;
  createdAt: number;
  extension: string;
  mimeType: string;
  url: string;
  originalName: string;
  size: number;
}

export interface IMessage {
  id: string;
  senderExternalId: number;
  read: boolean;
  text: string;
  messageFiles: IMessageFile[];
  createdAt: number;
  conversationId: string;
}

export interface IChatPagination {
  total: number;
  currentPage: number;
  lastPage: number;
  perPage: number;
}

export interface IChatMedia {
  id: string;
  src: string;
  viewer: 'video' | 'image' | null;
}
