import React, { useEffect, useRef } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/elements/Loader';
import PrivateRoute from '../../components/elements/PrivateRoute';
import StoreExperienceTemplate from '../../components/templates/StoreExperience';
import { PROFILE, STORE_EXPERIENCE } from '../../constants/routes';
import { useDispatch, useSelector } from '../../libs/redux';
import useLayoutTitle from '../../hooks/useLayoutTitle';
import {
  addExperience,
  editExperience,
  getExperience,
} from '../../store/actions/user-experience';
import { selectUserExperience } from '../../store/reducers/user-experience';
import { Access } from '../../types/general';
import { IExperience } from '../../types/user';

const StoreExperiencePage: React.FC = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { list, loaded, loading, errors } = useSelector(selectUserExperience);
  const id = parseInt(slug || '', 10);
  const navigate = useNavigate();
  const storing = useRef(false);
  useLayoutTitle(STORE_EXPERIENCE.name);

  useEffect(() => {
    if (!loaded && !loading) {
      dispatch(getExperience());
    }
  }, []);

  useEffect(() => {
    if (storing.current) {
      navigate(PROFILE.fullPath);
    }
  }, [list]);

  if (!slug || (Number.isNaN(id) && slug !== 'add')) {
    return <Navigate to="add" />;
  }

  const experience = list.find((item) => item.id === id);

  const handleStore = (data: Omit<IExperience, 'id'>) => {
    storing.current = true;
    if (slug === 'add') {
      dispatch(addExperience(data));
    } else {
      dispatch(editExperience({ ...data, id }));
    }
  };

  const handleCancel = () => {
    navigate(PROFILE.fullPath);
  };

  return (
    <PrivateRoute access={Access.active}>
      {!loaded && loading && !Number.isNaN(id) ? (
        <Loader />
      ) : (
        <StoreExperienceTemplate
          experience={experience}
          onStore={handleStore}
          onCancel={handleCancel}
          errors={errors}
          loading={loading}
        />
      )}
    </PrivateRoute>
  );
};

export default StoreExperiencePage;
