import { useEffect } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { ILayoutTitle, InnerLayoutCantext } from '../types/layouts';

const useLayoutTitle = (newTitle: ILayoutTitle | string) => {
  const [title, setTitle] = useOutletContext<InnerLayoutCantext>();
  const { pathname } = useLocation();

  const handledTitle =
    typeof newTitle === 'string'
      ? { title: newTitle, fullpath: pathname }
      : newTitle;

  useEffect(() => {
    if (JSON.stringify(title) !== JSON.stringify(handledTitle)) {
      setTitle(handledTitle);
    }
  }, [title, handledTitle, pathname]);

  return null;
};

export default useLayoutTitle;
