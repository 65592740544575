export enum ResetPassworState {
  resetPassword,
  resetEmailSent,
  changePassword,
  passwordChanged,
}

export interface ISignUpFields {
  email: string;
  name: string;
  password: string;
  confirmPassword: string;
  terms: boolean;
}

export interface ISession {
  access_token: string;
  refresh_token: string;
  expired_in: number;
}

export type IAuthProvider = 'facebook' | 'google' | 'linkedin';
