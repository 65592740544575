// the breakpoints must be the same as the ./_breakpoints.sass file

import { Sizes } from '../types/general';

export const breakpoints: Record<Sizes, number> = {
  xs: 0,
  sm: 526,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1430,
};

export const getBreakpoint = (
  name: keyof typeof breakpoints,
  direction: 'up' | 'down' = 'down'
) => {
  if (direction === 'down') {
    return `@media (max-width: ${breakpoints[name] - 1}px)`;
  }
  return `@media (min-width: ${breakpoints[name]}px)`;
};
