import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from '../../../libs/redux';
import { selectDynamicPages } from '../../../store/reducers/dynamic-pages';
import { ReactComponent as LogoIcon } from '../../../assets/img/logo-ivert.svg';
import { getMenu } from '../../../store/actions/dynamic-pages';
import { TEXT_PAGE } from '../../../constants/routes';
import classes from './Footer.module.scss';
import SocialNetworks from '../SocialNetworks';
import Container from '../../elements/Container';

const Footer: React.FC = () => {
  const { menu } = useSelector(selectDynamicPages);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!menu.loaded && !menu.loading) {
      dispatch(getMenu());
    }
  }, []);

  const footerMenu = menu.list.find((m) => m.name === 'Footer');

  return (
    <footer className={classes.footer}>
      <Container>
        <div className={classes.footer__wrap}>
          <div className={classes.link__box}>
            <div className={classes.logo}>
              <LogoIcon />
            </div>
            <div className={classes.social}>
              <SocialNetworks color="white" />
            </div>
          </div>
          <div className={classes.foot}>
            <p>© 2022 Sparring Partners. All Rights Reserved.</p>
            <div className={classes.menu}>
              {footerMenu &&
                footerMenu.menu_items.map((item) => (
                  <Link
                    to={`${TEXT_PAGE.fullPath}/${item.page_slug}`}
                    key={item.page_slug}
                  >
                    {item.name}
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
