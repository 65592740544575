import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { handleError422 } from '../../helpers';
import {
  register,
  login,
  loginSocial,
  resetPassword as resetPwd,
  changePassword as changePwd,
} from '../../repositories/user';
import {
  IAuthProvider,
  ISession,
  ISignUpFields,
  ResetPassworState,
} from '../../types/authentication';
import { IError422 } from '../../types/errors';
import { Session } from '../../utils/session';
import { setUser } from './user';

const sessionInstance = Session.getInstance();

export const clearAuthData = createAction('clearAuthData');

export const setAuthErrors =
  createAction<Partial<Record<string, string>>>('setAuthError');

export const setSession = createAsyncThunk(
  'setSession',
  (session: ISession | null, { dispatch }) => {
    if (session === null) {
      dispatch(clearAuthData());
    }
    return session;
  }
);

export const toggleResetState =
  createAction<ResetPassworState>('toggleResetState');

export const signUp = createAsyncThunk(
  'signUp',
  async (data: ISignUpFields, { dispatch }) => {
    try {
      const { name, confirmPassword, ...rest } = data;
      const response = await register({
        ...rest,
        password_confirmation: confirmPassword,
        first_name: name.split(' ')[0],
        last_name: name.split(' ')[1],
      });
      dispatch(setUser(response.data));
      sessionInstance.setSession(response.data.token);
      return response;
    } catch (error) {
      const err = error as IError422;
      if (err.errors) {
        dispatch(setAuthErrors(handleError422(err)));
      }
      return error;
    }
  }
);

export const signIn = createAsyncThunk(
  'signIn',
  async (data: { email: string; password: string }, { dispatch }) => {
    try {
      const response = await login(data);
      dispatch(setUser(response.data));
      sessionInstance.setSession(response.data.token);
      return response;
    } catch (error) {
      const err = error as IError422;
      if (err.errors) {
        dispatch(setAuthErrors(handleError422(err)));
      }
      return error;
    }
  }
);

export const socialAuth = createAsyncThunk(
  'signIn',
  async (data: { token: string; provider: IAuthProvider }, { dispatch }) => {
    const response = await loginSocial(data);
    dispatch(setUser(response.data));
    sessionInstance.setSession(response.data.token);
    return response;
  }
);

export const resetPassword = createAsyncThunk(
  'resetPassword',
  async (data: { email: string }, { dispatch, rejectWithValue }) => {
    try {
      const response = await resetPwd(data.email);
      return response;
    } catch (error) {
      const err = error as IError422;
      if (err.errors) {
        dispatch(setAuthErrors(handleError422(err)));
      }
      return rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  'changePassword',
  async (
    data: {
      password: string;
      confirmPassword: string;
      email: string;
      token: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await changePwd({
        ...data,
        password_confirmation: data.confirmPassword,
      });
      return response;
    } catch (error) {
      dispatch(
        setAuthErrors({
          email:
            'Sorry, your reset password link is not longer valid. You can request another one.',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const logOut = createAsyncThunk('logOut', () => {
  sessionInstance.removeSession();
});
