import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AboutBacking from '../../../assets/img/about-backing.png';
import AboutFirstIcon from '../../../assets/img/about-screen-1.png';
import AboutSecondIcon from '../../../assets/img/about-screen-2.png';
import BannerPeople from '../../../assets/img/banner-people.png';
import backing from '../../../assets/img/why-backing.png';
import FirstIcon from '../../../assets/img/why-screen-1.png';
import SecondIcon from '../../../assets/img/why-screen-2.png';
import { SIGN_IN } from '../../../constants/routes';
import { useDispatch, useSelector } from '../../../libs/redux';
import { getHomePage } from '../../../store/actions/dynamic-pages';
import { selectDynamicPages } from '../../../store/reducers/dynamic-pages';
import Button from '../../elements/Button';
import Container from '../../elements/Container';
import HTMLParser from '../../elements/HTMLParser';
import Loader from '../../elements/Loader';
import ContactForm from '../../modules/ContactForm';
import FaqList from '../../modules/FaqList';
import SocialNetworks from '../../modules/SocialNetworks';
import classes from './Home.module.scss';

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const { home } = useSelector(selectDynamicPages);

  useEffect(() => {
    if (!home.loading && !home.content) {
      dispatch(getHomePage());
    }
  }, []);

  if (home.loading || !home.content) {
    return (
      <div className={classes.loader}>
        <Loader />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        {home.content.description && (
          <meta name="description" content={home.content.description} />
        )}
        {home.content.keywords && (
          <meta name="keywords" content={home.content.keywords} />
        )}
      </Helmet>
      <section className={classes.banner}>
        <Container>
          <div className={classes.container}>
            <div className={classes.text__box}>
              <h1>{home.content.claim_line}</h1>
              <div className={classes.content}>
                <HTMLParser html={home.content.claim_description} />
              </div>
              <Button
                variant="contained"
                size="lg"
                shadow
                minWidth={300}
                to={SIGN_IN.fullPath}
              >
                JOIN US
              </Button>
            </div>
            <img
              className={classes.banner__img}
              src={BannerPeople}
              alt="people"
            />
          </div>
        </Container>
      </section>
      <section id="why" className={classes.why}>
        <Container>
          <div className={classes.container}>
            <div className={classes.image}>
              <img className={classes.backing} src={backing} alt="backing" />
              <div className={classes.screenWrap}>
                <img
                  className={classes.screen}
                  src={FirstIcon}
                  alt="screen-1"
                />
                <img
                  className={classes.screen}
                  src={SecondIcon}
                  alt="screen-2"
                />
              </div>
            </div>
            <div className={classes.text__box}>
              <h2>{home.content.sparring_partners_title}</h2>
              <HTMLParser html={home.content.sparring_partners_description} />
            </div>
          </div>
        </Container>
      </section>
      <section id="about" className={classes.about}>
        <Container>
          <div className={classes.container}>
            <div className={classes.text__box}>
              <h2>{home.content.about_title}</h2>
              <HTMLParser html={home.content.about_description} />
            </div>
            <div className={classes.image}>
              <img
                className={classes.backing}
                src={AboutBacking}
                alt="backing"
              />
              <div className={classes.screenWrap}>
                <img
                  className={classes.screen}
                  src={AboutFirstIcon}
                  alt="screen-1"
                />

                <img
                  className={classes.screen}
                  src={AboutSecondIcon}
                  alt="screen-2"
                />
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section id="social" className={classes.social}>
        <Container>
          <div className={classes.links}>
            <SocialNetworks />
          </div>
        </Container>
      </section>

      <section id="faq" className={classes.faq}>
        <Container>
          <h2>Get answers to common questions about Sparring Partners</h2>
          <div className={classes.faq__content}>
            <FaqList />
          </div>
        </Container>
      </section>
      <section className={classes.callBack}>
        <Container>
          <h2>Ask Us Any Questions</h2>
          <ContactForm />
        </Container>
      </section>
    </>
  );
};

export default Home;
