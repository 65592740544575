import React from 'react';
import {
  IEducation,
  IExperience,
  IUser,
  IUserUpdatableFields,
} from '../../../types/user';
import classes from './Profile.module.scss';
import SearchSettingsForm from '../../modules/SearchSettingsForm';
import InnerPageCard from '../../elements/InnerPageCard';
import ProfileInfoCard from '../../modules/ProfileInfoCard';
import SparringRequestForm from '../../modules/SparringRequestForm';
import { ISparringRequest } from '../../../types/sparring';

interface IProps {
  user: IUser;
  education?: {
    list: IEducation[];
    onAdd?: () => void;
    onEdit?: (id: number) => void;
    onDelete?: (id: number) => void;
  };
  experience?: {
    list: IExperience[];
    onAdd?: () => void;
    onEdit?: (id: number) => void;
    onDelete?: (id: number) => void;
  };
  onUserEdit?: (user: IUserUpdatableFields) => void;
  onSparringRequest?: (data: ISparringRequest) => void;
  children?: React.ReactNode;
}

const ProfileTemplate: React.FC<IProps> = (props) => {
  const {
    user,
    onUserEdit,
    experience,
    education,
    onSparringRequest,
    children,
  } = props;

  const handleSparringRequest = (
    data: Omit<ISparringRequest, 'recipient_id'>
  ) => {
    if (onSparringRequest) {
      onSparringRequest({ ...data, recipient_id: user.id });
    }
  };

  return (
    <section className={classes.profile}>
      <div className={classes.profileInfo}>
        <InnerPageCard>
          <ProfileInfoCard
            user={user}
            onUserEdit={onUserEdit}
            experience={experience}
            education={education}
          />
          {children}
        </InnerPageCard>
      </div>
      {(onUserEdit || onSparringRequest) && (
        <div className={classes.settings} id="form">
          <InnerPageCard size="small">
            {onUserEdit && (
              <>
                <h4>Settings</h4>
                <SearchSettingsForm />
              </>
            )}
            {onSparringRequest && (
              <>
                <h4>Request Sparring</h4>
                <SparringRequestForm onSubmit={handleSparringRequest} />
              </>
            )}
          </InnerPageCard>
        </div>
      )}
    </section>
  );
};

export default ProfileTemplate;
