import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SIGN_IN, SIGN_UP } from '../../../constants/routes';
import Loader from '../../elements/Loader';
import Tabs from '../../elements/Tabs';
import SocialAuth from '../SocialAuth';
import classes from './AuthWrap.module.scss';

interface IProps {
  children?: React.ReactNode;
  loading?: boolean;
}

const AuthWrap: React.FC<IProps> = (props) => {
  const { children, loading } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const tabs = [
    { name: SIGN_IN.name, value: SIGN_IN.fullPath },
    { name: SIGN_UP.name, value: SIGN_UP.fullPath },
  ];

  return (
    <>
      <Loader position="fixed" open={loading} />
      <div className={classes.tabs}>
        <Tabs
          value={location.pathname}
          fullWidth
          onChange={(v) => navigate(v)}
          items={tabs}
        />
      </div>
      {children}
      <div className={classes.or}>OR</div>
      <SocialAuth />
    </>
  );
};

export default AuthWrap;
