import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { MEMBER, PROFILE } from '../../../constants/routes';
import useWindowSize from '../../../hooks/useWindowSize';
import { breakpoints } from '../../../styles/breakpoints';
import Avatar from '../../elements/Avatar';
import Button from '../../elements/Button';
import classes from './MemberCard.module.scss';
import Rating, { RatingProps } from '../Rating';

interface IProps {
  id: number;
  name: string;
  position?: string | null;
  avatar?: string | null;
  interests?: string | null;
  additionalButton?: {
    text: string;
    to?: string;
    onClick?: () => void;
  };
  isCurrentUser?: boolean;
  statistic?: Omit<RatingProps, 'name' | 'variant'>;
}

const MemberCard: React.FC<IProps> = (props) => {
  const {
    id,
    name,
    interests,
    avatar,
    position,
    additionalButton,
    isCurrentUser = false,
    statistic,
  } = props;
  const { width } = useWindowSize();
  const { pathname } = useLocation();

  const isMobile = width !== undefined && width < breakpoints.md;

  const memberPath = isCurrentUser
    ? PROFILE.fullPath
    : `${MEMBER.fullPath}/${id}`;

  return (
    <div className={classes.partner}>
      <div className={classes.main__info__wrap}>
        <Link
          to={memberPath}
          state={{ prevPathname: pathname }}
          className={classes.avatar}
        >
          <Avatar fullWidth src={avatar} />
        </Link>
        <div className={classes.main__info}>
          <Link
            to={memberPath}
            state={{ prevPathname: pathname }}
            className={classes.name}
          >
            {name}
          </Link>
          <div className={classes.profession}>{position}</div>
          {statistic && (
            <div className={classes.rating}>
              <Rating
                rating={statistic.rating}
                name={name}
                communications={statistic.communications}
                ko={statistic.ko}
                suggestUpdate={statistic.suggestUpdate}
              />
            </div>
          )}
        </div>
      </div>
      <div className={classes.description}>
        <div className={classes.details}>{interests}</div>
        {!isMobile && (
          <Button
            to={memberPath}
            state={{ prevPathname: pathname }}
            variant="contained"
            color="secondary"
            size="xs"
          >
            VIEW PROFILE
          </Button>
        )}
      </div>

      <div className={classes.btnWrap}>
        {isMobile && (
          <div className={classes.item}>
            <Button
              to={memberPath}
              state={{ prevPathname: pathname }}
              color="secondary"
            >
              VIEW PROFILE
            </Button>
          </div>
        )}

        <div className={classes.item}>
          {additionalButton && (
            <Button
              to={additionalButton.to}
              onClick={additionalButton.onClick}
              fullWidth
            >
              {additionalButton.text}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MemberCard;
