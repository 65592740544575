export enum Access {
  notAuthenticated,
  authenticated,
  onboarding,
  active,
  all,
}

export type Colors =
  | 'primary'
  | 'secondary'
  | 'default'
  | 'success'
  | 'warning'
  | 'error';

export type Sizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export interface ISettings {
  facebook_link: string;
  linkedin_link: string;
  youtube_link: string;
  instagram_link: string;
  user_rating_total: number;
  user_rating_category: number;
  sparring_round: number;
}

export interface IPagination {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
}

export interface IPaginationParams {
  page?: number;
}

export type SortDirection = 'asc' | 'desc';

export interface ISortOption<T extends Object> {
  direction: SortDirection;
  key: keyof T;
}

export enum WebSocketStatus {
  disconnected,
  pending,
  connected,
  suspended,
  error,
}
