import React from 'react';
import classNames from 'classnames';
import classes from './SocialNetworks.module.scss';
import { ReactComponent as Facebook } from '../../../assets/img/social-icons/facebook-square.svg';
import { ReactComponent as Linkedin } from '../../../assets/img/social-icons/linkedin-square.svg';
import { ReactComponent as Youtube } from '../../../assets/img/social-icons/youtube-square.svg';
import { ReactComponent as Instagram } from '../../../assets/img/social-icons/instagram-square.svg';
import { useSelector } from '../../../libs/redux';
import { selectSettings } from '../../../store/reducers/settings';

interface IProps {
  color?: 'primary' | 'white';
}

const SocialNetworks: React.FC<IProps> = (props) => {
  const { color = 'primary' } = props;
  const settings = useSelector(selectSettings);

  if (!settings.appSettings) {
    return null;
  }

  const items = [
    {
      name: 'facebook',
      link: settings.appSettings.facebook_link,
      icon: <Facebook />,
    },
    {
      name: 'linkedin',
      link: settings.appSettings.linkedin_link,
      icon: <Linkedin />,
    },
    {
      name: 'youtube',
      link: settings.appSettings.youtube_link,
      icon: <Youtube />,
    },
    {
      name: 'instagram',
      link: settings.appSettings.instagram_link,
      icon: <Instagram />,
    },
  ];
  return (
    <div className={classes.wrap} style={{ color }}>
      {items.map((item) => (
        <a
          key={item.name}
          href={item.link}
          className={classNames(classes.item, classes[color])}
          target="_blank"
          rel="noreferrer"
        >
          {item.icon}
        </a>
      ))}
    </div>
  );
};

export default SocialNetworks;
