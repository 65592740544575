import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/elements/Loader';
import PrivateRoute from '../../components/elements/PrivateRoute';
import ProfileTemplate from '../../components/templates/Profile';
import {
  PROFILE,
  STORE_EDUCATION,
  STORE_EXPERIENCE,
} from '../../constants/routes';
import { useDispatch, useSelector } from '../../libs/redux';
import useLayoutTitle from '../../hooks/useLayoutTitle';
import useWindowSize from '../../hooks/useWindowSize';
import { updateUserData } from '../../store/actions/user';
import {
  getEducation,
  deleteEducation,
} from '../../store/actions/user-education';
import {
  deleteExperience,
  getExperience,
} from '../../store/actions/user-experience';
import { selectUser } from '../../store/reducers/user';
import { selectUserEducation } from '../../store/reducers/user-education';
import { selectUserExperience } from '../../store/reducers/user-experience';
import { breakpoints } from '../../styles/breakpoints';

const ProfilePage: React.FC = () => {
  const { user } = useSelector(selectUser);
  const experience = useSelector(selectUserExperience);
  const education = useSelector(selectUserEducation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  useLayoutTitle(width && width > breakpoints.md ? PROFILE.name : '');

  useEffect(() => {
    if (!experience.loaded && !experience.loading) {
      dispatch(getExperience());
    }
    if (!education.loaded && !education.loading) {
      dispatch(getEducation());
    }
  }, []);

  return (
    <PrivateRoute access={PROFILE.access}>
      {!user ? (
        <Loader />
      ) : (
        <ProfileTemplate
          user={user}
          experience={{
            list: experience.list,
            onAdd() {
              navigate(STORE_EXPERIENCE.fullPath);
            },
            onDelete(id) {
              dispatch(deleteExperience(id));
            },
            onEdit(id) {
              navigate(`${STORE_EXPERIENCE.fullPath}/${id}`);
            },
          }}
          education={{
            list: education.list,
            onAdd() {
              navigate(STORE_EDUCATION.fullPath);
            },
            onDelete(id) {
              dispatch(deleteEducation(id));
            },
            onEdit(id) {
              navigate(`${STORE_EDUCATION.fullPath}/${id}`);
            },
          }}
          onUserEdit={(u) => dispatch(updateUserData(u))}
        />
      )}
    </PrivateRoute>
  );
};

export default ProfilePage;
