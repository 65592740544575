import React, { useState } from 'react';
import Button from '../../elements/Button';
import classes from './RoundStatus.module.scss';
import { ChatSparing, ChatSparringStatus } from '../../../types/chat';
import { useDispatch, useSelector } from '../../../libs/redux';
import {
  chatSparringKo,
  chatSparringComplateRound,
  chatSparringRate,
} from '../../../store/actions/chat';
import { selectUser } from '../../../store/reducers/user';
import Modal from '../../elements/Modal';
import { selectSettings } from '../../../store/reducers/settings';
import RateForm from '../RateForm';

interface RoundStatusProps {
  sparring: ChatSparing;
  recipient: { id: number; name: string };
  isKoPossible?: boolean;
  userRatingRated: boolean;
}

enum ModalState {
  close,
  rate,
  ko,
}

const RoundStatus: React.FC<RoundStatusProps> = (props) => {
  const { sparring, recipient, isKoPossible, userRatingRated } = props;
  const { user } = useSelector(selectUser);
  const dispatch = useDispatch();
  const [rateModalState, setRateModalState] = useState<ModalState>(
    ModalState.close
  );
  const { appSettings } = useSelector(selectSettings);

  const maxValue = appSettings ? appSettings.user_rating_category : 10;
  const maxTotalCount = appSettings ? appSettings.user_rating_total : 30;

  if (!user || sparring.status === ChatSparringStatus.ko) {
    return null;
  }

  const handleCompleteRound = () => {
    dispatch(chatSparringComplateRound(recipient.id));
  };

  const allowedNextRound = sparring.sparringUsers.find(
    (s) => s.userId === user.id && !s.complete
  );

  const getRoundSuffix = () => {
    if (sparring.status === ChatSparringStatus.finished) {
      return ' Rounds Complete';
    }
    return 'st Round';
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.val}>
        {sparring.round}
        {getRoundSuffix()}
      </div>
      <div className={classes.btnWrap}>
        {isKoPossible && sparring.status === ChatSparringStatus.open && (
          <Button
            size="xs"
            variant="contained"
            color="secondary"
            onClick={() => setRateModalState(ModalState.ko)}
          >
            KO
          </Button>
        )}
        {sparring.status === ChatSparringStatus.open && (
          <Button
            size="xs"
            variant="contained"
            onClick={handleCompleteRound}
            disabled={!allowedNextRound}
          >
            COMPLETE
          </Button>
        )}
        {sparring.status === ChatSparringStatus.finished && !userRatingRated && (
          <Button
            size="xs"
            variant="contained"
            onClick={() => setRateModalState(ModalState.rate)}
          >
            RATE PARTNER
          </Button>
        )}
      </div>
      {rateModalState !== ModalState.close && (
        <Modal
          size="md"
          fullWidth
          open
          onClose={() => setRateModalState(ModalState.close)}
        >
          <div className={classes.ratingWrap}>
            <h4 className={classes.title}>
              {rateModalState === ModalState.rate &&
                'Rate your communication with '}
              {rateModalState === ModalState.ko && 'Knocking Out partner: '}

              {recipient.name}
            </h4>
            <p className={classes.description}>
              {rateModalState === ModalState.rate &&
                `You can use ${maxTotalCount} points to evaluate your communication
                with this person. You can spend not more than ${maxValue} points
                max per one category.`}
              {rateModalState === ModalState.ko &&
                `We are sorry communication with ${recipient.name} was not  successful.Please, leave a comment why you are not satisfied with his communication.`}
            </p>
            <RateForm
              onClose={() => setRateModalState(ModalState.close)}
              onSubmit={(data) => {
                if (rateModalState === ModalState.rate) {
                  dispatch(
                    chatSparringRate({
                      ...data,
                      user_id: recipient.id,
                      note: data.feedback,
                    })
                  );
                }
                if (rateModalState === ModalState.ko) {
                  dispatch(
                    chatSparringKo({
                      id: recipient.id,
                      note: data.feedback,
                    })
                  );
                }
                setRateModalState(ModalState.close);
              }}
              maxTotalCount={maxTotalCount}
              maxValue={maxValue}
              allowRating={rateModalState === ModalState.rate}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default RoundStatus;
