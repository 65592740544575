import ReactDOM from 'react-dom/client';
import React from 'react';
import { flatten } from 'flat';
import hexRgb from 'hex-rgb';
import Root from './components/modules/Root';
import reportWebVitals from './reportWebVitals';
import palette from './styles/palette';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import store from './store';
import { setIsNewVersionAvailable } from './store/actions/notifications';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// map pallet to css variables
const doc = document.documentElement;
const cssVariables = flatten(palette, { delimiter: '-' }) as any;
Object.keys(cssVariables).forEach((item) => {
  doc.style.setProperty(`--${item}`, cssVariables[item]);
  const rgba = hexRgb(cssVariables[item], { format: 'array' });
  rgba.pop();
  doc.style.setProperty(`--${item}-rgb`, rgba.join(' '));
});

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const displayNewVersionModal = () => {
  store.dispatch(setIsNewVersionAvailable(true));
};

serviceWorkerRegistration.register({ onUpdate: displayNewVersionModal });
