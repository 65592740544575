import React from 'react';
import { STORE_TOPICS, TOPICS } from '../../../constants/routes';
import { useDispatch, useSelector } from '../../../libs/redux';
import {
  deleteTopic,
  setCurrentPage,
  setCurrentSort,
} from '../../../store/actions/topics';
import { selectTopics } from '../../../store/reducers/topics';
import Button from '../../elements/Button';
import InnerPageCard from '../../elements/InnerPageCard';
import Loader from '../../elements/Loader';
import Pagination from '../../elements/Pagination';
import TopicDetails from '../../modules/TopicDetails';
import { ReactComponent as Close } from '../../../assets/img/icon/close.svg';
import classes from './Topics.module.scss';
import Select from '../../elements/Select';
import { ISortOption } from '../../../types/general';
import { ITopic } from '../../../types/topic';
import { selectUser } from '../../../store/reducers/user';

const TopicsTemplate: React.FC = () => {
  const { list, pagination, loading, sort } = useSelector(selectTopics);
  const { user } = useSelector(selectUser);
  const dispatch = useDispatch();

  const sortOptions: { label: string; value: ISortOption<ITopic> }[] = [
    { label: 'Newest', value: { key: 'created_at', direction: 'desc' } },
    { label: 'Oldest', value: { key: 'created_at', direction: 'asc' } },
  ];

  const getCurrentValue = (value: ISortOption<ITopic>) =>
    sortOptions.find(
      (val) =>
        val.value.key === value.key && val.value.direction === value.direction
    );

  const visibleList = list.slice(
    pagination.current_page * pagination.per_page - pagination.per_page,
    pagination.current_page * pagination.per_page
  );

  const getResultTitle = () => {
    if (loading && !list.length) {
      return 'Loading...';
    }
    if (list.length > 0) {
      return `${pagination.total} Result${pagination.total > 1 ? 's' : ''}`;
    }
    return 'Add your first topic';
  };

  return (
    <section className={classes.topics}>
      <InnerPageCard>
        <div className={classes.topics__head}>
          {list.length > 0 ? (
            <>
              <span className={classes.total__result}>{getResultTitle()}</span>
              <div>
                Sort by:{' '}
                <Select
                  options={sortOptions}
                  value={getCurrentValue(sort)}
                  onChange={(v) =>
                    v ? dispatch(setCurrentSort(v.value)) : null
                  }
                  isSearchable={false}
                  variant="transparent"
                />
              </div>
            </>
          ) : (
            <h2>{getResultTitle()}</h2>
          )}
        </div>
        <Loader open={loading} position="absolute" />
        <div className={classes.topic__list}>
          {visibleList.map((topic) => (
            <div className={classes.topic} key={topic.id}>
              <TopicDetails
                key={topic.id}
                name={topic.name}
                description={topic.description}
                status={topic.status}
                tags={topic.topic_tags.map((tag) => tag.name).join(', ')}
                usersCount={topic.users_count}
                descriptionLength={80}
              />
              <div className={classes.btnWrap}>
                {user && topic.user_id === user.id && (
                  <div className={classes.actions}>
                    <Button
                      to={`${STORE_TOPICS.fullPath}/${topic.id}`}
                      variant="contained"
                      color="secondary"
                      size="xs"
                    >
                      EDIT
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      size="xs"
                      onClick={() => dispatch(deleteTopic(topic.id))}
                    >
                      <Close />
                    </Button>
                  </div>
                )}

                <Button fullWidth to={`${TOPICS.fullPath}/${topic.id}`}>
                  LEARN MORE
                </Button>
              </div>
            </div>
          ))}
        </div>
        {list.length > 0 && pagination.total > pagination.per_page && (
          <div className={classes.pagination}>
            <Pagination
              total={Math.ceil(pagination.total / pagination.per_page)}
              current={pagination.current_page}
              pageRangeDisplayed={3}
              onChange={(p) => dispatch(setCurrentPage(p))}
            />
          </div>
        )}
      </InnerPageCard>
    </section>
  );
};

export default TopicsTemplate;
