import classNames from 'classnames';
import React from 'react';
import InputBase, { InputBaseProps } from '../InputBase';
import classes from './TextField.module.scss';

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {}

export interface TextFieldProps extends InputBaseProps, InputProps {
  value?: string | number;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  type?: 'text' | 'password';
  placeholder?: string;
  className?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  textareaRef?: React.Ref<HTMLTextAreaElement>;
  name?: string;
  multiline?: boolean;
  rows?: number;
}

const TextField: React.FC<TextFieldProps> = (props) => {
  const {
    value,
    onChange,
    type = 'text',
    label,
    className,
    inputRef,
    textareaRef,
    name,
    error = false,
    helperText,
    multiline,
    placeholder,
    rows = 3,
    fullWidth,
    onClick,
    onBlur,
    onKeyDown,
    onFocus,
    startIcon,
    endIcon,
    disabled,
  } = props;

  const classList = classNames(classes.textField, className, {
    [classes.error]: error,
    [classes.textarea]: multiline,
    [classes.startIcon]: startIcon,
    [classes.endIcon]: endIcon,
  });

  return (
    <InputBase
      error={error}
      helperText={helperText}
      label={label}
      fullWidth={fullWidth}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {multiline ? (
        <textarea
          className={classList}
          value={value}
          onChange={onChange}
          name={name}
          placeholder={placeholder}
          rows={rows}
          ref={textareaRef}
          disabled={disabled}
        />
      ) : (
        <input
          className={classList}
          type={type}
          value={value}
          onChange={onChange}
          ref={inputRef}
          name={name}
          placeholder={placeholder}
          onClick={onClick}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          disabled={disabled}
        />
      )}
    </InputBase>
  );
};

export default TextField;
