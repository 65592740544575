import axios, { ResponseWithPagination } from '../libs/axios';
import { IUserSearchable } from '../types/search';

export const searchUsers = (
  params: { [key: string]: number | number[] },
  page?: number
) =>
  axios<ResponseWithPagination<IUserSearchable[]>>({
    url: '/user/search',
    method: 'GET',
    params: {
      ...params,
      page,
    },
  });

export default searchUsers;
