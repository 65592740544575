import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { searchUsers } from '../../repositories/search';
import { IPaginationParams } from '../../types/general';
import { ISearchFields } from '../../types/search';

export const setSearchFields = createAction<ISearchFields>('setSearchFields');

export const search = createAsyncThunk(
  'search',
  async (payload: ISearchFields & IPaginationParams) => {
    const { page, ...rest } = payload;
    const params: { [key: string]: number | number[] } = (
      Object.keys(rest) as Array<keyof typeof rest>
    ).reduce((acc: { [key: string]: number | number[] }, item) => {
      const value = rest[item];
      if (!value || (Array.isArray(value) && !value.length)) {
        return acc;
      }
      if (typeof value === 'number') {
        return { ...acc, [item]: value };
      }
      if (item === 'looking_occupation' || item === 'scale_search') {
        return {
          ...acc,
          [item]: value[0].id,
        };
      }
      return {
        ...acc,
        [item]: value.map((i) => i.id),
      };
    }, {});
    const response = await searchUsers(params, page);

    return response;
  }
);

export const setCurrentPage = createAction<number>('setCurrentPage');
