import React from 'react';
import PrivateRoute from '../../components/elements/PrivateRoute';
import OnboardingTemplate from '../../components/templates/Onboarding';
import { Access } from '../../types/general';

const OnboardingPage: React.FC = () => (
  <PrivateRoute access={Access.onboarding}>
    <OnboardingTemplate />
  </PrivateRoute>
);

export default OnboardingPage;
