import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ERROR_FIELD_REQUIRED } from '../../../constants/error-messages';
import {
  dynamicContentToOptions,
  getMultiOptionFromDynamicContent,
  getOptionFromDynamicContent,
} from '../../../helpers/dynamic-content';
import useDynamicContent from '../../../hooks/useDynamicContent';
import { IBaseTopic, ITopic } from '../../../types/topic';
import Button from '../../elements/Button';
import FormGroup from '../../elements/FormGroup';
import Select from '../../elements/Select';
import TextField from '../../elements/TextField';
import classes from './TopicForm.module.scss';

interface ITopicLocal extends Omit<IBaseTopic, 'topic_tags'> {
  topic_tags: number[];
}

interface IProps {
  onSubmit: (data: ITopicLocal) => void;
  errors: Partial<Record<keyof ITopic, string>>;
  onCancel: () => void;
  values?: ITopicLocal;
  sending: boolean;
}

const TopicForm: React.FC<IProps> = (props) => {
  const { onSubmit, errors: propErrors, onCancel, values, sending } = props;
  const { topicTags, topicStatuses } = useDynamicContent([
    'topicTags',
    'topicStatuses',
  ]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ITopicLocal>({
    defaultValues: values,
  });
  const fields = {
    name: register('name', {
      required: ERROR_FIELD_REQUIRED,
    }),
    description: register('description', {
      required: ERROR_FIELD_REQUIRED,
    }),
    status: register('status', {
      required: ERROR_FIELD_REQUIRED,
    }),
    topic_tags: register('topic_tags', {
      required: ERROR_FIELD_REQUIRED,
    }),
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <TextField
          fullWidth
          label="Topic Name"
          name={fields.name.name}
          inputRef={fields.name.ref}
          onChange={fields.name.onChange}
          error={Boolean(errors.name || propErrors.name)}
          helperText={errors.name?.message || propErrors.name}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          multiline
          rows={4}
          label="Description"
          name={fields.description.name}
          textareaRef={fields.description.ref}
          onChange={fields.description.onChange}
          error={Boolean(errors.description || propErrors.description)}
          helperText={errors.description?.message || propErrors.description}
        />
      </FormGroup>

      <FormGroup>
        <Controller
          control={control}
          name={fields.topic_tags.name}
          render={(field) => (
            <Select
              isMulti
              fullWidth
              label="Tags"
              options={dynamicContentToOptions(topicTags.list)}
              value={
                field.field.value
                  ? getMultiOptionFromDynamicContent(
                      topicTags.list,
                      field.field.value
                    )
                  : null
              }
              onChange={(v) => field.field.onChange(v && v.map((i) => i.value))}
              error={Boolean(field.fieldState.error)}
              helperText={field.fieldState.error?.message}
            />
          )}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          control={control}
          name={fields.status.name}
          render={(field) => (
            <Select
              fullWidth
              label="Status"
              options={dynamicContentToOptions(topicStatuses.list)}
              value={
                field.field.value
                  ? getOptionFromDynamicContent(
                      topicStatuses.list,
                      field.field.value
                    )
                  : null
              }
              onChange={(v) => field.field.onChange(v && v.value)}
              error={Boolean(field.fieldState.error)}
              helperText={field.fieldState.error?.message}
            />
          )}
        />
      </FormGroup>

      <div className={classes.btnWrap}>
        <Button color="default" onClick={onCancel}>
          CANCEL
        </Button>
        <Button type="submit" disabled={sending}>
          SAVE
        </Button>
      </div>
    </form>
  );
};

export default TopicForm;
