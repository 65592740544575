import React from 'react';
import ExperienceItem from '../../elements/ExperienceItem';
import Button from '../../elements/Button';
import { ReactComponent as Plus } from '../../../assets/img/icon/plus.svg';
import classes from './ExperienceList.module.scss';

interface IProps {
  title: string;
  items: {
    id: number;
    title: string;
    subtitle: string;
    date: string;
    description?: string;
    caption?: string;
  }[];
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  onAdd?: () => void;
}

const ExperienceList: React.FC<IProps> = (props) => {
  const { items, onDelete, onEdit, title, onAdd } = props;

  return (
    <div className={classes.education__box}>
      <div className={classes.education__head}>
        <h3>{title}</h3>
        {onAdd && (
          <Button
            size="sm"
            variant="outlined"
            startIcon={<Plus />}
            onClick={onAdd}
          >
            ADD
          </Button>
        )}
      </div>
      <div className={classes.education__list}>
        {items.map((item) => (
          <ExperienceItem
            key={item.id}
            title={item.title}
            subtitle={item.subtitle}
            date={item.date}
            description={item.description}
            caption={item.caption}
            onDelete={onDelete && (() => onDelete(item.id))}
            onEdit={onEdit && (() => onEdit(item.id))}
          />
        ))}
      </div>
    </div>
  );
};

export default ExperienceList;
