import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { IChatWithDetails } from '../../../types/chat';
import classes from './ChatsList.module.scss';
import { MESSAGES } from '../../../constants/routes';
import Avatar from '../../elements/Avatar';
import Badge from '../../elements/Badge';
import RoundStatus from '../RoundStatus';
import { useDispatch } from '../../../libs/redux';
import { getChatSparring } from '../../../store/actions/chat';

interface ItemProps {
  chat: IChatWithDetails;
  active: boolean;
}

const Item: React.FC<ItemProps> = (props) => {
  const { chat, active } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!chat.details.sparring) {
      dispatch(getChatSparring(chat.details.userId));
    }
  }, []);

  return (
    <div
      className={classNames(classes.contactWrap, {
        [classes.active]: active,
      })}
    >
      <Link
        className={classes.contact}
        to={`${MESSAGES.fullPath}/${chat.details.userId}`}
      >
        <div className={classes.avatar}>
          <Avatar fullWidth src={chat.details.photo} />
        </div>
        <div className={classes.info}>
          <div className={classes.top}>
            <p className={classes.name}>{chat.details.name}</p>
            {chat.messageLast && chat.messageLast.createdAt ? (
              <span className={classes.time}>
                {moment(chat.messageLast.createdAt).format('hh:mm')}
              </span>
            ) : null}
          </div>
          <div className={classes.bottom}>
            {chat.messageLast && (
              <p>
                {chat.messageLast.text ||
                  (chat.messageLast.messageFiles.length ? 'File' : '')}
              </p>
            )}

            {chat.messageUnreadCount > 0 && (
              <span className={classes.counter}>
                <Badge badgeContent={chat.messageUnreadCount} size="small" />
              </span>
            )}
          </div>
        </div>
      </Link>
      {chat.details.sparring && (
        <div className={classes.round}>
          <RoundStatus
            sparring={chat.details.sparring}
            recipient={{ id: chat.details.userId, name: chat.details.name }}
            isKoPossible={chat.details.topicKnockOut}
            userRatingRated={chat.details.userRatingRated}
          />
        </div>
      )}
    </div>
  );
};

export default Item;
