import React, { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ONBOARDING, SIGN_IN, TOPICS } from '../../../constants/routes';
import { useSelector } from '../../../libs/redux';
import { selectUser } from '../../../store/reducers/user';
import { Access } from '../../../types/general';
import { IUserStatus } from '../../../types/user';
import Loader from '../Loader';

interface IProps {
  children?: React.ReactNode;
  access: Access;
}

const PrivateRoute: React.FC<IProps> = (props) => {
  const { user, loading } = useSelector(selectUser);
  const { pathname } = useLocation();
  const { children, access } = props;

  const getRedirect = () => {
    switch (access) {
      case Access.notAuthenticated:
        return user ? TOPICS.fullPath : undefined;
      case Access.authenticated:
        return !user ? SIGN_IN.fullPath : undefined;
      case Access.active:
        if (!user) {
          return SIGN_IN.fullPath;
        }
        return user.status !== IUserStatus.active
          ? ONBOARDING.fullPath
          : undefined;
      case Access.onboarding:
        if (!user) {
          return SIGN_IN.fullPath;
        }
        return user.status !== IUserStatus.onboarding
          ? TOPICS.fullPath
          : undefined;
      default:
        return undefined;
    }
  };

  const redirect = useMemo(getRedirect, [
    loading,
    user?.status,
    access,
    pathname,
  ]);

  if (loading) {
    return <Loader />;
  }

  if (redirect && redirect !== pathname) {
    return <Navigate to={redirect} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default PrivateRoute;
