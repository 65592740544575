import { createReducer } from '@reduxjs/toolkit';
import { ModalName, ModalState } from '../../types/global-modals';
import { setRatingModalData, updateModal } from '../actions/global-modals';

interface State extends Record<ModalName, ModalState<any>> {
  rate: {
    open: boolean;
    data: { userId: number; name: string } | null;
  };
}

const initialState: State = {
  rate: {
    open: false,
    data: null,
  },
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(updateModal, (state, action) => {
    state[action.payload.name].open = action.payload.open;
  });
  builder.addCase(setRatingModalData, (state, action) => {
    state.rate.data = action.payload;
  });
});

interface Store {
  globalModals: State;
}

export const selectGlobalModals = (state: Store) => state.globalModals;

export default reducer;
