import { createReducer } from '@reduxjs/toolkit';
import { WebSocketStatus } from '../../types/general';
import { clearAuthData } from '../actions/authentication';
import { setError, setStatus } from '../actions/web-socket';

interface State {
  status: WebSocketStatus;
  error: string;
}

const initialState: State = {
  status: WebSocketStatus.disconnected,
  error: '',
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setStatus, (state, action) => {
    state.status = action.payload;
  });
  builder.addCase(setError, (state, action) => {
    state.error = action.payload;
  });
  builder.addCase(clearAuthData, () => initialState);
});

interface Store {
  webSocket: State;
}

export const selectWebSocket = (state: Store) => state.webSocket;

export default reducer;
