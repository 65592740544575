import React from 'react';
import { useDispatch, useSelector } from '../../../libs/redux';
import { search, setSearchFields } from '../../../store/actions/search';
import { selectSearch } from '../../../store/reducers/search';
import { ISearchFields } from '../../../types/search';
import Chip from '../../elements/Chip';
import classes from './SearchInfo.module.scss';
import { IBaseContent } from '../../../types/dynamic-content';

const SearchBadges: React.FC = () => {
  const { fields } = useSelector(selectSearch);
  const dispatch = useDispatch();

  const handleRemoveOption = (data: ISearchFields) => {
    dispatch(setSearchFields(data));
    dispatch(search(data));
  };

  interface Badge {
    name: string;
    id: number;
    fields: ISearchFields;
  }
  const getBadges = (): Badge[] =>
    (
      Object.entries(fields) as [keyof typeof fields, number | IBaseContent[]][]
    ).reduce((acc: Badge[], field) => {
      const [key, value] = field;
      if (!value) {
        return acc;
      }
      if (typeof value === 'number') {
        let name = 'Total point > ';
        if (key === 'successful_communication') {
          name = 'Successful communication > ';
        }
        if (key === 'ko') {
          name = 'KO < ';
        }
        return [
          ...acc,
          {
            name: `${name} ${value}`,
            id: 1,
            fields: {
              ...fields,
              [key]: 0,
            },
          },
        ];
      }
      return [
        ...acc,
        ...value.map((item) => ({
          name: item.name,
          id: item.id,
          fields: {
            ...fields,
            [key]: value.filter((i) => i.id !== item.id),
          },
        })),
      ];
    }, []);

  return (
    <div className={classes.badges}>
      {getBadges().map((badge) => (
        <Chip
          onDelete={() => handleRemoveOption(badge.fields)}
          key={badge.name + badge.id}
          variant="contained"
          size="xs"
        >
          {badge.name}
        </Chip>
      ))}
    </div>
  );
};

export default SearchBadges;
