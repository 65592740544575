import classNames from 'classnames';
import React from 'react';
import classes from './Badge.module.scss';

interface IProps {
  badgeContent?: number;
  showZero?: boolean;
  children?: React.ReactNode;
  variant?: 'dot' | 'standard';
  position?: { horizontal: 'left' | 'right'; vertical: 'bottom' | 'top' };
  size?: 'large' | 'medium' | 'small';
}

const Badge: React.FC<IProps> = (props) => {
  const {
    badgeContent,
    showZero = false,
    children,
    variant = 'standard',
    position = { horizontal: 'right', vertical: 'top' },
    size = 'medium',
  } = props;

  const isVisible =
    badgeContent !== undefined &&
    (badgeContent !== 0 || (badgeContent === 0 && showZero));
  return (
    <div className={classes.wrap}>
      {isVisible && (
        <div
          className={classNames(
            classes.badge,
            classes[position.horizontal],
            classes[position.vertical],
            classes[variant],
            classes[size]
          )}
        >
          {variant === 'standard' && badgeContent}
        </div>
      )}
      {children}
    </div>
  );
};

export default Badge;
