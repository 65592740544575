import React from 'react';
import { useSelector } from '../../../libs/redux';
import { selectNotifications } from '../../../store/reducers/notifications';
import Notification from '../Notification';
import classes from './NotificationsList.module.scss';

const NotificationsList: React.FC = () => {
  const { fastNotifications } = useSelector(selectNotifications);

  return (
    <div className={classes.wrap}>
      {fastNotifications.map((notification) => (
        <Notification key={notification.id} notification={notification} />
      ))}
    </div>
  );
};

export default NotificationsList;
