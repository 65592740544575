import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../components/elements/Loader';
import PrivateRoute from '../../components/elements/PrivateRoute';
import ErrorTemplate from '../../components/templates/Error';
import TopicTemplate from '../../components/templates/Topic';
import { TOPICS } from '../../constants/routes';
import { useDispatch, useSelector } from '../../libs/redux';
import useLayoutTitle from '../../hooks/useLayoutTitle';
import { getTopic, setTopic } from '../../store/actions/topics';
import { selectTopic } from '../../store/reducers/topic';
import { selectUser } from '../../store/reducers/user';

const TopicPage: React.FC = () => {
  const { id } = useParams();
  useLayoutTitle('');
  const { topic, loading, errors } = useSelector(selectTopic);
  const { user } = useSelector(selectUser);
  const dispatch = useDispatch();

  const handledId = parseInt(id || '', 10);

  useEffect(() => {
    if (
      !Number.isNaN(handledId) &&
      ((!topic && !loading) || (topic && topic.id !== handledId))
    ) {
      dispatch(getTopic(handledId));
    }
    return () => {
      dispatch(setTopic(null));
    };
  }, []);

  if (Object.keys(errors).length || Number.isNaN(handledId)) {
    return <ErrorTemplate code={404} />;
  }
  return (
    <PrivateRoute access={TOPICS.access}>
      {!topic || loading ? (
        <Loader position="absolute" />
      ) : (
        <TopicTemplate topic={topic} user={user} />
      )}
    </PrivateRoute>
  );
};

export default TopicPage;
