import React, { useEffect } from 'react';
import Loader from '../../components/elements/Loader';
import PrivateRoute from '../../components/elements/PrivateRoute';
import EditProfileTemplate from '../../components/templates/EditProfile';
import { EDIT_PROFILE } from '../../constants/routes';
import { useDispatch, useSelector } from '../../libs/redux';
import useLayoutTitle from '../../hooks/useLayoutTitle';
import { updateUserData, setUserPhoto } from '../../store/actions/user';
import { getEducation } from '../../store/actions/user-education';
import { getExperience } from '../../store/actions/user-experience';
import { selectUser } from '../../store/reducers/user';
import { selectUserEducation } from '../../store/reducers/user-education';
import { selectUserExperience } from '../../store/reducers/user-experience';
import { Access } from '../../types/general';

const EditProfilePage: React.FC = () => {
  const { user, updating } = useSelector(selectUser);
  const experience = useSelector(selectUserExperience);
  const education = useSelector(selectUserEducation);
  const dispatch = useDispatch();
  useLayoutTitle(EDIT_PROFILE.name);

  useEffect(() => {
    if (!experience.loaded && !experience.loading) {
      dispatch(getExperience());
    }
    if (!education.loaded && !education.loading) {
      dispatch(getEducation());
    }
  }, []);

  return (
    <PrivateRoute access={Access.active}>
      {!user ? (
        <Loader />
      ) : (
        <EditProfileTemplate
          user={user}
          onUserEdit={(u) => dispatch(updateUserData(u))}
          onPhotoUpdate={(p) => dispatch(setUserPhoto(p))}
          updating={updating}
        />
      )}
    </PrivateRoute>
  );
};

export default EditProfilePage;
