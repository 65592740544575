import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  requestHomePage,
  requestMenus,
  requestPage,
} from '../../repositories/dynamic-pages';

export const getMenu = createAsyncThunk('getMenu', async () => {
  const response = await requestMenus();
  return response.data;
});

export const getPage = createAsyncThunk('getPage', async (slug: string) => {
  const response = await requestPage(slug);
  return response.data;
});

export const getHomePage = createAsyncThunk('getHomePage', async () => {
  const response = await requestHomePage();
  return response.data;
});
