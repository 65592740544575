import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ERROR_FIELD_EXPERIENCE_REQUIRED } from '../../../constants/error-messages';
import { useDispatch, useSelector } from '../../../libs/redux';
import { getBaseContent } from '../../../store/actions/dynamic-content';
import {
  addExperience,
  deleteExperience,
  editExperience,
  getExperience,
} from '../../../store/actions/user-experience';
import { selectDynamicContent } from '../../../store/reducers/dynamic-content';
import { selectUserExperience } from '../../../store/reducers/user-experience';
import { IExperience } from '../../../types/user';
import HelperText from '../../elements/HelperText';
import Loader from '../../elements/Loader';
import PersonalDataCard from '../../elements/PersonalDataCard';
import ExperienceForm from '../ExperienceForm';
import ExperienceList from '../ExperienceList';
import StepsNavigation from './StepsNavigation';
import classes from './Onboarding.module.scss';

interface IProps {
  onNext: () => void;
  onPrev: () => void;
}

const Experience: React.FC<IProps> = (props) => {
  const { onNext, onPrev } = props;
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [itemToEdit, setItemToEdit] = useState<null | number>(null);
  const { list, errors, loaded, loading } = useSelector(selectUserExperience);
  const { employmentTypes } = useSelector(selectDynamicContent);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setShowModal(false);
    setItemToEdit(null);
  };

  const handleEdit = (id: number) => {
    setShowModal(true);
    setItemToEdit(id);
  };

  const handleSubmit = (data: Omit<IExperience, 'id'>) => {
    if (itemToEdit !== null) {
      dispatch(editExperience({ ...data, id: itemToEdit }));
    } else {
      dispatch(addExperience(data));
    }
  };

  useEffect(() => {
    handleCloseModal();
  }, [list]);

  useEffect(() => {
    if (!loaded && !loading) {
      dispatch(getExperience());
    }
    if (!employmentTypes.loaded && !employmentTypes.loading) {
      dispatch(getBaseContent({ key: 'employmentTypes' }));
    }
  }, []);

  const editableExperience =
    itemToEdit !== null ? list.find((i) => i.id === itemToEdit) : undefined;

  const handleNext = () => {
    if (list.length) {
      onNext();
      setError('');
    } else {
      setError(ERROR_FIELD_EXPERIENCE_REQUIRED);
    }
  };

  return (
    <>
      <div className={classNames({ [classes.hidden]: showModal })}>
        <ExperienceList
          title="Experience"
          onAdd={() => setShowModal(true)}
          onDelete={(id) => dispatch(deleteExperience(id))}
          onEdit={(id) => handleEdit(id)}
          items={list.map((item) => ({
            id: item.id,
            title: item.title,
            subtitle: item.company_name,
            date: `${new Date(item.start_date).getFullYear()}-${new Date(
              item.end_date
            ).getFullYear()}`,
            description: item.description,
            caption: item.location,
          }))}
        />
        {error && <HelperText color="error">{error}</HelperText>}

        <StepsNavigation
          nextBtn={{ onClick: handleNext }}
          prevBtn={{ onClick: onPrev }}
        />
      </div>

      {showModal && (
        <div className={classes.modal}>
          <PersonalDataCard subtitle="ADD EXPERIENCE">
            <ExperienceForm
              errors={errors}
              onSubmit={handleSubmit}
              onCancel={handleCloseModal}
              values={editableExperience}
              employmentTypes={employmentTypes.list.map((i) => ({
                value: i.id,
                label: i.name,
              }))}
            />
          </PersonalDataCard>
        </div>
      )}

      <Loader open={loading} zIndex={20} position="fixed" />
    </>
  );
};

export default Experience;
