import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ERROR_FIELD_REQUIRED } from '../../../constants/error-messages';
import { useDispatch, useSelector } from '../../../libs/redux';
import { getBaseContent } from '../../../store/actions/dynamic-content';
import { updateUserData } from '../../../store/actions/user';
import { selectDynamicContent } from '../../../store/reducers/dynamic-content';
import { IUser } from '../../../types/user';
import FormGroup from '../../elements/FormGroup';
import Select from '../../elements/Select';
import StepsNavigation from './StepsNavigation';

interface IProps {
  onPrev: () => void;
  user: IUser;
}

const Interests: React.FC<IProps> = (props) => {
  const { onPrev, user } = props;
  const { interaction, occupations } = useSelector(selectDynamicContent);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!interaction.loaded && !interaction.loading) {
      dispatch(getBaseContent({ key: 'interaction' }));
    }
    if (!occupations.loaded && !occupations.loading) {
      dispatch(getBaseContent({ key: 'occupations' }));
    }
  }, []);

  const { handleSubmit, control } = useForm<{
    occupation: number[];
    interaction: number[];
  }>({
    defaultValues: {
      occupation: user.looking_occupations
        ? user.looking_occupations.map((i) => i.id)
        : undefined,
      interaction: user.interaction_types
        ? user.interaction_types.map((i) => i.id)
        : undefined,
    },
  });

  const interactionOptions = interaction.list.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const occupationsOptions = occupations.list.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const handleNext = () => {
    handleSubmit((data) => {
      dispatch(
        updateUserData({
          interaction_types: data.interaction,
          looking_occupations: data.occupation,
        })
      );
    })();
  };

  return (
    <>
      <FormGroup>
        <Controller
          control={control}
          name="occupation"
          rules={{
            required: ERROR_FIELD_REQUIRED,
          }}
          render={(field) => (
            <Select
              fullWidth
              isMulti
              label="Who are you looking for?"
              options={occupationsOptions}
              value={occupationsOptions.filter(
                (i) => field.field.value && field.field.value.includes(i.value)
              )}
              onChange={(v) => field.field.onChange(v.map((i) => i.value))}
              error={Boolean(field.fieldState.error)}
              helperText={field.fieldState.error?.message}
            />
          )}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          control={control}
          name="interaction"
          rules={{
            required: ERROR_FIELD_REQUIRED,
          }}
          render={(field) => (
            <Select
              fullWidth
              isMulti
              label="Interaction Type"
              options={interactionOptions}
              value={interactionOptions.filter(
                (i) => field.field.value && field.field.value.includes(i.value)
              )}
              onChange={(v) => field.field.onChange(v.map((i) => i.value))}
              error={Boolean(field.fieldState.error)}
              helperText={field.fieldState.error?.message}
            />
          )}
        />
      </FormGroup>
      <StepsNavigation
        nextBtn={{ onClick: handleNext }}
        prevBtn={{ onClick: onPrev }}
      />
    </>
  );
};

export default Interests;
