import React from 'react';
import Button from '../Button';
import classes from './Pagination.module.scss';

interface IProps {
  total: number;
  current: number;
  pageRangeDisplayed?: number;
  onChange: (page: number) => void;
}

enum BtnType {
  btn,
  more,
}

interface BtnItem {
  type: BtnType;
  content: number;
}

const Pagination: React.FC<IProps> = (props) => {
  const { total, current, pageRangeDisplayed = 3, onChange } = props;

  const items: BtnItem[] = [];

  for (let i = 1; i <= total; i += 1) {
    items.push({ type: BtnType.btn, content: i });
  }

  const displayedItems = items.reduce((acc: BtnItem[], item, i, arr) => {
    if (
      i === 0 ||
      i === arr.length - 1 ||
      i + 1 === current ||
      (current <= pageRangeDisplayed && i + 1 <= pageRangeDisplayed) ||
      (current > arr.length - pageRangeDisplayed &&
        i + 1 > arr.length - pageRangeDisplayed)
    ) {
      return [...acc, item];
    }

    if (
      i - Math.floor(pageRangeDisplayed / 2) > current - 1 ||
      i + Math.floor(pageRangeDisplayed / 2) < current - 1
    ) {
      if (acc[acc.length - 1].type === BtnType.more) {
        return acc;
      }
      return [...acc, { type: BtnType.more, content: -1 }];
    }
    return [...acc, item];
  }, []);

  return (
    <div className={classes.pagination}>
      {displayedItems.map((item) =>
        item.type === BtnType.btn ? (
          <Button
            key={item.content}
            className={classes.btn}
            color={item.content === current ? 'primary' : 'secondary'}
            onClick={() => onChange(item.content)}
          >
            {item.content}
          </Button>
        ) : (
          <div className={classes.more} key={item.content}>
            ...
          </div>
        )
      )}
    </div>
  );
};

export default Pagination;
