import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  ERROR_FIELD_EMAIL_REQUIRED,
  ERROR_FIELD_EMAIL_VALIDATION,
  ERROR_FIELD_FULL_NAME,
  ERROR_FIELD_PASSWORD,
  ERROR_FIELD_REQUIRED,
} from '../../../constants/error-messages';
import { EMAIL, FULL_NAME, PASSWORD } from '../../../constants/regexp';
import { ISignUpFields } from '../../../types/authentication';
import Button from '../../elements/Button';
import Checkbox from '../../elements/Checkbox';
import FormGroup from '../../elements/FormGroup';
import TextField from '../../elements/TextField';
import classes from './SignUpForm.module.scss';

interface IProps {
  onSubmit: (data: ISignUpFields) => void;
  errors?: Partial<ISignUpFields>;
  terms?: string;
}

const SignUpForm: React.FC<IProps> = (props) => {
  const { onSubmit, errors: propsErrors, terms } = props;

  const navegate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignUpFields>();
  const fields = {
    name: register('name', {
      required: ERROR_FIELD_REQUIRED,
      pattern: { value: FULL_NAME, message: ERROR_FIELD_FULL_NAME },
    }),
    email: register('email', {
      required: ERROR_FIELD_EMAIL_REQUIRED,
      pattern: { value: EMAIL, message: ERROR_FIELD_EMAIL_VALIDATION },
    }),
    password: register('password', {
      required: ERROR_FIELD_PASSWORD,
      pattern: {
        value: PASSWORD,
        message: ERROR_FIELD_PASSWORD,
      },
    }),
    confirmPassword: register('confirmPassword', {
      required: ERROR_FIELD_PASSWORD,
      pattern: {
        value: PASSWORD,
        message: ERROR_FIELD_PASSWORD,
      },
    }),
    terms: register('terms', { required: true, value: true }),
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <TextField
          fullWidth
          label="Your Name"
          name={fields.name.name}
          onChange={fields.name.onChange}
          inputRef={fields.name.ref}
          error={Boolean(errors.name || propsErrors?.name)}
          helperText={errors.name?.message || propsErrors?.name}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          label="Email"
          name={fields.email.name}
          onChange={fields.email.onChange}
          inputRef={fields.email.ref}
          error={Boolean(errors.email || propsErrors?.email)}
          helperText={errors.email?.message || propsErrors?.email}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          label="Password"
          type="password"
          name={fields.password.name}
          onChange={fields.password.onChange}
          inputRef={fields.password.ref}
          error={Boolean(errors.password || propsErrors?.password)}
          helperText={errors.password?.message || propsErrors?.password}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          label="Confirm Password"
          type="password"
          name={fields.confirmPassword.name}
          onChange={fields.confirmPassword.onChange}
          inputRef={fields.confirmPassword.ref}
          error={Boolean(errors.confirmPassword)}
          helperText={errors.confirmPassword?.message}
        />
      </FormGroup>
      {terms && (
        <Checkbox
          label={
            <>
              Accept{' '}
              <Button variant="link" onClick={() => navegate(terms)}>
                Terms & Conditions
              </Button>
            </>
          }
          name={fields.terms.name}
          onChange={fields.terms.onChange}
          inputRef={fields.terms.ref}
          error={Boolean(errors.terms)}
        />
      )}

      <div className={classes.btnWrap}>
        <Button type="submit" variant="contained" shadow size="lg">
          SIGN UP
        </Button>
      </div>
    </form>
  );
};

export default SignUpForm;
