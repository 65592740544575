import axios, { Response, ResponseWithPagination } from '../libs/axios';
import { IPaginationParams } from '../types/general';
import { ISparring, ISparringRequest, SparringStatus } from '../types/sparring';

export const createSparring = (data: ISparringRequest) =>
  axios<Response<ISparring>>({
    url: '/sparring-invitations',
    method: 'POST',
    data,
  });

export const requestSparrings = (params?: IPaginationParams) =>
  axios<ResponseWithPagination<ISparring[]>>({
    url: '/sparring-invitations',
    method: 'GET',
    params,
  });

export const updateSparring = (status: SparringStatus, id: number) =>
  axios<Response<ISparring[]>>({
    url: `/sparring-invitations/${id}`,
    method: 'PUT',
    data: {
      status,
    },
  });
