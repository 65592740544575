import React from 'react';
import { useDispatch, useSelector } from '../../../libs/redux';
import { setChatMedia } from '../../../store/actions/chat';
import { selectCurrentChat } from '../../../store/reducers/current-chat';
import Modal from '../../elements/Modal';
import classes from './ChatMediaModal.module.scss';

const ChatMediaModal: React.FC = () => {
  const { media } = useSelector(selectCurrentChat);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setChatMedia(null));
  };
  return (
    <Modal
      open={Boolean(media.viewer)}
      onClose={handleClose}
      className={classes.modal}
    >
      {media.viewer === 'image' && (
        <div className={classes.imgWrap}>
          <img src={media.src} alt="" />
        </div>
      )}
    </Modal>
  );
};

export default ChatMediaModal;
