import React from 'react';
import Picker, { EmojiStyle } from 'emoji-picker-react';
import classes from './EmojiPicker.module.scss';

interface IProps {
  onSelect: (emoji: string) => void;
}

const EmojiPicker: React.FC<IProps> = (props) => {
  const { onSelect } = props;

  return (
    <div className={classes.wrap}>
      <Picker
        skinTonesDisabled
        searchDisabled
        width={300}
        emojiStyle={EmojiStyle.NATIVE}
        previewConfig={{ showPreview: false }}
        onEmojiClick={(emoji) => onSelect(emoji.emoji)}
      />
    </div>
  );
};

export default EmojiPicker;
