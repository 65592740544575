import React, { useEffect, useRef } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/elements/Loader';
import PrivateRoute from '../../components/elements/PrivateRoute';
import StoreEducationTemplate from '../../components/templates/StoreEducation';
import { PROFILE, STORE_EDUCATION } from '../../constants/routes';
import { useDispatch, useSelector } from '../../libs/redux';
import useLayoutTitle from '../../hooks/useLayoutTitle';
import {
  addEducation,
  editEducation,
  getEducation,
} from '../../store/actions/user-education';
import { selectUserEducation } from '../../store/reducers/user-education';
import { Access } from '../../types/general';
import { IEducation } from '../../types/user';

const StoreEducationPage: React.FC = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { list, loaded, loading, errors } = useSelector(selectUserEducation);
  const id = parseInt(slug || '', 10);
  const navigate = useNavigate();
  const storing = useRef(false);

  useLayoutTitle(STORE_EDUCATION.name);

  useEffect(() => {
    if (!loaded && !loading) {
      dispatch(getEducation());
    }
  }, []);
  useEffect(() => {
    if (storing.current) {
      navigate(PROFILE.fullPath);
    }
  }, [list]);

  if (!slug || (Number.isNaN(id) && slug !== 'add')) {
    return <Navigate to="add" />;
  }

  const education = list.find((item) => item.id === id);

  const handleStore = (data: Omit<IEducation, 'id'>) => {
    storing.current = true;
    if (slug === 'add') {
      dispatch(addEducation(data));
    } else {
      dispatch(editEducation({ ...data, id }));
    }
  };

  const handleCancel = () => {
    navigate(PROFILE.fullPath);
  };

  return (
    <PrivateRoute access={Access.active}>
      {!loaded && loading && !Number.isNaN(id) ? (
        <Loader />
      ) : (
        <StoreEducationTemplate
          education={education}
          onStore={handleStore}
          onCancel={handleCancel}
          errors={errors}
          loading={loading}
        />
      )}
    </PrivateRoute>
  );
};

export default StoreEducationPage;
