export const ERROR_FIELD_REQUIRED = 'This field is required';
export const ERROR_FIELD_ONLY_LETTERS = 'This field must contain only letters';
export const ERROR_FIELD_START_DATE =
  'The start date must be less than the end date';
export const ERROR_FIELD_END_DATE =
  'The end date must be greater than the start date';
export const ERROR_FIELD_DATE_GRATER_THEN_TODAY =
  'The selected date cannot be greater than today';
export const ERROR_FIELD_PHOTO_REQUIRED = 'Photo is required';
export const ERROR_FIELD_PHOTO_FORMAT = 'Invalid file format';
export const ERROR_FIELD_EDUCATION_REQUIRED = 'Education is required';
export const ERROR_FIELD_EXPERIENCE_REQUIRED = 'Experience is required';
export const ERROR_FIELD_EMAIL_VALIDATION = 'Please enter a valid email.';
export const ERROR_FIELD_EMAIL_REQUIRED = 'Email is required';
export const ERROR_FIELD_FULL_NAME = 'Please enter a full name.';
export const ERROR_FIELD_PASSWORD =
  'Passwords must have at least 8 characters, 1 uppercase, 1 lowercase, 1 number and one special symbol (!?-_)';

export const ERROR_FIELD_INTEGER = 'This field must a integer';
