import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowBack } from '../../../assets/img/icon/arrow-back.svg';
import { ReactComponent as ArrowForward } from '../../../assets/img/icon/arrow-forward.svg';
import classes from './NumberField.module.scss';

interface Props {
  value?: number;
  onChange?: (v: number) => void;
  max?: number;
  min?: number;
  fulWidth?: boolean;
}

const NumberField: React.FC<Props> = (props) => {
  const { value, onChange, max = Infinity, min = -Infinity, fulWidth } = props;
  const [val, setVal] = useState(value || 0);

  useEffect(() => {
    if (typeof value === 'number' && value !== val) {
      setVal(value);
    }
  }, [value]);

  const handleChange = (v: number) => {
    if (v <= max && v >= min) {
      setVal(v);
      if (onChange) {
        onChange(v);
      }
    }
  };

  return (
    <div
      className={classNames(classes.counter, { [classes.fullWidth]: fulWidth })}
    >
      <button
        className={classes.btn}
        type="button"
        onClick={() => {
          handleChange(val - 1);
        }}
        disabled={val <= min}
      >
        <ArrowBack />
      </button>
      <input
        className={classes.input}
        value={val}
        onChange={(e) => {
          handleChange(Number(e.target.value));
        }}
        type="number"
      />
      <button
        className={classes.btn}
        type="button"
        onClick={() => {
          handleChange(val + 1);
        }}
        disabled={val >= max}
      >
        <ArrowForward />
      </button>
    </div>
  );
};

export default NumberField;
