import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  requestChatSparring,
  requestConversations,
  requestMessages,
  requestChatSparringCompleteRound,
  requestChatSparringKo,
} from '../../repositories/chat';
import {
  ChatSparing,
  IChatMedia,
  IChatPagination,
  IChatWithDetails,
  IMessage,
} from '../../types/chat';
import { rateUser } from '../../repositories/user';

export const getMessages = createAsyncThunk(
  'getMessages',
  async (params: { page: number; userExternalId: number }) => {
    const response = await requestMessages(params);
    return response;
  }
);

export const sendMessage = createAction<{
  text: string;
  userExternalId: number;
  files: string[];
}>('sendMessage');
export const getMessage = createAction<IMessage>('getMessage');

export const setMessagesStatus = createAction<{
  loading?: boolean;
  error?: string;
}>('setMessagesStatus');

export const getChatByUserId = createAction<{ id: number }>('getChatByUserId');

export const getChatsList = createAsyncThunk(
  'getChatsList',
  async (params: { page: number; userExternalId?: number; name?: string }) => {
    const response = await requestConversations(params);
    return response;
  }
);

export const setChatsList = createAction<IChatWithDetails[]>('setChatsList');
export const setChatsPagination = createAction<IChatPagination | null>(
  'setChatsPagination'
);
export const setChatsStatus = createAction<{
  loading?: boolean;
  error?: string;
}>('setChatsStatus');

export const setCurrentChat = createAction<string | null>('setCurrentChat');

export const resetCurrentChat = createAction('resetCurrentChat');

export const readChat = createAction<number>('readChat');

export const setSparring = createAction<ChatSparing>('setSparring');
export const setKo = createAction<number>('setKo');

export const setChatMedia = createAction<
  (Omit<IChatMedia, 'viewer'> & Partial<Pick<IChatMedia, 'viewer'>>) | null
>('setChatMedia');

export const setUnreadMessages = createAction<number>('setUnreadMessages');
export const readMessages = createAction<string>('readMessages');

export const getChatSparring = createAsyncThunk(
  'getChatSparring',
  async (id: number) => {
    const response = await requestChatSparring(id);
    return response;
  }
);

export const chatSparringComplateRound = createAsyncThunk(
  'chatSparringComplateRound',
  async (id: number) => {
    const response = await requestChatSparringCompleteRound(id);
    return response;
  }
);

export const chatSparringKo = createAsyncThunk(
  'chatSparringKo',
  async (payload: { id: number; note?: string }) => {
    const response = await requestChatSparringKo({
      user_id: payload.id,
      note: payload.note,
    });
    return response;
  }
);

export const chatSparringRate = createAsyncThunk(
  'chatSparringRate',
  async (payload: {
    user_id: number;
    vision?: number;
    strategy?: number;
    technical?: number;
    problem_solving?: number;
    communication?: number;
    listening?: number;
    empathy?: number;
    creativity?: number;
    inspirational?: number;
    humility?: number;
    note?: string;
  }) => {
    const response = await rateUser(payload);
    return response;
  }
);
