import React from 'react';
import classNames from 'classnames';
import Button from '../Button';
import { ReactComponent as Close } from '../../../assets/img/icon/close.svg';
import { Row, Col } from '../Grid';
import classes from './ExperienceItem.module.scss';

interface IProps {
  onEdit?: () => void;
  onDelete?: () => void;
  title: string;
  subtitle: string;
  date: string | number;
  caption?: string;
  description?: string;
}

const ExperienceItem: React.FC<IProps> = (props) => {
  const { onEdit, onDelete, title, subtitle, date, description, caption } =
    props;
  return (
    <div className={classes.item}>
      <Row
        justifyContent="space-between"
        alignItems="center"
        direction="row-reverse"
      >
        <Col md={3}>
          <div className={classes.button__box}>
            {onEdit && (
              <Button
                size="xs"
                color="secondary"
                variant="contained"
                onClick={onEdit}
              >
                EDIT
              </Button>
            )}
            {onDelete && (
              <Button
                size="xs"
                color="error"
                variant="contained"
                onClick={onDelete}
              >
                <Close />
              </Button>
            )}
          </div>
        </Col>
        <Col className={classes.name} md={9}>
          {title}
        </Col>
      </Row>
      <Row alignItems="center" className={classes.row}>
        <Col sm={5} className={classes.level}>
          {subtitle}
        </Col>
        <Col sm={4} className={classes.secondaryText}>
          {date}
        </Col>
        <Col
          sm={3}
          className={classNames(classes.secondaryText, classes.textRight)}
        >
          {caption}
        </Col>
      </Row>
      <div>{description}</div>
    </div>
  );
};

export default ExperienceItem;
