import { ISubscription } from './subscriptions';

export enum IUserStatus {
  active = 1,
  blocked,
  onboarding,
}

export interface IUser {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  language_id: number | null;
  city_id: number | null;
  status: IUserStatus;
  user_detail: {
    about: string;
  } | null;
  photo_url: string | null;
  interaction_types: { id: number; name: string }[] | null;
  interests: { id: number; name: string }[] | null;
  looking_occupations: { id: number; name: string }[] | null;
  language: { id: number; name: string } | null;
  city: {
    id: number;
    name: string;
    country: {
      id: number;
      name: string;
    };
  } | null;
  onboarding_fields: string[] | null;
  subscription_user: {
    name: string;
    subscription: ISubscription;
    expired_at: number | null;
  } | null;
  user_sum_rating: null | IRating;
  sparring_knock_outs_percentage: number | null;
  sparring_users_count: number | null;
}

export interface IUserUpdatableFields
  extends Pick<
    IUser,
    'first_name' | 'last_name' | 'language_id' | 'city_id' | 'user_detail'
  > {
  interests: number[];
  looking_occupations: number[];
  interaction_types: number[];
}

export interface IEducation {
  id: number;
  school: string;
  degree: string;
  grade: string;
  field_study: string;
  start_date: number;
  end_date: number;
}

export interface IExperience {
  id: number;
  title: string;
  company_name: string;
  location: string;
  headline: string;
  industry: string;
  description: string;
  start_date: number;
  end_date: number;
  employment_type_id: number;
}

export interface ISettings {
  user_available_occupations: number[];
  scale_search: number;
}

export interface IRating {
  user_id: number;
  strategy: number;
  technical: number;
  problem_solving: number;
  communication: number;
  listening: number;
  empathy: number;
  creativity: number;
  vision: number;
  inspirational: number;
  total: number;
  humility: number;
}
