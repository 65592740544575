import React from 'react';
import ReactDOM from 'react-dom';

interface IProps {
  children?: React.ReactNode;
  container?: Element | DocumentFragment;
}

const Portal: React.FC<IProps> = (props) => {
  const { children, container = document.body } = props;
  return ReactDOM.createPortal(children, container);
};

export default Portal;
