import axios, { Response } from '../libs/axios';
import { ISettings } from '../types/user';

export const requestSettings = () =>
  axios<Response<ISettings>>({
    url: '/user/settings',
    method: 'GET',
  });

export const updateSettings = (data: ISettings) =>
  axios<Response<ISettings>>({
    url: '/user/settings',
    method: 'PUT',
    data,
  });
