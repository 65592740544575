import { getFile } from '../repositories/general';

const useFileDownloader = () => (url: string, filename?: string) => {
  getFile(url).then((response) => {
    const href = URL.createObjectURL(response);
    const link = document.createElement('a');
    link.href = href;
    if (filename) {
      link.setAttribute('download', filename);
    }

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

export default useFileDownloader;
