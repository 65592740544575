import React from 'react';
import Logo from '../../elements/Logo';
import { ReactComponent as Menu } from '../../../assets/img/icon/menu.svg';
import classes from './HeaderInner.module.scss';
import UserMenu from '../UserMenu';
import Container from '../../elements/Container';
import { useDispatch } from '../../../libs/redux';
import { toggleSideMenu } from '../../../store/actions/side-menu';
import useWindowSize from '../../../hooks/useWindowSize';
import { breakpoints } from '../../../styles/breakpoints';

interface IProps {
  title?: React.ReactNode;
}

const HeaderInner: React.FC<IProps> = (props) => {
  const { title } = props;
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  return (
    <header className={classes.header}>
      <Container fullWidth>
        <div className={classes.header__wrap}>
          <div className={classes.title}>{title}</div>
          <div className={classes.logo}>
            <Logo />
          </div>
          <button
            type="button"
            className={classes.menu}
            onClick={() => dispatch(toggleSideMenu(true))}
          >
            <Menu />
          </button>
          <div className={classes.header__nav}>
            <UserMenu displyName={Boolean(width && width >= breakpoints.md)} />
          </div>
        </div>
      </Container>
    </header>
  );
};

export default HeaderInner;
