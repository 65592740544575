import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { handleError422 } from '../../helpers';
import {
  requestTopics,
  addTopic as addTopicByApi,
  deleteTopic as deleteTopicByApi,
  updateTopic,
  getTopicById,
} from '../../repositories/topics';
import { IError422 } from '../../types/errors';
import { IPaginationParams, ISortOption } from '../../types/general';
import { IBaseTopic, ITopic, ITopicFull } from '../../types/topic';
import { addFastNotification } from './notifications';

export const getTopics = createAsyncThunk(
  'getTopics',
  async (
    payload: IPaginationParams & {
      sort?: ISortOption<ITopic>;
    }
  ) => {
    const { page, sort } = payload;
    const response = await requestTopics({
      page,
      sort: sort
        ? `${sort.direction === 'desc' ? '-' : ''}${sort.key}`
        : undefined,
      include: 'topic_tags,users_count',
    });
    return response;
  }
);

export const deleteTopic = createAsyncThunk(
  'deleteTopic',
  async (id: number) => {
    const response = await deleteTopicByApi(id);
    return response.data;
  }
);

export const addTopic = createAsyncThunk<
  ITopic,
  Omit<IBaseTopic, 'topic_tags'> & {
    topic_tags: number[];
  },
  { rejectValue: Partial<Record<keyof ITopic, string>> }
>('addTopic', async (payload, { rejectWithValue }) => {
  try {
    const response = await addTopicByApi(payload);
    return response.data;
  } catch (error) {
    const err = error as IError422;
    if (err.errors) {
      return rejectWithValue(handleError422(err));
    }
    return rejectWithValue({});
  }
});

export const editTopic = createAsyncThunk<
  ITopicFull,
  Omit<IBaseTopic, 'topic_tags'> & {
    topic_tags: number[];
  } & Pick<ITopic, 'id'>,
  { rejectValue: Partial<Record<keyof ITopic, string>> }
>('editExperience', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const { id, ...rest } = payload;
    const response = await updateTopic(id, rest);
    dispatch(
      addFastNotification({
        content: `${response.data.name} Successfull updated!`,
        color: 'success',
      })
    );
    return response.data;
  } catch (error) {
    const err = error as IError422;
    if (err.errors) {
      return rejectWithValue(handleError422(err));
    }
    return rejectWithValue({});
  }
});
export const getTopic = createAsyncThunk<
  ITopicFull,
  number,
  { rejectValue: Partial<Record<keyof ITopic, string>> }
>('getTopic', async (payload, { rejectWithValue }) => {
  try {
    const response = await getTopicById(payload);
    return response.data;
  } catch (error) {
    const err = error as IError422;
    if (err.errors) {
      return rejectWithValue(handleError422(err));
    }
    return rejectWithValue({});
  }
});

export const setTopic = createAction<ITopicFull | null>('setTopic');

export const setCurrentPage = createAction<number>('setCurrentPage');
export const setCurrentSort =
  createAction<ISortOption<ITopic>>('setCurrentSort');
