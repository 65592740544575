import { createReducer } from '@reduxjs/toolkit';
import { IPagination } from '../../types/general';
import { ISparring, ISparringRequest } from '../../types/sparring';
import { clearAuthData } from '../actions/authentication';
import {
  getSparrings,
  handleSparring,
  sendSparringRequest,
  setSparringRequestErrors,
} from '../actions/sparrings';

interface State {
  list: ISparring[];
  error: string;
  pagination: IPagination;
  loading: boolean;
  sending: boolean;
  requestErrors: Partial<Record<keyof ISparringRequest, string>>;
}

const defaultPagination: IPagination = {
  total: 0,
  from: 1,
  to: 1,
  current_page: 1,
  last_page: 1,
  per_page: 10,
};

const initialState: State = {
  list: [],
  error: '',
  pagination: defaultPagination,
  loading: false,
  sending: false,
  requestErrors: {},
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(getSparrings.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getSparrings.fulfilled, (state, action) => {
    const pagination = action.payload.meta || defaultPagination;

    state.loading = false;

    if (!state.list.length) {
      state.list = Array(pagination.total);
    }

    state.list.splice(
      (action.meta.arg ? action.meta.arg.page || 1 : 1) * pagination.per_page -
        pagination.per_page,
      pagination.per_page,
      ...action.payload.data
    );
    if (action.payload.meta) {
      state.pagination = action.payload.meta;
    }
  });
  builder.addCase(getSparrings.rejected, (state) => {
    state.loading = false;
  });
  builder.addCase(handleSparring.pending, (state) => {
    state.sending = true;
  });

  builder.addCase(handleSparring.fulfilled, (state, action) => {
    state.list = state.list.filter((item) => item.id !== action.meta.arg.id);
    state.pagination.total -= 1;
    state.sending = false;
  });
  builder.addCase(handleSparring.rejected, (state) => {
    state.sending = false;
  });

  builder.addCase(sendSparringRequest.pending, (state) => {
    state.sending = true;
    state.requestErrors = {};
  });
  builder.addCase(sendSparringRequest.fulfilled, (state) => {
    state.sending = false;
  });
  builder.addCase(sendSparringRequest.rejected, (state, action) => {
    state.sending = false;
    if (action.payload) {
      state.requestErrors = action.payload;
    }
  });
  builder.addCase(setSparringRequestErrors, (state, action) => {
    state.requestErrors = action.payload;
  });
  builder.addCase(clearAuthData, () => initialState);
});

interface Store {
  sparrings: State;
}

export const selectSparrings = (state: Store) => state.sparrings;

export default reducer;
