import React from 'react';
import { useNavigate } from 'react-router-dom';
import { selectUser } from '../../../store/reducers/user';
import Button from '../../elements/Button';
import Logo from '../../elements/Logo';
import classes from './Header.module.scss';
import Container from '../../elements/Container';
import UserMenu from '../UserMenu';
import HomePageMenu from '../HomePageMenu';
import { SIGN_IN, SIGN_UP } from '../../../constants/routes';
import { useSelector } from '../../../libs/redux';

const Header: React.FC = () => {
  const { user } = useSelector(selectUser);
  const navigate = useNavigate();
  return (
    <header className={classes.header}>
      <Container>
        <div className={classes.header__wrap}>
          <Logo className={classes.logo} />
          <div className={classes.header__nav}>
            <HomePageMenu />
            {user ? (
              <UserMenu />
            ) : (
              <div className={classes.btn__group}>
                <Button onClick={() => navigate(SIGN_UP.fullPath)}>
                  SIGN UP
                </Button>
                <Button
                  onClick={() => navigate(SIGN_IN.fullPath)}
                  variant="contained"
                >
                  SIGN IN
                </Button>
              </div>
            )}
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
