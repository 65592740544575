import React from 'react';
import Progress from '../../elements/Progress';
import classes from './RatingDetails.module.scss';

export interface RatingDetailsProps {
  list: { value: number; name: string }[];
}

const RatingDetails: React.FC<RatingDetailsProps> = (props) => {
  const { list } = props;

  const maxValue = list.reduce((acc, item) => Math.max(acc, item.value), 0);
  return (
    <table className={classes.wrap}>
      <tbody>
        {list.map((item) => (
          <tr key={item.name}>
            <td>{item.name}:</td>
            <td className={classes.progress}>
              <Progress value={(item.value / maxValue) * 100} size={20} />
            </td>
            <td>{item.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RatingDetails;
