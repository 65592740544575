import classNames from 'classnames';
import React from 'react';
import Card, { CardProps } from '../Card/Card';
import classes from './InnerPageCard.module.scss';

interface IProps extends CardProps {
  size?: 'small' | 'big';
}

const InnerPageCard: React.FC<IProps> = (props) => {
  const { size = 'big', children, className } = props;
  return (
    <Card className={classNames(className, classes[size])} noPadding>
      <div className={classes.cardBody}>{children}</div>
    </Card>
  );
};

export default InnerPageCard;
