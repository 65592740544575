import React from 'react';
import { Card, CardBody } from '../Card';
import CardHeader from '../Card/CardHeader';
import Logo from '../Logo';
import classes from './CardWithLogo.module.scss';

interface IProps {
  children?: React.ReactNode;
}

const CardWithLogo: React.FC<IProps> = (props) => {
  const { children } = props;

  return (
    <Card>
      <CardHeader>
        <div className={classes.logo}>
          <Logo />
        </div>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
};

export default CardWithLogo;
