import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestSubscriptions } from '../../repositories/subscriptions';

export const getSubscriptions = createAsyncThunk(
  'getSubscriptions',
  async () => {
    const response = await requestSubscriptions();
    return response;
  }
);

export const getSubscriptionssss = createAsyncThunk(
  'getSubscriptions',
  async () => {
    const response = await requestSubscriptions();
    return response;
  }
);
