import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import classes from './Rating.module.scss';
import Button from '../../elements/Button';
import RatingDetails from '../RatingDetails';
import Modal from '../../elements/Modal';
import { IRating } from '../../../types/user';
import { fieldNames } from '../../../constants/rating';
import { ISubscription } from '../../../types/subscriptions';

export interface RatingProps {
  rating: IRating | null;
  name: string;
  ko: number;
  communications: number;
  variant?: 'horizontal' | 'vertical';
  suggestUpdate?: ISubscription;
}

const Rating: React.FC<RatingProps> = (props) => {
  const {
    rating,
    variant = 'horizontal',
    ko,
    name,
    communications,
    suggestUpdate,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { user_id: i, total, ...clearRating } = rating || {};
  return (
    <div>
      <div
        className={classNames(classes.wrap, classes[variant], {
          [classes.center]: !rating,
        })}
      >
        RATING
        {rating && (
          <>
            <div className={classes.value}>{rating.total}</div>
            <Button
              size="xs"
              variant="contained"
              onClick={() => setIsModalOpen(true)}
              className={classes.btn}
              fullWidth
            >
              VIEW
            </Button>
          </>
        )}
      </div>
      {suggestUpdate &&
        suggestUpdate !== ISubscription.greatMind &&
        suggestUpdate !== ISubscription.pro && (
          <div className={classes.upgrade}>
            <Link to={{ hash: 'subscription' }}>
              Upgrade to{' '}
              {suggestUpdate === ISubscription.amateur ? 'Share' : 'View'}
            </Link>
          </div>
        )}
      <Modal
        open={Boolean(isModalOpen)}
        onClose={() => setIsModalOpen(false)}
        size="md"
        fullWidth
      >
        {isModalOpen && (
          <div className={classes.ratingModal}>
            <h3>{name}&apos;s</h3>
            <div className={classes.ko}>
              <div>
                Based on <b>{communications}</b> communications.
              </div>
              {ko}% of Kos.
            </div>

            <RatingDetails
              list={Object.entries(clearRating).map(
                (item: [string, number]) => ({
                  name: fieldNames[item[0]] || 'unknown',
                  value: item[1],
                })
              )}
            />
            <Button
              color="default"
              className={classes.btn}
              onClick={() => setIsModalOpen(false)}
            >
              CLOSE
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Rating;
