import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ILayoutTitle, InnerLayoutCantext } from '../../../types/layouts';
import Button from '../../elements/Button';
import Container from '../../elements/Container';
import HeaderInner from '../../modules/HeaderInner';
import NewPWAVersion from '../../modules/NewPWAVersion';
import NotificationsList from '../../modules/NotificationsList';
import SideMenu from '../../modules/SideMenu';
import RootLayout, { RootLayoutProps } from '../Root';
import classes from './Inner.module.scss';
import Subscriptions from '../../modules/Subscriptions';

interface IProps extends RootLayoutProps {}

const emptyTitle: ILayoutTitle = {
  title: '',
  fullpath: '',
};

const InnerLayout: React.FC<IProps> = (props) => {
  const { children, pageTitle } = props;
  const context: InnerLayoutCantext = useState<ILayoutTitle>(emptyTitle);
  const [title, setTitle] = context;
  const { pathname } = useLocation();

  useEffect(() => {
    setTitle({ ...emptyTitle, fullpath: pathname });
  }, [pathname]);

  const nodeTitle = (
    <div className={classes.btnWrap}>
      <h1>{title.title}</h1>
      {title.button && (
        <Button onClick={title.button.onClick} to={title.button.to}>
          {title.button.text}
        </Button>
      )}
    </div>
  );

  return (
    <RootLayout pageTitle={pageTitle}>
      <div className={classes.wrap}>
        <SideMenu />
        <main className={classes.container}>
          <HeaderInner title={nodeTitle} />
          <section className={classes.mobTitle}>
            <Container>{nodeTitle}</Container>
          </section>
          <Container fullWidth className={classes.mainContent}>
            {children}
            <Outlet context={context} />
          </Container>
        </main>
      </div>
      <NotificationsList />
      <NewPWAVersion />
      <Subscriptions />
    </RootLayout>
  );
};

export default InnerLayout;
