import { createReducer } from '@reduxjs/toolkit';
import { IEducation, IExperience, IUser } from '../../types/user';
import { clearAuthData } from '../actions/authentication';
import {
  getMember,
  getMemberEducation,
  getMemberExperience,
} from '../actions/member';

interface State {
  member: IUser | null;
  education: IEducation[];
  experience: IExperience[];
  error: string;
  loading: boolean;
  updating: boolean;
}

const initialState: State = {
  member: null,
  education: [],
  experience: [],
  error: '',
  loading: false,
  updating: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(getMember.pending, (state) => {
    state.member = null;
    state.loading = true;
    state.error = '';
  });
  builder.addCase(getMember.fulfilled, (state, action) => {
    state.member = action.payload;
    state.loading = false;
  });
  builder.addCase(getMember.rejected, (state, action) => {
    state.loading = false;
    if (action.payload) {
      state.error = action.payload;
    }
  });

  builder.addCase(getMemberEducation.pending, (state) => {
    state.education = [];
  });
  builder.addCase(getMemberEducation.fulfilled, (state, action) => {
    state.education = action.payload;
  });

  builder.addCase(getMemberExperience.pending, (state) => {
    state.experience = [];
  });
  builder.addCase(getMemberExperience.fulfilled, (state, action) => {
    state.experience = action.payload;
  });

  builder.addCase(clearAuthData, () => initialState);
});

interface Store {
  member: State;
}

export const selectMember = (state: Store) => state.member;

export default reducer;
