import React from 'react';
import classNames from 'classnames';
import classes from './Modal.module.scss';
import Portal from '../Portal';
import { ReactComponent as Close } from '../../../assets/img/icon/close.svg';
import { Sizes } from '../../../types/general';
import ScrollBar from '../ScrollBar';

interface IProps {
  open: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  size?: Sizes;
  backdrop?: boolean;
  fullWidth?: boolean;
  className?: string;
}

const Modal: React.FC<IProps> = (props) => {
  const {
    open,
    onClose,
    size = 'auto',
    children,
    backdrop = true,
    fullWidth = false,
    className,
  } = props;

  return (
    <Portal>
      {open && (
        <div className={classes.wrap}>
          {backdrop && (
            <div
              className={classes.shadow}
              onClick={onClose}
              onKeyPress={onClose}
              role="button"
              tabIndex={0}
              aria-label="close modal"
            />
          )}

          <div
            className={classNames(classes.popup, className, classes[size], {
              [classes.fullWidth]: fullWidth,
            })}
          >
            {onClose && (
              <button className={classes.close} type="button" onClick={onClose}>
                <Close />
              </button>
            )}
            <div className={classes.content}>
              <ScrollBar maxHeight="calc(100vh - 36px)">{children}</ScrollBar>
            </div>
          </div>
        </div>
      )}
    </Portal>
  );
};

export default Modal;
