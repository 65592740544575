import React from 'react';
import LandingLayout from '../../components/layouts/Landing';
import HomeTemplate from '../../components/templates/Home';

const HomePage: React.FC = () => (
  <LandingLayout>
    <HomeTemplate />
  </LandingLayout>
);
export default HomePage;
