import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { formatTimeToMs } from '../../helpers/time';
import { getUserById } from '../../repositories/user';
import { requestEducationByUserId } from '../../repositories/user-education';
import { requestExperienceByUserId } from '../../repositories/user-experience';
import { IEducation, IExperience, IUser } from '../../types/user';

export const setMember = createAction<IUser | null>('setMember');

export const getMember = createAsyncThunk<
  IUser,
  number,
  { rejectValue: string }
>('getMember', async (id: number, { rejectWithValue }) => {
  try {
    const response = await getUserById(id);
    return response.data;
  } catch (error) {
    return rejectWithValue('Error');
  }
});

export const getMemberEducation = createAsyncThunk<
  IEducation[],
  number,
  { rejectValue: string }
>('getMemberEducation', async (id: number, { rejectWithValue }) => {
  try {
    const response = await requestEducationByUserId(id);
    return response.data.map((item) => formatTimeToMs(item));
  } catch (error) {
    return rejectWithValue('Error');
  }
});

export const getMemberExperience = createAsyncThunk<
  IExperience[],
  number,
  { rejectValue: string }
>('getMemberExperience', async (id: number, { rejectWithValue }) => {
  try {
    const response = await requestExperienceByUserId(id);
    return response.data.map((item) => formatTimeToMs(item));
  } catch (error) {
    return rejectWithValue('Error');
  }
});
