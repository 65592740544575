import { createReducer } from '@reduxjs/toolkit';
import { toggleSideMenu } from '../actions/side-menu';

interface State {
  isOpen: boolean;
}

const initialState: State = {
  isOpen: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(toggleSideMenu, (state, action) => {
    state.isOpen = action.payload;
  });
});

interface Store {
  sideMenu: State;
}

export const selectSideMenu = (state: Store) => state.sideMenu;

export default reducer;
