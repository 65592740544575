import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { uid } from 'uid';
import { INotification } from '../../types/notifications';

export const addFastNotification = createAsyncThunk(
  'addFastNotification',
  (payload: Omit<INotification, 'id'>) => ({
    id: uid(),
    ...payload,
  })
);
export const addFastNotificationsGroup = createAction<INotification[]>(
  'addFastNotificationsGroup'
);

export const deleteFastNotification =
  createAction<string>('deleteNotification');
export const deleteAllFastNotifications = createAction(
  'deleteAllFastNotifications'
);

export const setIsNewVersionAvailable = createAction<boolean>(
  'setIsNewVersionAvailable'
);
