import { createReducer } from '@reduxjs/toolkit';
import { IPagination, ISortOption } from '../../types/general';
import { SparringStatus } from '../../types/sparring';
import { ITopic } from '../../types/topic';
import { clearAuthData } from '../actions/authentication';
import { setCurrentPage } from '../actions/search';
import { handleSparring } from '../actions/sparrings';
import {
  addTopic,
  deleteTopic,
  editTopic,
  getTopics,
  setCurrentSort,
} from '../actions/topics';

interface State {
  list: ITopic[];
  sort: ISortOption<ITopic>;
  errors: Partial<Record<keyof ITopic, string>>;
  pagination: IPagination;
  loading: boolean;
  loaded: boolean;
}

const defaultSort: ISortOption<ITopic> = {
  direction: 'desc',
  key: 'created_at',
};

const defaultPagination: IPagination = {
  current_page: 1,
  total: 1,
  from: 1,
  to: 1,
  last_page: 1,
  per_page: 1,
};

const initialState: State = {
  list: [],
  sort: defaultSort,
  errors: {},
  pagination: defaultPagination,
  loaded: false,
  loading: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(getTopics.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getTopics.fulfilled, (state, action) => {
    const pagination = action.payload.meta || defaultPagination;

    state.loading = false;

    if (!state.list.length) {
      state.list = Array(pagination.total);
    }
    state.list.splice(
      (action.meta.arg.page || 1) * pagination.per_page - pagination.per_page,
      pagination.per_page,
      ...action.payload.data
    );
    if (action.payload.meta) {
      state.pagination = action.payload.meta;
    }
  });
  builder.addCase(getTopics.rejected, (state) => {
    state.loading = false;
  });

  builder.addCase(addTopic.pending, (state) => {
    state.loading = true;
    state.errors = {};
  });
  builder.addCase(addTopic.fulfilled, (state) => {
    state.loading = false;
    state.list = [];
    state.pagination = defaultPagination;
  });
  builder.addCase(addTopic.rejected, (state, action) => {
    state.loading = false;
    if (action.payload) {
      state.errors = action.payload;
    }
  });

  builder.addCase(editTopic.pending, (state) => {
    state.loading = true;
    state.errors = {};
  });
  builder.addCase(editTopic.fulfilled, (state, action) => {
    const index = state.list.findIndex((item) => item.id === action.payload.id);
    state.loading = false;
    if (index > -1) {
      state.list[index] = { ...state.list[index], ...action.payload };
    }
  });
  builder.addCase(editTopic.rejected, (state, action) => {
    state.loading = false;
    if (action.payload) {
      state.errors = action.payload;
    }
  });

  builder.addCase(deleteTopic.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(deleteTopic.fulfilled, (state, action) => {
    state.loading = false;
    state.list = state.list.filter((item) => item.id !== action.meta.arg);
    state.pagination.total -= 1;
  });
  builder.addCase(deleteTopic.rejected, (state) => {
    state.loading = false;
  });

  builder.addCase(setCurrentPage, (state, action) => {
    state.pagination.current_page = action.payload;
  });
  builder.addCase(setCurrentSort, (state, action) => {
    state.sort = action.payload;
    state.list = [];
  });
  builder.addCase(handleSparring.fulfilled, (state, action) => {
    if (action.meta.arg.status === SparringStatus.accepted) {
      state.list = [];
    }
  });
  builder.addCase(clearAuthData, () => initialState);
});

interface Store {
  topics: State;
}

export const selectTopics = (state: Store) => state.topics;

export default reducer;
