import React from 'react';
import classNames from 'classnames';
import classes from './Chip.module.scss';
import { ReactComponent as Close } from '../../../assets/img/icon/close.svg';
import { Colors, Sizes } from '../../../types/general';

interface ChipProps {
  children?: React.ReactNode;
  onDelete?: () => void;
  className?: string;
  color?: Colors;
  variant?: 'outlined' | 'contained';
  size?: Sizes;
  fullWidth?: boolean;
}

const Chip: React.FC<ChipProps> = (props) => {
  const {
    children,
    onDelete,
    className,
    variant = 'outlined',
    color = 'primary',
    size = 'md',
    fullWidth = false,
  } = props;

  return (
    <div
      className={classNames(
        classes.chip,
        className,
        classes[color],
        classes[variant],
        classes[size],
        {
          [classes.fullWidth]: fullWidth,
        }
      )}
    >
      {children}
      {onDelete && (
        <button type="button" className={classes.close} onClick={onDelete}>
          <Close />
        </button>
      )}
    </div>
  );
};

export default Chip;
