import axios, { Response } from '../libs/axios';
import { IHomePage, IMenu, IPage } from '../types/dynamic-pages';

export const requestMenus = () =>
  axios<Response<IMenu[]>>({
    url: '/menus',
  });

export const requestPage = (slug: string) =>
  axios<Response<IPage>>({
    url: `/pages/${slug}`,
  });

export const requestHomePage = () =>
  axios<Response<IHomePage>>({
    url: '/pages/home',
  });
