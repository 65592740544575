import React from 'react';
import PrivateRoute from '../../components/elements/PrivateRoute';
import SignUpTemplate from '../../components/templates/SignUp';
import { Access } from '../../types/general';

const SignUpPage: React.FC = () => (
  <PrivateRoute access={Access.notAuthenticated}>
    <SignUpTemplate />
  </PrivateRoute>
);

export default SignUpPage;
