import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducers';
import webSocket from './middlewares/web-socket';

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(webSocket),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
