import axios, { Response } from '../libs/axios';
import { IExperience } from '../types/user';

export const requestExperience = () =>
  axios<Response<IExperience[]>>({
    url: '/user-experiences',
    method: 'GET',
    params: {
      all: 1,
    },
  });

export const addExperience = (data: Omit<IExperience, 'id'>) =>
  axios<Response<IExperience>>({
    url: '/user-experiences',
    method: 'POST',
    data,
  });

export const updateExperience = (id: number, data: Omit<IExperience, 'id'>) =>
  axios<Response<IExperience>>({
    url: `/user-experiences/${id}`,
    method: 'PUT',
    data,
  });

export const removeExperience = (id: number) =>
  axios<Response<IExperience>>({
    url: `/user-experiences/${id}`,
    method: 'DELETE',
  });

export const requestExperienceByUserId = (id: number) =>
  axios<Response<IExperience[]>>({
    url: `/user/${id}/user-experiences`,
    method: 'GET',
    params: {
      all: 1,
    },
  });
