import { useEffect } from 'react';
import { useDispatch, useSelector } from '../libs/redux';
import { getBaseContent } from '../store/actions/dynamic-content';
import { selectDynamicContent } from '../store/reducers/dynamic-content';
import { IDynamicContentKeys } from '../types/dynamic-content';

interface RequestItem {
  key: IDynamicContentKeys;
  data?: number;
  search?: string;
}

type Request = IDynamicContentKeys | RequestItem;

const requested: RequestItem[] = [];

const useDynamicContent = (params: Request[]) => {
  const dynamicContent = useSelector(selectDynamicContent);
  const dispatch = useDispatch();
  useEffect(() => {
    params.forEach((item) => {
      const data = typeof item === 'string' ? { key: item } : item;
      const index = requested.findIndex((r) => r.key === data.key);
      const isDifferentData =
        requested.filter(
          (r) =>
            r.key === data.key &&
            (r.data !== data.data || r.search !== data.search)
        ).length > 0;
      if (
        (index < 0 || isDifferentData) &&
        (!dynamicContent[data.key].loaded || isDifferentData) &&
        !dynamicContent[data.key].loading
      ) {
        dispatch(getBaseContent(data));
        if (isDifferentData) {
          requested[index] = data;
        } else {
          requested.push(data);
        }
      }
    });
  }, [params]);

  return dynamicContent;
};

export default useDynamicContent;
