import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { EMAIL } from '../../../constants/regexp';
import Button from '../../elements/Button';
import FormGroup from '../../elements/FormGroup';
import TextField from '../../elements/TextField';
import classes from './ContactForm.module.scss';
import { requestContact } from '../../../repositories/general';

const ContactForm: React.FC = () => {
  const [status, setStatus] = useState<'new' | 'inProgress' | 'sent'>('new');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    name: string;
    email: string;
    subject: string;
    message: string;
  }>();
  const fields = {
    name: register('name', {
      required: 'Name is required',
    }),
    email: register('email', {
      required: 'Email is required',
      pattern: { value: EMAIL, message: 'Please enter a valid email.' },
    }),
    subject: register('subject', {
      required: 'Subject is required',
      maxLength: {
        value: 255,
        message: 'Maximum length is 255 characters',
      },
    }),
    message: register('message', {
      required: 'Message is required',
      maxLength: {
        value: 2000,
        message: 'Maximum length is 2000 characters',
      },
    }),
  };
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const onSubmit = async (data: {
    name: string;
    email: string;
    subject: string;
    message: string;
  }) => {
    if (recaptchaRef && recaptchaRef.current) {
      const token = await recaptchaRef.current.executeAsync();
      setStatus('inProgress');
      requestContact({ ...data, re_captcha: token || '' }).then(() =>
        setStatus('sent')
      );
      recaptchaRef.current.execute();
    }
  };

  return (
    <form className={classes.wrap} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.row}>
        <div className={classes.col}>
          <FormGroup>
            <TextField
              fullWidth
              label="Name"
              name={fields.name.name}
              onChange={fields.name.onChange}
              inputRef={fields.name.ref}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
            />
          </FormGroup>
        </div>
        <div className={classes.col}>
          <FormGroup>
            <TextField
              fullWidth
              label="Email"
              name={fields.email.name}
              onChange={fields.email.onChange}
              inputRef={fields.email.ref}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
            />
          </FormGroup>
        </div>
      </div>
      <FormGroup>
        <TextField
          fullWidth
          label="Subject"
          name={fields.subject.name}
          onChange={fields.subject.onChange}
          inputRef={fields.subject.ref}
          error={Boolean(errors.subject)}
          helperText={errors.subject?.message}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          label="Message"
          multiline
          name={fields.message.name}
          onChange={fields.message.onChange}
          textareaRef={fields.message.ref}
          error={Boolean(errors.message)}
          helperText={errors.message?.message}
        />
      </FormGroup>
      {status === 'sent' && (
        <h3>Your request has been successfully accepted</h3>
      )}
      <div className={classes.btnWrap}>
        <Button
          variant="contained"
          type="submit"
          size="lg"
          shadow
          minWidth={300}
          disabled={status !== 'new'}
        >
          SUBMIT
        </Button>
      </div>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
        size="invisible"
        ref={recaptchaRef}
      />
    </form>
  );
};

export default ContactForm;
