import React from 'react';
import Button from '../../elements/Button';
import classes from './Onboarding.module.scss';

interface IProps {
  nextBtn?: {
    text?: string;
    onClick: () => void;
  };
  prevBtn?: {
    text?: string;
    onClick: () => void;
  };
}

const StepsNavigation: React.FC<IProps> = (props) => {
  const { nextBtn, prevBtn } = props;
  return (
    <div className={classes.navigation}>
      {prevBtn && (
        <Button color="default" onClick={prevBtn.onClick}>
          {prevBtn.text || 'BACK'}
        </Button>
      )}
      {nextBtn && (
        <Button onClick={nextBtn.onClick}>{nextBtn.text || 'NEXT'}</Button>
      )}
    </div>
  );
};

export default StepsNavigation;
