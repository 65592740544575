import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { MEMBER, MESSAGES } from '../../../constants/routes';
import { useDispatch, useSelector } from '../../../libs/redux';
import {
  getMessages,
  readMessages,
  setChatMedia,
} from '../../../store/actions/chat';
import { selectCurrentChat } from '../../../store/reducers/current-chat';
import { selectUser } from '../../../store/reducers/user';
import Avatar from '../../elements/Avatar';
import { Card } from '../../elements/Card';
import ChatMessage from '../../elements/ChatMessage';
import ScrollBar from '../../elements/ScrollBar';
import ChatForm from '../ChatForm';
import { ReactComponent as Forum } from '../../../assets/img/icon/forum.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/img/icon/arrow-left.svg';
import classes from './ChatBody.module.scss';
import Loader from '../../elements/Loader';
import ChatMediaModal from '../ChatMediaModal';
import { IMessage } from '../../../types/chat';
import useWindowSize from '../../../hooks/useWindowSize';
import RoundStatus from '../RoundStatus';
import { selectChatById } from '../../../store/reducers/chats';

const ChatBody: React.FC = () => {
  const { id: chatId, messages } = useSelector(selectCurrentChat);
  const chat = useSelector((state) => selectChatById(state, chatId));
  const { user } = useSelector(selectUser);
  const dispatch = useDispatch();
  const [manualScroll, setManualScroll] = useState(false);
  const { height } = useWindowSize();

  const chatRef = useRef<HTMLDivElement>(null);

  const onScroll = () => {
    if (chatRef.current) {
      const autoscroll =
        chatRef.current.scrollTop + chatRef.current.clientHeight + 1 >=
        chatRef.current.scrollHeight;
      if (!autoscroll !== manualScroll) {
        setManualScroll(!autoscroll);
      }
      if (
        chat &&
        chatRef.current.scrollTop < 200 &&
        messages.pagination &&
        messages.pagination.total > messages.list.length &&
        !messages.loading
      ) {
        dispatch(
          getMessages({
            userExternalId: chat.details.userId,
            page: messages.pagination.currentPage + 1,
          })
        );
      }
    }
  };

  const goDown = () => {
    if (chatRef.current) {
      chatRef.current.scrollTo(0, chatRef.current.scrollHeight);
    }
  };

  useEffect(() => {
    if (chat && !messages.pagination && !messages.loading) {
      setManualScroll(false);
      dispatch(getMessages({ userExternalId: chat.details.userId, page: 1 }));
      dispatch(readMessages(chat.id));
    }
  }, [chat]);

  useEffect(() => {
    if (!manualScroll) {
      goDown();
    }
  }, [chatRef, messages, height]);

  useEffect(() => {
    const lastMsg = messages.list.length ? messages.list[0] : undefined;
    if (lastMsg && user && lastMsg.senderExternalId === user.id) {
      goDown();
    }
  }, [messages.list]);

  if (!user) {
    return null;
  }

  if (!chat) {
    return (
      <div className={classes.startScreen}>
        <div className={classes.icon}>
          <Forum />
        </div>
        <h2>Start Conversation</h2>
      </div>
    );
  }

  type BodyContent =
    | { type: 'day'; value: string }
    | { type: 'message'; value: IMessage };

  const bodyContent: BodyContent[] = [...messages.list]
    .reverse()
    .reduce((acc: BodyContent[], item) => {
      const time = moment(item.createdAt).calendar(null, {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: 'dddd, MMMM D',
        sameElse: 'L',
      });

      const isUnique = acc.findIndex(
        (i) => i.type === 'day' && i.value === time
      );

      const result: BodyContent[] = [{ type: 'message', value: item }];
      if (isUnique < 0) {
        result.unshift({ type: 'day', value: time });
      }

      return [...acc, ...result];
    }, [])
    .reverse();

  return (
    <Card noPadding className={classes.card}>
      <div className={classes.wrap}>
        <div className={classes.contact}>
          <Link to={MESSAGES.fullPath} className={classes.back} type="button">
            <ArrowLeft />
          </Link>
          <Link
            to={`${MEMBER.fullPath}/${chat.details.userId}`}
            className={classes.avatar}
          >
            <Avatar fullWidth src={chat.details.photo} />
          </Link>
          <div className={classes.info}>
            <Link
              to={`${MEMBER.fullPath}/${chat.details.userId}`}
              className={classes.name}
            >
              {chat.recipients[0].userFullName}
            </Link>
          </div>
        </div>
        <div className={classes.messagesWrap}>
          <ScrollBar
            scrollableNodeProps={{
              ref: chatRef,
              onScroll,
            }}
          >
            <div className={classes.message__field}>
              {bodyContent.map((content) => {
                if (content.type === 'day') {
                  return (
                    <div key={content.value} className={classes.date}>
                      {content.value}
                    </div>
                  );
                }
                return (
                  <ChatMessage
                    key={content.value.id}
                    isMy={
                      content.value.senderExternalId !== chat.details.userId
                    }
                    avatar={
                      content.value.senderExternalId === chat.details.userId
                        ? chat.details.photo
                        : user.photo_url
                    }
                    createdAt={content.value.createdAt}
                    messageFiles={content.value.messageFiles}
                    text={content.value.text}
                    onMediaOpen={(d) => dispatch(setChatMedia(d))}
                  />
                );
              })}
              <Loader position="static" open={messages.loading} />
            </div>
          </ScrollBar>
        </div>
        <div className={classes.round}>
          {chat.details.sparring && (
            <RoundStatus
              sparring={chat.details.sparring}
              recipient={{ id: chat.details.userId, name: chat.details.name }}
              isKoPossible={chat.details.topicKnockOut}
              userRatingRated={chat.details.userRatingRated}
            />
          )}
        </div>
        <ChatForm />
      </div>
      <ChatMediaModal />
    </Card>
  );
};

export default ChatBody;
