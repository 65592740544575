import React, { useEffect, useRef } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/elements/Loader';
import PrivateRoute from '../../components/elements/PrivateRoute';
import StoreTopicTemplate from '../../components/templates/StoreTopic';
import { TOPICS, STORE_TOPICS } from '../../constants/routes';
import { useDispatch, useSelector } from '../../libs/redux';
import useLayoutTitle from '../../hooks/useLayoutTitle';
import {
  addTopic,
  editTopic,
  getTopic,
  setTopic,
} from '../../store/actions/topics';
import { selectTopic } from '../../store/reducers/topic';
import { selectTopics } from '../../store/reducers/topics';
import { IBaseTopic } from '../../types/topic';

const StoreTopicPage: React.FC = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { topic, loading, errors, updating } = useSelector(selectTopic);
  const { list, loading: topicsLoading } = useSelector(selectTopics);
  const id = parseInt(slug || '', 10);
  const navigate = useNavigate();
  const storing = useRef(false);

  useLayoutTitle(`${slug === 'add' ? 'New' : 'Edit'} Topic`);

  useEffect(() => {
    if (!loading && !Number.isNaN(id)) {
      dispatch(getTopic(id));
    }
    return () => {
      dispatch(setTopic(null));
    };
  }, []);
  useEffect(() => {
    if (storing.current) {
      navigate(TOPICS.fullPath);
    }
  }, [list]);

  if (!slug || (Number.isNaN(id) && slug !== 'add')) {
    return <Navigate to="add" />;
  }

  const handleStore = (
    data: Omit<IBaseTopic, 'topic_tags'> & {
      topic_tags: number[];
    }
  ) => {
    storing.current = true;
    if (slug === 'add') {
      dispatch(addTopic(data));
    } else {
      dispatch(editTopic({ ...data, id }));
    }
  };

  const handleCancel = () => {
    navigate(TOPICS.fullPath);
  };

  return (
    <PrivateRoute access={STORE_TOPICS.access}>
      {loading && !Number.isNaN(id) ? (
        <Loader />
      ) : (
        <StoreTopicTemplate
          topic={topic || undefined}
          onStore={handleStore}
          onCancel={handleCancel}
          errors={errors}
          loading={updating || loading}
          sending={topicsLoading}
        />
      )}
    </PrivateRoute>
  );
};

export default StoreTopicPage;
