import { createReducer } from '@reduxjs/toolkit';
import { INotification } from '../../types/notifications';
import {
  addFastNotification,
  addFastNotificationsGroup,
  deleteAllFastNotifications,
  deleteFastNotification,
  setIsNewVersionAvailable,
} from '../actions/notifications';

interface State {
  fastNotifications: INotification[];
  newVersionIsAvailable: boolean;
}

const initialState: State = {
  fastNotifications: [],
  newVersionIsAvailable: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(addFastNotification.fulfilled, (state, action) => {
    state.fastNotifications.push(action.payload);
  });
  builder.addCase(addFastNotificationsGroup, (state, action) => {
    state.fastNotifications.push(...action.payload);
  });
  builder.addCase(deleteFastNotification, (state, action) => {
    state.fastNotifications = state.fastNotifications.filter(
      (notification) => notification.id !== action.payload
    );
  });
  builder.addCase(deleteAllFastNotifications, (state) => {
    state.fastNotifications = [];
  });
  builder.addCase(setIsNewVersionAvailable, (state, action) => {
    state.newVersionIsAvailable = action.payload;
  });
});

interface Store {
  notifications: State;
}

export const selectNotifications = (state: Store) => state.notifications;

export default reducer;
