import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Badge from '../../elements/Badge';
import classes from './UserMenu.module.scss';
import { ReactComponent as Notification } from '../../../assets/img/icon/notification.svg';
import { ReactComponent as Arrow } from '../../../assets/img/icon/chevron-down.svg';
import { useSelector } from '../../../libs/redux';
import { selectUser } from '../../../store/reducers/user';
import { PROFILE } from '../../../constants/routes';
import Avatar from '../../elements/Avatar';
import UserNotifications from '../UserNotifications';
import Transition from '../../elements/Transition';
import useClickOutside from '../../../hooks/useOutsideClick';
import { selectSparrings } from '../../../store/reducers/sparrings';
import { selectChat } from '../../../store/reducers/chats';

interface IProps {
  displyName?: boolean;
}

const UserMenu: React.FC<IProps> = (props) => {
  const { displyName = false } = props;
  const { user } = useSelector(selectUser);
  const {
    pagination: { total: totalRequests },
  } = useSelector(selectSparrings);
  const { unreadCount } = useSelector(selectChat);
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false);

  const notificationsListRef = useClickOutside({
    onTriggered() {
      setIsNotificationsModalOpen(false);
    },
  });

  if (!user) {
    return null;
  }
  return (
    <div className={classes.wrap}>
      <div className={classes.notificationsWrap}>
        <button
          type="button"
          ref={notificationsListRef}
          className={classes.notification}
          onClick={() => setIsNotificationsModalOpen(!isNotificationsModalOpen)}
        >
          <Badge badgeContent={totalRequests + unreadCount} variant="dot">
            <Notification />
          </Badge>
        </button>
        <div className={classes.notificationsList}>
          <Transition in={isNotificationsModalOpen} mode="zoom" timeout={200}>
            <UserNotifications />
          </Transition>
        </div>
      </div>

      <Link to={PROFILE.fullPath} className={classes.user}>
        <Avatar alt="user" src={user.photo_url} />
      </Link>
      {displyName && (
        <span className={classes.profile__name}>
          {user.first_name} {user.last_name}
          <Arrow />
        </span>
      )}
    </div>
  );
};

export default UserMenu;
