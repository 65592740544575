import React, { useState } from 'react';
import Button from '../../elements/Button';
import classes from './Onboarding.module.scss';
import 'react-html5-camera-photo/build/css/index.css';
import { setUserPhoto } from '../../../store/actions/user';
import { useSelector, useDispatch } from '../../../libs/redux';
import { selectUser } from '../../../store/reducers/user';
import { ERROR_FIELD_PHOTO_REQUIRED } from '../../../constants/error-messages';
import AvatarUpdate from '../AvatarUpdate';

interface IProps {
  onNext: () => void;
}

const Picture: React.FC<IProps> = (props) => {
  const { onNext } = props;
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState('');
  const { user, errors } = useSelector(selectUser);
  const dispatch = useDispatch();

  const handleStorePhoto = () => {
    if (file) {
      dispatch(setUserPhoto(file));
    } else {
      setError(ERROR_FIELD_PHOTO_REQUIRED);
    }
    if (file || (user && user.photo_url)) {
      onNext();
    }
  };

  return (
    <div className={classes.pictureWrap}>
      <AvatarUpdate
        avatar={file || user?.photo_url || null}
        onChang={setFile}
        maxSize={1}
        error={error || errors.photo_url}
      />

      <div className={classes.submit}>
        <Button onClick={handleStorePhoto} variant="contained">
          NEXT
        </Button>
      </div>
    </div>
  );
};

export default Picture;
