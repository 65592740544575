import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/elements/Button';
import Loader from '../../components/elements/Loader';
import PrivateRoute from '../../components/elements/PrivateRoute';
import ProfileTemplate from '../../components/templates/Profile';
import ErrorTemplate from '../../components/templates/Error';
import routes, { PROFILE } from '../../constants/routes';
import { useDispatch, useSelector } from '../../libs/redux';
import { ReactComponent as ArrowLeft } from '../../assets/img/icon/arrow-left.svg';
import { selectMember } from '../../store/reducers/member';
import {
  getMember,
  getMemberEducation,
  getMemberExperience,
} from '../../store/actions/member';
import { sendSparringRequest } from '../../store/actions/sparrings';
import { selectUser } from '../../store/reducers/user';

const MemberPage: React.FC = () => {
  const { member, loading, error, education, experience } =
    useSelector(selectMember);
  const { user } = useSelector(selectUser);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const memberId = parseInt(id || '', 10);

  useEffect(() => {
    if (!Number.isNaN(memberId)) {
      dispatch(getMember(memberId));
      dispatch(getMemberEducation(memberId));
      dispatch(getMemberExperience(memberId));
    }
  }, []);

  if (error) {
    return <ErrorTemplate code={404} />;
  }

  const prevRoute =
    Boolean(state) &&
    Boolean(state.prevPathname) &&
    routes.find((route) => {
      const p = state.prevPathname as string;
      if (route.fullPath.length === 1) {
        return p === route.fullPath;
      }
      return p.includes(route.fullPath);
    });

  return (
    <PrivateRoute access={PROFILE.access}>
      {!member || loading ? (
        <Loader />
      ) : (
        <ProfileTemplate
          user={member}
          experience={
            experience.length
              ? {
                  list: experience,
                }
              : undefined
          }
          education={
            education.length
              ? {
                  list: education,
                }
              : undefined
          }
          onSparringRequest={
            user && user.id === memberId
              ? undefined
              : (data) => dispatch(sendSparringRequest(data))
          }
        >
          {prevRoute && (
            <div style={{ marginBottom: 20 }}>
              <Button startIcon={<ArrowLeft />} onClick={() => navigate(-1)}>
                BACK TO {prevRoute.name.toUpperCase()}
              </Button>
            </div>
          )}
        </ProfileTemplate>
      )}
    </PrivateRoute>
  );
};

export default MemberPage;
