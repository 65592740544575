import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { requestSettings } from '../../repositories/general';
import { ISettings } from '../../types/general';

export const getSettings = createAsyncThunk('getSettings', async () => {
  const response = await requestSettings();
  return response.data;
});

export const setSettings = createAction<ISettings>('setSettings');
