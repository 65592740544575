export const RESPONSE = '-response';
export const FAIL = '-error';
export const GET_CHATS = 'get-conversations';
export const NEW_MESSAGE = 'create-message';
export const GET_MESSAGES = 'get-messages';
export const SEND_MESSAGE = 'create-message';
export const GET_CHAT_BY_USER_ID = 'get-conversation-user';
export const GET_UNREAD_MESSAGES_COUNT = 'count-unread-messages';
export const READ_MESSAGES = 'read-conversation';

export const ROUND_COMPLETE = 'round-complete';
export const KO = 'knock-out';

export const ERROR = 'error';
export const EXCEPTION = 'exception';
