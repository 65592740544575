import classNames from 'classnames';
import React, { useRef } from 'react';
import { Transition as ReactTransition } from 'react-transition-group';
import { css } from '@emotion/css';
import classes from './Transition.module.scss';

interface IProps {
  mode?: 'zoom' | 'fade' | 'slide';
  in: boolean;
  timeout?:
    | number
    | {
        appear?: number | undefined;
        enter?: number | undefined;
        exit?: number | undefined;
      };
  children?: React.ReactNode;
  unmountOnExit?: boolean;
}

const Transition: React.FC<IProps> = (props) => {
  const {
    mode = 'fade',
    in: inProp,
    timeout = 500,
    children,
    unmountOnExit = false,
  } = props;
  const nodeRef = useRef<HTMLDivElement>(null);
  const transition = css`
    transition: all ease
      ${typeof timeout === 'number' ? timeout : timeout.enter}ms;
  `;
  const hiddenTransition =
    (unmountOnExit &&
      typeof timeout !== 'number' &&
      timeout.exit === 0 &&
      css`
        opacity: 0;
        visibility: hidden;
        position: absolute;
        transition: none !important;
      `) ||
    '';

  return (
    <ReactTransition
      nodeRef={nodeRef}
      in={inProp}
      timeout={timeout}
      classNames={classes}
      unmountOnExit={unmountOnExit}
    >
      {(state) => (
        <div
          ref={nodeRef}
          className={classNames(classes[mode], transition, {
            [classes.exitDone]: state === 'exited' && !inProp,
            [classes.exitActive]: state === 'exiting' && !inProp,
            [classes.enterDone]: state === 'entered' && inProp,
            [classes.enterActive]: state === 'entering' && inProp,
            [hiddenTransition]: state === 'exiting',
          })}
        >
          {children}
        </div>
      )}
    </ReactTransition>
  );
};

export default Transition;
