import { createReducer } from '@reduxjs/toolkit';
import { Subscription } from '../../types/subscription';
import { getSubscriptions } from '../actions/subscriptions';

interface State {
  list: Subscription[];
  loading: boolean;
  loaded: boolean;
}

const initialState: State = {
  list: [],
  loading: false,
  loaded: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(getSubscriptions.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getSubscriptions.fulfilled, (state, action) => {
    state.loading = false;
    state.loaded = true;
    state.list = action.payload.data;
  });
});

interface Store {
  subscriptions: State;
}

export const selectSubscriptions = (state: Store) => state.subscriptions;

export default reducer;
