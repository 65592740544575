import React from 'react';
import Portal from '../Portal';
import classes from './Backdrop.module.scss';

interface IProps {
  open?: boolean;
  children?: React.ReactNode;
  zIndex?: number;
  onClick?: () => void;
}

const Backdrop: React.FC<IProps> = (props) => {
  const { open, children, zIndex, onClick } = props;
  if (!open) {
    return null;
  }
  return (
    <Portal>
      <div
        className={classes.wrap}
        style={{ zIndex }}
        onClick={onClick}
        onKeyPress={onClick}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
    </Portal>
  );
};

export default Backdrop;
