import axios, { Response } from '../libs/axios';
import { ISession, IAuthProvider } from '../types/authentication';
import { IUser, IUserUpdatableFields } from '../types/user';

export const requestUser = () =>
  axios<Response<IUser>>({
    url: '/user',
  });

export const login = (data: { email: string; password: string }) =>
  axios<Response<IUser & { token: ISession }>>({
    url: '/user/login',
    method: 'POST',
    data,
  });

export const loginSocial = (data: { token: string; provider: IAuthProvider }) =>
  axios<Response<IUser & { token: ISession }>>({
    url: '/user/social/login',
    method: 'POST',
    data: {
      provider: data.provider,
      [data.provider !== 'linkedin' ? 'token' : 'code']: data.token,
    },
  });

export const register = (data: {
  email: string;
  password: string;
  password_confirmation: string;
  first_name: string;
  last_name: string;
}) =>
  axios<Response<IUser & { token: ISession }>>({
    url: '/user/registration',
    method: 'POST',
    data,
  });

export const refreshToken = (token: string) =>
  axios<Response<IUser & { token: ISession }>>({
    url: '/user/refresh-token',
    method: 'POST',
    data: { refresh_token: token },
  });

export const updatePhoto = (data: FormData) =>
  axios<Response<{ photo_url: string }>>({
    url: '/user/upload-photo',
    method: 'POST',
    data,
  });

export const resetPassword = (email: string) =>
  axios({
    url: '/user/reset-password',
    method: 'POST',
    data: { email },
  });

export const changePassword = (data: {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}) =>
  axios({
    url: '/user/change-password',
    method: 'POST',
    data,
  });

export const updateData = (data: Partial<IUserUpdatableFields>) =>
  axios<Response<IUser>>({
    url: '/user',
    method: 'PUT',
    data,
  });

export const getUserById = (id: number) =>
  axios<Response<IUser>>({
    url: `/user/${id}`,
    method: 'GET',
  });

export const rateUser = (data: {
  user_id: number;
  vision?: number;
  strategy?: number;
  technical?: number;
  problem_solving?: number;
  communication?: number;
  listening?: number;
  empathy?: number;
  creativity?: number;
  inspirational?: number;
  humility?: number;
  note?: string;
}) =>
  axios<Response<IUser>>({
    url: '/user-rating/rate',
    method: 'POST',
    data,
  });
