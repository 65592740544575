import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import ErrorTemplate from '../components/templates/Error';
import {
  STORE_EDUCATION,
  EDIT_PROFILE,
  ONBOARDING,
  PROFILE,
  RESTORE_PASSWORD,
  SEARCH,
  SIGN_IN,
  SIGN_UP,
  TEXT_PAGE,
  TOPICS,
  STORE_EXPERIENCE,
  STORE_TOPICS,
  MESSAGES,
  MEMBER,
  SPARRING,
} from '../constants/routes';
import HomePage from './HomePage';
import TextPage from './TextPage';
import TopicsPage from './Topics';
import SignInPage from './SignIn';
import SignUpPage from './SignUp';
import RestorePasswordPage from './RestorePassword';
import OnboardingPage from './Onboarding';
import ProfilePage from './Profile';
import SearchPage from './Search';
import EditProfilePage from './EditProfile';
import StoreEducationPage from './StoreEducation';
import StoreExperiencePage from './StoreExperience';
import MemberPage from './Member';
import InnerLayout from '../components/layouts/Inner';
import StoreTopicPage from './StoreTopic';
import TopicPage from './Topic';
import MessagesPage from './Messages';
import SparringPage from './Sparring';

const RoutesNavigation: React.FC = () => (
  <Routes>
    <Route path="*" element={<ErrorTemplate code={404} />} />
    <Route index element={<HomePage />} />
    <Route path={SIGN_IN.path} element={<SignInPage />} />
    <Route path={SIGN_UP.path} element={<SignUpPage />} />
    <Route path={RESTORE_PASSWORD.path} element={<RestorePasswordPage />} />
    <Route path={ONBOARDING.path} element={<OnboardingPage />} />

    <Route path={TEXT_PAGE.path}>
      <Route path=":slug" element={<TextPage />} />
    </Route>

    <Route path={TOPICS.path} element={<InnerLayout />}>
      <Route index element={<TopicsPage />} />

      <Route path={STORE_TOPICS.path} element={<StoreTopicPage />}>
        <Route path=":slug" element={<StoreTopicPage />} />
      </Route>
      <Route path=":id" element={<TopicPage />} />
    </Route>

    <Route path={SPARRING.path} element={<InnerLayout />}>
      <Route index element={<SparringPage />} />
    </Route>

    <Route path={SEARCH.path} element={<InnerLayout />}>
      <Route index element={<SearchPage />} />
      <Route path=":id" element={<MemberPage />} />
    </Route>

    <Route path={MESSAGES.path} element={<InnerLayout />}>
      <Route index element={<MessagesPage />} />
      <Route path=":id" element={<MessagesPage />} />
    </Route>

    <Route path={MEMBER.path} element={<InnerLayout />}>
      <Route path=":id" element={<MemberPage />} />
    </Route>

    <Route path={PROFILE.path} element={<InnerLayout />}>
      <Route index element={<ProfilePage />} />

      <Route path={EDIT_PROFILE.path} element={<EditProfilePage />} />

      <Route path={STORE_EDUCATION.path} element={<StoreEducationPage />}>
        <Route path=":slug" element={<StoreEducationPage />} />
      </Route>

      <Route path={STORE_EXPERIENCE.path} element={<StoreExperiencePage />}>
        <Route path=":slug" element={<StoreExperiencePage />} />
      </Route>
    </Route>

    <Route path="/linkedin" element={<LinkedInCallback />} />
  </Routes>
);

export default RoutesNavigation;
