import React from 'react';
import PrivateRoute from '../../components/elements/PrivateRoute';
import SearchTemplate from '../../components/templates/Search';
import { SEARCH } from '../../constants/routes';

const SearchPage: React.FC = () => (
  <PrivateRoute access={SEARCH.access}>
    <SearchTemplate />
  </PrivateRoute>
);

export default SearchPage;
