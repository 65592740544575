import React from 'react';
import classes from './CircularProgress.module.scss';

interface IProps {
  size?: number;
  color?: string;
}

const CircularProgress: React.FC<IProps> = (props) => {
  const { size, color } = props;
  return (
    <div className={classes.progress} style={{ fontSize: size, color }}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default CircularProgress;
