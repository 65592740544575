import classNames from 'classnames';
import React from 'react';
import classes from './Label.module.scss';

export interface LabelProps {
  children?: React.ReactNode;
  error?: boolean;
}

const Label: React.FC<LabelProps> = (props) => {
  const { children, error } = props;
  return (
    <label className={classNames(classes.label, { [classes.error]: error })}>
      {children}
    </label>
  );
};

export default Label;
