import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MESSAGES, SPARRING } from '../../../constants/routes';
import { useDispatch, useSelector } from '../../../libs/redux';
import { getSparrings } from '../../../store/actions/sparrings';
import { selectChat } from '../../../store/reducers/chats';
import { selectSparrings } from '../../../store/reducers/sparrings';
import { Card } from '../../elements/Card';
import classes from './UserNotifications.module.scss';

const UserNotifications: React.FC = () => {
  const {
    pagination: { total: totalRequests },
  } = useSelector(selectSparrings);
  const { unreadCount } = useSelector(selectChat);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSparrings({ page: 1 }));
  }, []);

  return (
    <Card noPadding className={classes.card}>
      <div className={classes.wrap}>
        <h5>
          {!totalRequests ? 'You have no notifications' : 'Notifications'}
        </h5>
        {Boolean(unreadCount) && (
          <Link className={classes.link} to={MESSAGES.fullPath}>
            {`${unreadCount} unread message${unreadCount > 1 ? 's' : ''}`}
          </Link>
        )}
        {Boolean(totalRequests) && (
          <Link className={classes.link} to={SPARRING.fullPath}>
            {`${totalRequests} spating requests`}
          </Link>
        )}
      </div>
    </Card>
  );
};

export default UserNotifications;
