import React from 'react';
import Container from '../../elements/Container';
import RootLayout, { RootLayoutProps } from '../Root';
import classes from './Base.module.scss';

interface IProps extends RootLayoutProps {}

const BaseLayout: React.FC<IProps> = (props) => {
  const { children, pageTitle } = props;
  return (
    <RootLayout pageTitle={pageTitle}>
      <main className={classes.wrap}>
        {children}
        <section className={classes.copyright}>
          <Container>© 2022 Sparring Partners</Container>
        </section>
      </main>
    </RootLayout>
  );
};

export default BaseLayout;
