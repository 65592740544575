import React from 'react';
import PageTitle from '../../elements/PageTitle';

export interface RootLayoutProps {
  children?: React.ReactNode;
  pageTitle?: string;
}

const RootLayout: React.FC<RootLayoutProps> = (props) => {
  const { children, pageTitle } = props;

  return (
    <>
      <PageTitle title={pageTitle} />
      {children}
    </>
  );
};

export default RootLayout;
