import { createReducer } from '@reduxjs/toolkit';
import { Session } from '../../utils/session';
import { ISession, ResetPassworState } from '../../types/authentication';
import {
  changePassword,
  clearAuthData,
  resetPassword,
  setAuthErrors,
  signIn,
  signUp,
  toggleResetState,
  setSession,
} from '../actions/authentication';

const sessionInstance = Session.getInstance();

interface State {
  loading: boolean;
  errors: Partial<Record<string, string>>;
  resetPasswordState: ResetPassworState;
  session: ISession | null;
}

const initialState: State = {
  loading: false,
  errors: {},
  resetPasswordState: ResetPassworState.resetPassword,
  session: sessionInstance.getSession(),
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(toggleResetState, (state, action) => {
    state.resetPasswordState = action.payload;
    state.errors = {};
  });
  builder.addCase(signUp.pending, (state) => {
    state.loading = true;
    state.errors = {};
  });
  builder.addCase(signUp.fulfilled, (state) => {
    state.loading = false;
  });
  builder.addCase(signUp.rejected, (state) => {
    state.loading = false;
  });
  builder.addCase(signIn.pending, (state) => {
    state.loading = true;
    state.errors = {};
  });
  builder.addCase(signIn.fulfilled, (state) => {
    state.loading = false;
  });
  builder.addCase(signIn.rejected, (state) => {
    state.loading = false;
  });
  builder.addCase(setAuthErrors, (state, action) => {
    state.errors = action.payload;
  });
  builder.addCase(setSession.fulfilled, (state, action) => {
    state.session = action.payload;
  });
  builder.addCase(resetPassword.pending, (state) => {
    state.loading = true;
    state.errors = {};
  });
  builder.addCase(resetPassword.fulfilled, (state) => {
    state.loading = false;
    state.resetPasswordState = ResetPassworState.resetEmailSent;
  });
  builder.addCase(resetPassword.rejected, (state) => {
    state.loading = false;
  });

  builder.addCase(changePassword.pending, (state) => {
    state.loading = true;
    state.errors = {};
  });
  builder.addCase(changePassword.fulfilled, (state) => {
    state.loading = false;
    state.resetPasswordState = ResetPassworState.passwordChanged;
  });
  builder.addCase(changePassword.rejected, (state) => {
    state.loading = false;
  });
  builder.addCase(clearAuthData, () => initialState);
});

interface Store {
  authentication: State;
}

export const selectAuthentication = (state: Store) => state.authentication;

export default reducer;
