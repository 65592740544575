import React from 'react';
import classNames from 'classnames';
import classes from './Card.module.scss';

export interface CardBodyProps {
  children?: React.ReactNode;
  className?: string;
}

const CardBody: React.FC<CardBodyProps> = (props) => {
  const { children, className } = props;
  return <div className={classNames(classes.body, className)}>{children}</div>;
};

export default CardBody;
