import { IError422 } from '../types/errors';

export const emailValidation = (val: string) =>
  val.length > 0
    ? /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        val
      )
    : true;

export const flatten = (arr: []) =>
  arr.reduce((flat, next) => flat.concat(next), []);

// export const insertToArray = (array: [], action: { index: number, item: any }) => ([
//   ...array.slice(0, action.index),
//   action.item,
//   ...array.slice(action.index),
// ]);
//
export const secondsToMinutes = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export const removeFromArray = (array: any[], action: { index: number }) => [
  ...array.slice(0, action.index),
  ...array.slice(action.index + 1),
];

export const updateObjectInArray = <T>(
  array: T[],
  action: { index: number; item: T }
) =>
  array.map((item, index) => {
    if (index !== action.index) {
      return item;
    }
    return {
      ...item,
      ...action.item,
    };
  });

export const removeKeyFromObject = (obj: object, omitKey: string) =>
  (Object.keys(obj) as Array<keyof typeof obj>).reduce((result, key) => {
    if (key !== omitKey) {
      return { ...result, [key]: obj[key] };
    }
    return result;
  }, {});

export const clearEmptyFields = (obj: { [k: string]: any }) =>
  Object.keys(obj)
    .filter(
      (key) => obj[key] !== null && obj[key] !== undefined && obj[key] !== ''
    )
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});

export const toBase64 = (
  file: File | Blob
): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export const toFeet = (cm: number) => {
  const realFeet = (cm * 0.3937) / 12;
  const feet = Math.floor(realFeet);
  const inches = Math.round((realFeet - feet) * 12);
  return `${feet}'${inches}''`;
};
export const calculateAge = (birthday: string, now: string) => {
  const dateNow = new Date(now);
  const dateBirthday = new Date(birthday);
  const ageDifMs = dateNow.getTime() - dateBirthday.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const parseObjectKeys = (obj: { [k: string]: string }) => {
  const newObj: { [k: string]: any } = {};
  Object.keys(obj).forEach((name) => {
    let res = newObj;
    name.split('.').forEach((aName, index, names) => {
      if (!res[aName]) {
        if (index === names.length - 1) {
          res[aName] = obj[name];
        } else {
          res[aName] = {};
        }
      }
      res = res[aName];
    });
  });
  return newObj;
};

export const getDaysInMonth = (month: number, year: number) => {
  const date = new Date(year, month, 1);
  const days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

export const getLocalDate = (timestamp: number) => {
  const date = new Date(timestamp < 10 ** 12 ? timestamp * 1000 : timestamp);
  return `${date.getDate() < 10 ? '0' : ''}${date.getDate()}.${
    date.getMonth() < 9 ? '0' : ''
  }${date.getMonth() + 1}.${date.getFullYear()}`;
};

export const objectToQuery = (
  params: any,
  skipobjects = false,
  prefix = ''
): string => {
  const isObj = (a: any) =>
    typeof a === 'object' && a !== null && !Array.isArray(a);

  const st = (z: string, g: string) =>
    `${g !== '' ? '[' : ''}${z}${g !== '' ? ']' : ''}`;

  let result = '';

  if (typeof params !== 'object') {
    return `${prefix}=${encodeURIComponent(params)}&`;
  }

  Object.keys(params).forEach((param) => {
    const c = prefix + st(param, prefix);
    const currentParam = params[param];
    if (isObj(currentParam) && !skipobjects) {
      result += objectToQuery(currentParam, false, c);
    } else if (Array.isArray(currentParam) && !skipobjects) {
      currentParam.forEach((item, ind) => {
        result += objectToQuery(item, false, `${c}[${ind}]`);
      });
    } else {
      result += `${c}=${encodeURIComponent(params[param])}&`;
    }
  });

  return result;
};

export const handleError422 = (
  errors: IError422
): { [k: string]: string | undefined } =>
  errors.errors.reduce(
    (acc, item) => ({ ...acc, [item.field]: item.message }),
    {}
  );
