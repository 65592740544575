import React from 'react';
import { ReactComponent as Close } from '../../../assets/img/icon/close.svg';
import classes from './ChatForm.module.scss';
import pdf from '../../../assets/img/file-icons/pdf.svg';
import unknown from '../../../assets/img/file-icons/unknown.svg';
import ScrollBar from '../../elements/ScrollBar';
import { mimeType } from '../../../constants/file';

interface IProps {
  files: File[];
  onRemove: (files: File[]) => void;
}

type FileType = 'image' | 'pdf' | 'other';

const types: { name: FileType; types: string[] }[] = [
  {
    name: 'image',
    types: mimeType.image,
  },
  {
    name: 'pdf',
    types: mimeType.pdf,
  },
];

const FilesList: React.FC<IProps> = (props) => {
  const { files, onRemove } = props;

  const getFileType = (file: File): FileType => {
    const type = types.find((item) => item.types.includes(file.type));
    return type ? type.name : 'other';
  };

  const getContentByType = (file: File) => {
    const type = getFileType(file);
    switch (type) {
      case 'image':
        return (
          <img
            className={classes.preview}
            src={URL.createObjectURL(file)}
            alt=""
          />
        );
      case 'pdf':
        return (
          <div className={classes.fileIcon} title={file.name}>
            <img src={pdf} alt={file.name} />
          </div>
        );
      default:
        return (
          <div className={classes.fileIcon} title={file.name}>
            <img src={unknown} alt={file.name} />
          </div>
        );
    }
  };

  return (
    <div className={classes.attattachment__listWrap}>
      <ScrollBar>
        <div className={classes.attattachment__list}>
          {files.map((file, i) => (
            <div
              className={classes.attachmentWrap}
              key={file.name + file.lastModified + i.toString()}
            >
              <div className={classes.attachment}>
                <button
                  type="button"
                  className={classes.close}
                  onClick={() => {
                    onRemove(files.filter((_, j) => i !== j));
                  }}
                >
                  <Close />
                </button>
                {getContentByType(file)}
              </div>
            </div>
          ))}
        </div>
      </ScrollBar>
    </div>
  );
};

export default FilesList;
