import React from 'react';
import Container from '../../elements/Container';
import HTMLParser from '../../elements/HTMLParser';
import classes from './TextPage.module.scss';

interface IProps {
  content: string;
  title?: string;
}

const TextPageTemplate: React.FC<IProps> = (props) => {
  const { content, title } = props;
  return (
    <section className={classes.text__page}>
      <Container>
        {title && <h1>{title}</h1>}
        <HTMLParser html={content} />
      </Container>
    </section>
  );
};

export default TextPageTemplate;
