import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconLogo } from '../../../assets/img/logo.svg';
import classes from './Logo.module.scss';

interface IProps {
  className?: string;
}

const Logo: React.FC<IProps> = (props) => {
  const { className } = props;
  return (
    <Link to="/" className={classNames(className, classes.wrap)}>
      <IconLogo />
    </Link>
  );
};

export default Logo;
